import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

/**
 * Service class for interacting with the E2E Taxonomy data.
 */
@Injectable({
	providedIn: 'root',
})
export class EventService {
	private _subjects: { [key: string]: Subject<any> | BehaviorSubject<any> } = {};
	private _subjectsSimple: string[] = [];
	private _observables: { [key: string]: Observable<any> } = {};

	private startupN = 0;
	private startupCount = 100;
	private startupSubjectCount: Subject<string> = new Subject();
	private startupSubject: Subject<string> = new Subject();

	private authSubject: Subject<boolean> = new Subject();
	private _hasAuth: boolean = false;

	constructor() {
		this.startupSubjectCount.asObservable().subscribe(() => {
			if (this.startupN >= this.startupCount) {
				this.startupSubject.next(uuid());
			}
		});

		this.authSubject.asObservable().subscribe((b) => {
			this._hasAuth = b;
		});
	}

	setStartupDependencies(n: number) {
		this.startupCount = n;
	}

	hasAuth() {
		return this._hasAuth;
	}

	setStartup() {
		this.startupN++;
		this.startupSubjectCount.next(uuid());
	}

	getStartup() {
		return this.startupSubject.asObservable();
	}

	setAuth(b: boolean) {
		this.authSubject.next(b);
	}

	getAuth() {
		return this.authSubject.asObservable();
	}

	setEvent<T>(key: string, initValue?: T) {
		const subject = initValue !== undefined ? new BehaviorSubject<T>(initValue) : new Subject<T>();
		this._subjects[key] = subject;
		this._observables[key] = subject.asObservable();
	}

	setEventSimple(key: string, initValue: boolean = false) {
		const subject = initValue ? new BehaviorSubject(uuid()) : new Subject();
		this._subjects[key] = subject;
		this._subjectsSimple.push(key);
		this._observables[key] = subject.asObservable();
	}

	nextValueEvent<T>(key: string, value: T) {
		if (!this._subjects.hasOwnProperty(key)) {
			this.setEvent<T>(key);
		}
		if (this._subjects.hasOwnProperty(key)) {
			this._subjects[key].next(value);
		}
	}

	nextEventSimple(key: string) {
		if (!this._subjects.hasOwnProperty(key) && !this._subjectsSimple.includes(key)) {
			this.getEventSimple(key);
		}
		if (this._subjectsSimple.includes(key)) {
			this._subjects[key].next(uuid());
		}
	}

	getEvent<T>(key: string) {
		if (!this._subjects.hasOwnProperty(key) && !this._observables.hasOwnProperty(key)) {
			this.setEvent<T>(key);
		}
		return this._observables[key] as Observable<T>;
	}

	getEventSimple(key: string) {
		if (
			!this._subjects.hasOwnProperty(key) &&
			!this._observables.hasOwnProperty(key) &&
			!this._subjectsSimple.includes(key)
		) {
			this.setEventSimple(key);
		}
		return this._observables[key] as Observable<string>;
	}
}
