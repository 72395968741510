import { Component, Inject, Input, OnInit } from '@angular/core';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AISolutionModelObjectType } from '../../../service/graphql';
import { UseCaseService } from '../../../service/use-case.service';
import { UseCase } from '../../../api/use-case.api';
import { AuthService } from '../../../service/auth.service';
import { LoadingService } from '../../common/loading/loading.service';

interface AIOpportunity extends AISolutionModelObjectType {
	allDataRequirements: string;
	allTopBenefits: { title: string; label: string; className: string }[];
	allAgents: {
		description: string;
		agents: {
			label: string;
			mustHave: 'MUST HAVE' | 'GOOD TO HAVE' | undefined;
			mustHaveLabel: string;
			className: 'yellow' | 'blue';
		}[];
	};
}

@Component({
	selector: 'app-ai-solution-item',
	templateUrl: './ai-solution-item.component.html',
	styleUrls: ['./ai-solution-item.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class AISolutionItemComponent implements OnInit {
	@Input() aiSolution: AIOpportunity;
	@Input() diagram?: DynamicDialogRef;

	@Inject(DynamicDialogRef) ref: DynamicDialogRef | undefined = undefined;

	constructor(
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		private loadingService: LoadingService,
		private useCaseService: UseCaseService,
		private authService: AuthService,
	) {
	}

	ngOnInit() {
	}

	addToMyUseCaseLoading: boolean = false;

	addToMyUseCase() {
		if (!this.addToMyUseCaseLoading) {
			this.confirmationService.confirm({
				header: 'Add to your AI Solutions?',
				message: 'This will add the AI Solution to your AI Solutions.',
				icon: 'pi pi-sparkles',
				acceptLabel: 'Add and edit',
				acceptIcon: 'pi pi-window-maximize',
				accept: () => {
					this._addToMyUseCase(true);
				},
				rejectLabel: 'Add',
				rejectIcon: 'pi pi-plus',
				reject: (type: ConfirmEventType) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this._addToMyUseCase(false);
							break;
					}
				},
			});
		}
	}

	async _addToMyUseCase(go: boolean) {
		this.loadingService.show();
		try {

			this.addToMyUseCaseLoading = true;
			if (this.aiSolution) {
				await this.useCaseService.setCurrentUseCase('', {});
				if (this.useCaseService.currentUseCase && this.aiSolution) {
					this.useCaseService.currentUseCase.name = this.aiSolution.name;
					this.useCaseService.currentUseCase.description = this.aiSolution.description;
					this.useCaseService.currentUseCase.diagram = this.useCaseService._newDiagramEmpty(
						this.useCaseService.currentUseCase,
					);
					this.useCaseService.currentUseCase.libraryData = { ...this.aiSolution } as any;
					this.useCaseService.currentUseCase.hubble = true;
					const organization = await this.authService.getCurrentOrganization();
					if (organization && this.useCaseService.currentUseCase?.diagram) {
						this.useCaseService.currentUseCase.diagram.organization = organization;
						this.useCaseService.currentUseCase.diagram.organizationId = organization.id as string;
						this.useCaseService.currentDiagram = this.useCaseService.currentUseCase.diagram;
					}

					const error = await this.useCaseService.saveCurrentUseCase();
					if (error) {
						this.messageSaveUseCase(error);
					} else {
						this.messageSaveUseCase();
						if (go && this.useCaseService.currentUseCase && this.ref) {
							this.ref.close();
							if (this.diagram) {
								this.diagram.close();
							}
							await this.goToMyUseCase(this.useCaseService.currentUseCase);
						}
					}
				}
			}
			this.addToMyUseCaseLoading = false;
		} finally {
			this.loadingService.hide();
		}
	}

	messageSaveUseCase(error?: string) {
		if (error) {
			if (error === 'no-name') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the new AI Solution',
				});
			} else if (error === 'no-description') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the new AI Solution',
				});
			} else if (error === 'no-updated') {
				this.messageService.add({ severity: 'warn', summary: 'No changes', detail: 'Nothing to save' });
			} else {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
			}
		} else {
			this.messageService.add({
				severity: 'success',
				summary: 'Confirmed',
				detail: 'AI Solution saved on My AI Solutions',
			});
		}
	}

	async goToMyUseCase(useCase: UseCase) {
		await this.useCaseService.goToUseCase(useCase.id as string, true);
	}
}
