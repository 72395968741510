import { ElementRef, Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ILevel2, ITechnology, getEnumValue } from '../../service/app.types';
import { ApiService } from '../../service/api.service';
import { EventService } from '../../service/event.service';
import { DiagramService } from '../../service/diagram.service';
import {
	CategoryFunctionModelObjectType,
	CommonDataModelObjectType,
	FunctionModelObjectType,
	Level1ModelObjectType,
	Level2ModelObjectType,
	TechnologyModelObjectType,
} from '../../service/graphql';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { AuthService } from '../../service/auth.service';

interface Card {
	id: string;
	label: string;
	values: number[];
	diagram: string;
	diagramImage: string;
	baseLevel: boolean;
	goTo: boolean;
	aiSolutionsEvaluations: {
        Breakthrough: { id: string; name: string }[];
        Transformative: { id: string; name: string }[];
        Incremental: { id: string; name: string }[];
    };
	type:
		| 'CategoryFunctionModelObjectType'
		| 'FunctionModelObjectType'
		| 'Level1ModelObjectType'
		| 'Level2ModelObjectType';
	paths: {
		id: string;
		label: string;
	}[];
}


interface GroupCards {
	label: string;
	cards: Card[];
}

interface Data {
	[key: string]: GroupCards[];
}

@Injectable({
	providedIn: 'root',
})
export class AiExplorerService {
	constructor(
		private apiService: ApiService,
		private eventService: EventService,
		private diagramService: DiagramService,
		// Legacy
		public e2ETaxonomyService: E2ETaxonomyService,
	) {
		if (this.eventService.hasAuth()) {
			this.prepareData();
		}

		this.eventService.getAuth().subscribe((hasAuth) => {
			if (!this.init && hasAuth) {
				this.prepareData();
			}
		});
	}

	init: boolean = false;
	loading: boolean = true;
	loading_all: boolean = true;

	scrollList: ElementRef;
	scrollGrid: ElementRef;

	baselineOfficeTreeNode!: TreeNode[];
	baselineFrontOfficeTreeNode!: TreeNode[];
	baselineMiddleOfficeTreeNode!: TreeNode[];
	baselineBackOfficeTreeNode!: TreeNode[];

	industryOfficeTreeNode!: TreeNode[];
	industryFrontOfficeTreeNode!: TreeNode[];
	industryMiddleOfficeTreeNode!: TreeNode[];
	industryBackOfficeTreeNode!: TreeNode[];

	treeNodeSelected: TreeNode | undefined;

	technologySort: string[] = [];
	technologies: ITechnology[];

	data: Data = {};
	currentData: GroupCards[] = [];
	currentPath: { id: string; label: string }[] = [];

	onUpdate: () => void = () => {};

	initSubscription: boolean = false;

	async prepareData() {
		this.init = false;
		await this.getData();
		this.init = true;

		if (!this.initSubscription) {
			this.initSubscription = true;

			this.eventService.getEvent('organization').subscribe(() => {
				setTimeout(() => {
					this.getData();
					this.onUpdate();
				}, 150);
			});

			this.eventService.getEvent<string>('diagram-template').subscribe(async (id: string) => {
				const level2 = await this.getLevel2(id);
			});
		}
	}

	async getData() {
		this.loading_all = true;
		this.loading = true;

		await this.getQueryData();

		this.eventService.setStartup();

		await this.getTaxonomyTreeNode();

		await this.getTableData();

		await this.getTechnologies();

		await this.selectionTreeNode(null);

		this.loading = false;
		this.loading_all = false;
	}

	_queryData: {
		common: { commonData: { get: CommonDataModelObjectType } };
		e2eTaxonomy: {
			technology: { fetch: TechnologyModelObjectType };
			categoryFunction: { fetch: CategoryFunctionModelObjectType[] };
		};
	};

	async getQueryData() {
		this._queryData = (await this.apiService.client.query({
			common: {
				commonData: {
					get: {
						...this.apiService.queryArgs({ key: 'technologies_sort' }),
						key: true,
						data: true,
					},
				},
			},
			e2eTaxonomy: {
				technology: {
					fetch: {
						...this.apiService.queryArgs({ neto: false }),
						id: true,
						name: true,
						svg: true,
					},
				},
				categoryFunction: {
					fetch: {
						...this.apiService.mergeArgs(
							await this.apiService.organizationTaxonomy(),
							this.apiService.sortArgs('name'),
						),
						__typename: true,
						id: true,
						name: true,
						office: true,
						type: true,
						taxonomy: {
							industry: {
								id: true,
							},
						},
						functions: {
							...this.apiService.mergeArgs(
								await this.apiService.organizationTaxonomy(),
								this.apiService.sortArgs('name'),
							),
							__typename: true,
							id: true,
							name: true,
							type: true,
							overall: true,
							diagram: { id: true, xmlImage: true },
							levels1: {
								...this.apiService.mergeArgs(
									await this.apiService.organizationTaxonomy(),
									this.apiService.sortArgs('name'),
								),
								__typename: true,
								id: true,
								name: true,
								diagram: { id: true, xmlImage: true },
								levels2: {
									...this.apiService.mergeArgs(
										await this.apiService.organizationTaxonomy(),
										this.apiService.sortArgs('name'),
									),
									__typename: true,
									id: true,
									name: true,
									description: true,
									values: true,
									diagram: { id: true, xmlImage: true },
									aiSolutions: {
										id: true,
										name: true,
										impactAssessment: true,
									},
								},
							},
						},
					},
				},
			},
		})) as any;
	}

	getPathOffice(
		type: 'baseline' | 'industry',
		office: string,
	): {
		id: string;
		label: string;
	} {
		office = getEnumValue(office);
		return {
			id: `all_${type}`,
			label: { '1': 'Front Office', '2': 'Middle Office', '3': 'Back Office' }[office] || '',
		};
	}

	async getTaxonomyTreeNode() {
		const baselineOfficeTreeNode: TreeNode[] = [];
		const industryOfficeTreeNode: TreeNode[] = [];

		((this._queryData.e2eTaxonomy?.categoryFunction?.fetch || []) as CategoryFunctionModelObjectType[])
			.filter((cf) => !!cf)
			.filter((cf) => !!(cf.functions || []).length)
			.forEach((cf) => {
				const office = getEnumValue(cf.office);
				// console.log('Processing node:', cf.name, 'Office:', office);

				if (cf.taxonomy?.industry?.id) {
					industryOfficeTreeNode.push(this.createNode(cf, 'industry', office));
				} else {
					baselineOfficeTreeNode.push(this.createNode(cf, 'baseline', office));
				}
			});

		this.baselineOfficeTreeNode = baselineOfficeTreeNode;
		this.baselineFrontOfficeTreeNode = baselineOfficeTreeNode.filter((node) => node.data.office === '1');
		this.baselineMiddleOfficeTreeNode = baselineOfficeTreeNode.filter((node) => node.data.office === '2');
		this.baselineBackOfficeTreeNode = baselineOfficeTreeNode.filter((node) => node.data.office === '3');

		this.industryOfficeTreeNode = industryOfficeTreeNode;
		this.industryFrontOfficeTreeNode = industryOfficeTreeNode.filter((node) => node.data.office === '1');
		this.industryMiddleOfficeTreeNode = industryOfficeTreeNode.filter((node) => node.data.office === '2');
		this.industryBackOfficeTreeNode = industryOfficeTreeNode.filter((node) => node.data.office === '3');

		// console.log('Baseline Front Office:', this.baselineFrontOfficeTreeNode);
		// console.log('Baseline Mid Office:', this.baselineMiddleOfficeTreeNode);
		// console.log('Baseline Back Office:', this.baselineBackOfficeTreeNode);
		// console.log('Industry Front Office:', this.industryFrontOfficeTreeNode);
		// console.log('Industry Mid Office:', this.industryMiddleOfficeTreeNode);
		// console.log('Industry Back Office:', this.industryBackOfficeTreeNode);
	}

	createNode(cf: CategoryFunctionModelObjectType, type: 'baseline' | 'industry', office: string): TreeNode {
		return {
			key: cf.id,
			label: cf.name,
			expanded: false,
			data: {
				office: office,
				type: getEnumValue(cf.type),
				paths: [this.getPathOffice(type, office), { id: '', label: cf.name }],
			},
			children: (cf.functions || [])
				.filter((f): f is FunctionModelObjectType => f !== null)
				.map((f) => this.createChildNode(f, type, cf, office)),
		};
	}

	createChildNode(
		f: FunctionModelObjectType,
		type: 'baseline' | 'industry',
		cf: CategoryFunctionModelObjectType,
		office: string,
	): TreeNode {
		return {
			key: f.id,
			label: f.name,
			data: {
				paths: [this.getPathOffice(type, office), { id: cf.id, label: cf.name }, { id: '', label: f.name }],
			},
			children: (f.levels1 || [])
				.filter((l1): l1 is Level1ModelObjectType => l1 !== null)
				.map((l1) => this.createLevel1Node(l1, type, cf, f, office)),
		};
	}

	createLevel1Node(
		l1: Level1ModelObjectType,
		type: 'baseline' | 'industry',
		cf: CategoryFunctionModelObjectType,
		f: FunctionModelObjectType,
		office: string,
	): TreeNode {
		return {
			key: l1.id,
			label: l1.name,
			data: {
				paths: [
					this.getPathOffice(type, office),
					{ id: cf.id, label: cf.name },
					{ id: f.id, label: f.name },
					{ id: '', label: l1.name },
				],
			},
		};
	}

	countAiSolutions(aiSolutions: any[]): number[] {
		const result = [aiSolutions.length, 0, 0, 0];

		aiSolutions.forEach((item) => {
			const value = item.impactAssessment?.general;

			if (value === 'Breakthrough') result[1]++;
			else if (value === 'Transformative') result[2]++;
			else if (value === 'Incremental') result[3]++;
		});

		return result;
	}

	getAiSolutionEvaluations(aiSolutions: any[]): { 
		Breakthrough: { id: string; name: string }[]; 
		Transformative: { id: string; name: string }[]; 
		Incremental: { id: string; name: string }[]; 
	} { 
		// console.log('aiSolutions', aiSolutions);
	
		const evaluations = {
			Breakthrough: aiSolutions
				.filter((item) => item.impactAssessment?.general === "Breakthrough")
				.map((item) => ({ id: item.id, name: item.name })),
	
			Transformative: aiSolutions
				.filter((item) => item.impactAssessment?.general === "Transformative")
				.map((item) => ({ id: item.id, name: item.name })),
	
			Incremental: aiSolutions
				.filter((item) => item.impactAssessment?.general === "Incremental")
				.map((item) => ({ id: item.id, name: item.name })),
		};
	
		return evaluations;
	}
	
	
	getAiSolutionsForLevel(level: FunctionModelObjectType | Level1ModelObjectType | Level2ModelObjectType): any[] {
		if ('levels1' in level && level.levels1) {
			return level.levels1
				.filter((l1): l1 is Level1ModelObjectType => !!l1)
				.flatMap((l1) => this.getAiSolutionsForLevel(l1)); 
		} else if ('levels2' in level && level.levels2) {
			return level.levels2
				.filter((l2): l2 is Level2ModelObjectType => !!l2)
				.flatMap((l2) => l2.aiSolutions || []);
		} else if ('aiSolutions' in level) {
			return level.aiSolutions || [];
		}
		return [];
	}
	
	computeValues(level: any, childProperty: string): number[] {
		if (childProperty == "aiSolutions") {
			return this.countAiSolutions(level.aiSolutions || []);
		}

		const values = [0, 0, 0, 0];
		const children = (level[childProperty] || []) as any[];

		const childPropertyMap: any = {
			functions: 'levels1',
			levels1: 'levels2',
			levels2: 'aiSolutions',
		};

		children.forEach((child) => {
			const childValues = this.computeValues(child, childPropertyMap[childProperty]);
			for (let i = 0; i < values.length; i++) {
				values[i] += childValues[i];
			}
		});

		return values;
	}

	async getTableData() {
		const data: Data = {};
		const foBaselineData: GroupCards = { label: 'Front Office', cards: [] };
		const moBaselineData: GroupCards = { label: 'Middle Office', cards: [] };
		const boBaselineData: GroupCards = { label: 'Back Office', cards: [] };
		const foIndustryData: GroupCards = { label: 'Front Office', cards: [] };
		const moIndustryData: GroupCards = { label: 'Middle Office', cards: [] };
		const boIndustryData: GroupCards = { label: 'Back Office', cards: [] };
	
		const emptyDiagram = '../../../../assets/rtp/img/thumb_emptyDiagram.svg';
	
		((this._queryData.e2eTaxonomy?.categoryFunction?.fetch || []) as CategoryFunctionModelObjectType[])
			.filter((cf) => !!cf)
			.filter((cf) => !!(cf.functions || []).length)
			.forEach((cf) => {
				const cfCard: Card = {
					id: cf.id,
					label: cf.name,
					aiSolutionsEvaluations: this.getAiSolutionEvaluations(
						(cf.functions || [])
							.filter((f): f is FunctionModelObjectType => f !== null)
							.flatMap((f) => this.getAiSolutionsForFunction(f)) || []
					),
					values: this.computeValues(cf, 'functions'),
					diagram: '',
					diagramImage: '',
					type: cf.__typename,
					goTo: true,
					baseLevel: true,
					paths: [],
				};
				const pathBase = { id: '', label: '' };
				const pathCF = { id: '', label: cf.name };
				if (cf.taxonomy?.industry?.id) {
					pathBase.id = 'all_industry';
					const office = getEnumValue(cf.office);
					if (office === '1') {
						pathBase.label = 'Front Office';
						cfCard.paths.push(pathBase);
						cfCard.paths.push(pathCF);
						foIndustryData.cards.push(cfCard);
					} else if (office === '2') {
						pathBase.label = 'Middle Office';
						cfCard.paths.push(pathBase);
						cfCard.paths.push(pathCF);
						moIndustryData.cards.push(cfCard);
					} else if (office === '3') {
						pathBase.label = 'Back Office';
						cfCard.paths.push(pathBase);
						cfCard.paths.push(pathCF);
						boIndustryData.cards.push(cfCard);
					}
				} else {
					const office = getEnumValue(cf.office);
					pathBase.id = 'all_baseline';
					if (office === '1') {
						pathBase.label = 'Front Office';
						cfCard.paths.push(pathBase);
						cfCard.paths.push(pathCF);
						foBaselineData.cards.push(cfCard);
					} else if (office === '2') {
						pathBase.label = 'Middle Office';
						cfCard.paths.push(pathBase);
						cfCard.paths.push(pathCF);
						moBaselineData.cards.push(cfCard);
					} else if (office === '3') {
						pathBase.label = 'Back Office';
						cfCard.paths.push(pathBase);
						cfCard.paths.push(pathCF);
						boBaselineData.cards.push(cfCard);
					}
				}
				const cfData: GroupCards = {
					label: cf.name,
					cards: [],
				};
				((cf.functions || []) as FunctionModelObjectType[])
					.filter((f): f is FunctionModelObjectType => f !== null)
					.forEach((f) => {
						const functionAiSolutions = [...this.getAiSolutionsForLevel(f)];
	
						const fCard: Card = {
							id: f.id,
							label: f.name,
							values: this.computeValues(f, 'levels1'),
							aiSolutionsEvaluations: this.getAiSolutionEvaluations(functionAiSolutions),
							diagram: f.diagram?.id || '',
							diagramImage: f.diagram?.xmlImage || emptyDiagram,
							type: f.__typename,
							goTo: true,
							baseLevel: false,
							paths: [pathBase, { id: cf.id, label: cf.name }, { id: f.id, label: f.name }],
						};
	
						cfData.cards.push(fCard);
	
						const fData: GroupCards = {
							label: f.name,
							cards: [],
						};
	
						((f.levels1 || []) as Level1ModelObjectType[])
							.filter((l1): l1 is Level1ModelObjectType => l1 !== null)
							.forEach((l1) => {
								const level1AiSolutions = this.getAiSolutionsForLevel(l1) || [];
	
								const l1Card: Card = {
									id: l1.id,
									label: l1.name,
									values: this.computeValues(l1, 'levels2'),
									diagram: l1.diagram?.id || '',
									diagramImage: l1.diagram?.xmlImage || emptyDiagram,
									aiSolutionsEvaluations: this.getAiSolutionEvaluations(level1AiSolutions),
									type: l1.__typename,
									goTo: true,
									baseLevel: false,
									paths: [
										pathBase,
										{ id: cf.id, label: cf.name },
										{ id: f.id, label: f.name },
										{ id: '', label: l1.name },
									],
								};
	
								fData.cards.push(l1Card);
								
								const l1Data: GroupCards = {
									label: l1.name,
									cards: [],
								};
	
								((l1.levels2 || []) as Level2ModelObjectType[])
									.filter((l2): l2 is Level2ModelObjectType => l2 !== null)
									.forEach((l2) => {
										const level2AiSolutions = l2.aiSolutions || [];
	
										const l2Card: Card = {
											id: l2.id,
											label: l2.name,
											values: this.computeValues(l2, 'aiSolutions'),
											aiSolutionsEvaluations: this.getAiSolutionEvaluations(level2AiSolutions),
											diagram: l2.diagram?.id || '',
											diagramImage: l2.diagram?.xmlImage || emptyDiagram,
											type: l2.__typename,
											goTo: false,
											baseLevel: false,
											paths: [],
										};
	
										l1Data.cards.push(l2Card);
									});
	
								data[l1.id] = [l1Data];
							});
	
						data[f.id] = [fData];
					});
				data[cf.id] = [cfData];
			});
		data['all_baseline'] = [foBaselineData, moBaselineData, boBaselineData];
		data['all_industry'] = [foIndustryData, moIndustryData, boIndustryData];
		this.data = data;
		this.onUpdate();
	}

	getAiSolutionsForFunction(func: FunctionModelObjectType): any[] {
		let aiSolutions: any[] = [];
	
		func.levels1?.forEach((level1) => {
			level1?.levels2?.forEach((level2) => {
				if (level2?.aiSolutions) {
					aiSolutions = aiSolutions.concat(level2.aiSolutions ?? []);
				}
			});
		});

		return aiSolutions;
	}

	async getLevel2(id: string) {
		const data = await this.apiService.client.query({
			e2eTaxonomy: {
				level2: {
					get: {
						...this.apiService.idArgs(id),
						id: true,
						name: true,
						description: true,
						values: true,
					},
				},
			},
		});

		return data.e2eTaxonomy?.level2?.get as ILevel2 | undefined;
	}

	async getTechnologies() {
		this.technologySort = JSON.parse(this._queryData.common.commonData?.get?.data || '[]');
		return ((this._queryData.e2eTaxonomy?.technology?.fetch || []) as unknown as TechnologyModelObjectType[]).sort(
			(a, b) => {
				if (this.technologySort && this.technologySort.length) {
					return (
						this.technologySort.findIndex((v) => v === a.id) -
						this.technologySort.findIndex((v) => v === b.id)
					);
				} else {
					return 0;
				}
			},
		);
	}

	async clearSelectionTreeNode() {
		if (this.treeNodeSelected) {
			this.treeNodeSelected = undefined;
			await this.selectionTreeNode(null);
		}
	}

	async selectionTreeNode(evt: TreeNode | null) {
		if (evt) {
			this.treeNodeSelected = evt;
			this.expandTreeNode(evt);
		}

		if (this.treeNodeSelected && this.treeNodeSelected.key) {
			this.currentData = this.data[this.treeNodeSelected.key];
		} else {
			this.currentData = this.selectionTypeFilter === '1' ? this.data['all_baseline'] : this.data['all_industry'];
		}

		this.currentPath = this.treeNodeSelected?.data?.paths?.length ? this.treeNodeSelected.data.paths : [];

		if (this.scrollList) {
			this.scrollList.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
		}

		if (this.scrollGrid) {
			this.scrollGrid.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

	selectionTypeFilter: string = '1';

	selectionTypeFilterOptions: { value: string; label: string }[] = [
		{
			value: '1',
			label: 'Baseline',
		},
		{
			value: '2',
			label: 'Industry',
		},
	];

	expandTreeNode(node: TreeNode) {
		const expandPath = (targetNode: TreeNode, nodes: TreeNode[]) => {
			for (const n of nodes) {
				if (n === targetNode || this.isAncestor(n, targetNode)) {
					n.expanded = true;
				} else {
					n.expanded = false;
				}

				if (n.children) {
					expandPath(targetNode, n.children);
				}
			}
		};

		const nodes = this.selectionTypeFilter === '1' ? this.baselineOfficeTreeNode : this.industryOfficeTreeNode;
		expandPath(node, nodes);
	}

	isAncestor(ancestor: TreeNode, target: TreeNode): boolean {
		if (!ancestor.children) return false;

		for (const child of ancestor.children) {
			if (child === target || this.isAncestor(child, target)) {
				return true;
			}
		}
		return false;
	}

	async selectionType() {
		this.treeNodeSelected = undefined;
		await this.selectionTreeNode(null);
	}

	async selectCard(card: Card) {
		if (card.goTo) {
			const node = this.findTreeNodeById(
				card.id,
				this.selectionTypeFilter === '1' ? this.baselineOfficeTreeNode : this.industryOfficeTreeNode,
			);
			if (node) {
				await this.selectionTreeNode(node);
			}
		}
	}

	findTreeNodeById(id: string, nodes: TreeNode[]): TreeNode | null {
		for (const node of nodes) {
			if (node.key === id) {
				return node;
			}
			if (node.children) {
				const found = this.findTreeNodeById(id, node.children);
				if (found) {
					return found;
				}
			}
		}
		return null;
	}

	filterTreeNodes(query: string) {
		// console.log('Filtering tree nodes with query:', query);

		if (!query || query.trim() === '') {
			// console.log('Query is empty, resetting nodes.');
			this.resetFilteredTreeNodes();
			return;
		}

		query = query.toLowerCase();

		this.baselineFrontOfficeTreeNode = this.filterNodes(query, this.baselineFrontOfficeTreeNode);
		this.baselineMiddleOfficeTreeNode = this.filterNodes(query, this.baselineMiddleOfficeTreeNode);
		this.baselineBackOfficeTreeNode = this.filterNodes(query, this.baselineBackOfficeTreeNode);

		this.industryFrontOfficeTreeNode = this.filterNodes(query, this.industryFrontOfficeTreeNode);
		this.industryMiddleOfficeTreeNode = this.filterNodes(query, this.industryMiddleOfficeTreeNode);
		this.industryBackOfficeTreeNode = this.filterNodes(query, this.industryBackOfficeTreeNode);

		// console.log('Filtered Baseline Front Office:', this.baselineFrontOfficeTreeNode);
		// console.log('Filtered Baseline Mid Office:', this.baselineMiddleOfficeTreeNode);
		// console.log('Filtered Baseline Back Office:', this.baselineBackOfficeTreeNode);

		this.treeNodeSelected = undefined;
		this.selectionTreeNode(null);
	}

	filterNodes(query: string, nodes: TreeNode[]): TreeNode[] {
		// console.log('Filtering nodes:', nodes, 'Query:', query);

		return nodes
			.map((node) => {
				// console.log('Checking node:', node.label, 'Office:', node.data?.office);

				const matches = node.label?.toLowerCase().includes(query) || false;
				const filteredChildren = node.children ? this.filterNodes(query, node.children) : [];
				if (matches || filteredChildren.length > 0) {
					return {
						...node,
						children: filteredChildren,
						expanded: filteredChildren.length > 0,
					};
				}
				return null;
			})
			.filter((node) => node !== null) as TreeNode[];
	}

	resetFilteredTreeNodes() {
		this.getTaxonomyTreeNode();
	}

	async selectPath(paths: { id: string; label: string }[], i: number) {
		if (paths[i].id) {
			const path = paths[i];
			const pathsNode = paths.slice(0, i + 1);
			await this.selectionTreeNode({
				label: path.label,
				key: path.id,
				data: { paths: pathsNode.length > 1 ? pathsNode : [] },
			});
		}
	}

	// Legacy

	async editDiagramTemplate(event: Event, card: Card) {
		if (!card.diagram) {
			return;
		}
		event.stopPropagation();
		const keys: { [key: string]: string } = {
			CategoryFunctionModelObjectType: '',
			FunctionModelObjectType: 'diagramE2EId',
			Level1ModelObjectType: 'diagramProcessId',
			Level2ModelObjectType: 'diagramTemplateId',
		};
		const key = keys[card.type];
		if (key) {
			if (key === 'diagramE2EId') {
				await this.e2ETaxonomyService.setCurrentFunction(card.id, true);
			} else if (key === 'diagramProcessId') {
				await this.e2ETaxonomyService.setCurrentLevel1(card.id, true);
			} else if (key === 'diagramTemplateId') {
				await this.e2ETaxonomyService.setCurrentLevel2(card.id, true);
			}
			const ref = this.diagramService.openDiagramModal({
				[key]: card.diagram,
			});
			if (ref) {
				ref.onClose.subscribe(async () => {
					this.eventService.nextEventSimple('reloadTaxonomyData');
				});
			}
		}
	}
}
