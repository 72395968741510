<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<ng-template [ngIf]="aiSolution">
	<div class="use-case-library-item text-color-base px-3">
		<div class="flex flex-wrap">
			<div class="col-12 pt-0 pl-0 pr-2">
				<h3 class="text-lg m-0 text-primary mb-3">{{ aiSolution.name }}</h3>
				<p class="text-color-base">{{ aiSolution.description }}</p>
			</div>
		</div>


		<div class="flex flex-wrap">
			<div class="col-2 p-0 pr-2">
				<div class="box-simulation-item p-0 h-full">
					<div class="flex flex-column gap-0 h-full">
						<div
							class="flex justify-content-left align-items-center min-h-3rem font-bold bg-blue-50 px-3 py-2">
							<p class="title-impact py-1"><i class="pi pi-bullseye mr-2"></i> Impact</p>
						</div>
						<div class="flex justify-content-left align-items-center flex-grow-1 px-3">
							<div class="flex flex-wrap gap-1">
								<span style="font-size: 85%"
									  class="impact breakthrough">
									{{ aiSolution.impactAssessment.general }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-5 p-0 px-2">
				<div class="box-simulation-item p-0 h-full">
					<div class="flex flex-column gap-0 h-full">
						<div
							class="flex justify-content-left align-items-center min-h-3rem font-bold header-business px-3 py-2">
							<p class="text-primary"><i class="pi pi-database mr-2 py-1"></i> Data Requirements</p>
						</div>
						<div class="flex justify-content-left align-items-center flex-grow-1 px-3">
							<p class="text-color-base py-2">
								{{ aiSolution.allDataRequirements }}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="col-5 p-0 pl-2">
				<div class="box-simulation-item p-0 h-full">
					<div class="flex flex-column gap-0 h-full">
						<div
							class="flex justify-content-left align-items-center min-h-3rem font-bold header-business px-3 py-2">
							<p class="text-primary">
								<i class="pi pi-exclamation-circle mr-2 py-1"></i> Implementation Considerations
							</p>
						</div>
						<div class="flex justify-content-left align-items-center flex-grow-1 px-3">
							<p class="text-color-base py-2">
								{{ aiSolution.potentialChallenges }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--TOP BENEFITS-->
		<p class="title-impact pt-3" style="font-size: 16px">
			<app-svg-benefit></app-svg-benefit> Top Benefits
		</p>

		<div class="col-12 box-simulation-item p-0 mt-3">
			<div class="flex flex-wrap">
				<ng-template ngFor let-value [ngForOf]="aiSolution.allTopBenefits">
					<div class="col p-0">
						<div class="flex flex-column gap-0">
							<div
								class="header-business flex justify-content-center align-items-center h-3rem font-bold"
							>
								<p>{{ value.title }}</p>
							</div>
							<div class="flex justify-content-center align-items-center h-5rem">
								<p class="w-5rem h-2rem" [ngClass]="value.className">{{ value.label }}</p>
							</div>
						</div>
					</div>
				</ng-template>
			</div>
		</div>

		<!-- AI AGENTS -->
		<div class="col-12 px-0 py-3">
			<div class="box-simulation-item p-0">
				<div class="flex flex-column gap-4 pb-4">
					<div class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3">
						<div class="text-primary flex gap-2" style="font-size: 16px; line-height: 25px;">
							<app-svg-ai-agent fill="blue"></app-svg-ai-agent> AI Agents
						</div>
					</div>
					<p class="px-3">{{aiSolution.allAgents.description}}</p>
					<span class="px-3 font-bold">Agents Involved:</span>
					<div class="text-black-alpha-90 opportunity-item flex flex-row flex-wrap gap-2 px-3 justify-content-evenly">
						<ng-template ngFor let-agent [ngForOf]="aiSolution.allAgents.agents || []">
							<div class="ai-agent flex flex-column {{agent.className}}">
								<div>
									<app-svg-ai-agent [fill]="agent.className"></app-svg-ai-agent>
								</div>
								<div class="label">
									{{ agent.label }}
								</div>
								<div class=" {{agent.className}}">
									<app-svg-must-have-icon [value]="agent.mustHave"></app-svg-must-have-icon>
									<!-- {{ agent.mustHaveLabel }} -->
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 text-right">
			<p-button
				label="Add to My AI Solution"
				icon="pi pi-plus"
				class="ml-2"
				[loading]="addToMyUseCaseLoading"
				(onClick)="addToMyUseCase()"
			></p-button>
		</div>
	</div>
</ng-template>
