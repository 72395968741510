import { ApiService, InputFieldArg } from '../../service/api.service';
import { Injectable } from '@angular/core';
import { AssessmentMaturity, CategoryQuestionMaturity, ICategoryQuestionMaturity } from '../maturity.api';

@Injectable({
	providedIn: 'root',
})
export class MaturityApiService {

	constructor(private apiService: ApiService) {
	}

	async putObject(data: AssessmentMaturity): Promise<Object> {
		return this.apiService.putObject<any>(data, 'assessment_maturity', true);
	}

	async fetchSubCategoryQuestion(category: string) {
		const queryArgs = await this.apiService.getQueryArgs({
			includeOrganization: true,
			queryArgs: { category },
			sortKeys: ['sort'],
		});

		const response = await this.apiService.client.query({
			maturity: {
				subCategoryQuestion: {
					fetch: {
						...queryArgs,
						id: true,
						name: true,
						sort: true,
						categoryId: true,
					},
				},
			},
		});

		return this.apiService.findQueryResponse(response);
	}

	async fetchCategoryQuestion(fields: InputFieldArg[] = ['id', 'name']): Promise<ICategoryQuestionMaturity[]> {
		const queryArgs = await this.apiService.getQueryArgs({
			includeOrganization: true,
			sortKeys: ['sort'],
			fields
		});

		const response = await this.apiService.client.query({
			maturity: {
				categoryQuestion: {
					fetch: queryArgs,
				},
			},
		});

		return this.apiService.findQueryResponse(response) as CategoryQuestionMaturity[];
	}

	async fetchAssessment(fields: InputFieldArg[] = ['id', 'formData']): Promise<AssessmentMaturity[]> {
		const queryArgs = await this.apiService.getQueryArgs({
			includeOrganization: true,
			fields
		});

		const response = await this.apiService.client.query({
			maturity: {
				assessment: {
					fetch: queryArgs
				},
			},
		});

		return this.apiService.findQueryResponse(response) as AssessmentMaturity[];
	}
}
