import { Component, Inject, Input, OnInit } from '@angular/core';
import { UseCaseLibrary } from '../../../api/simulation.api';
import { SimulationService } from '../simulation.service';
import { E2ETaxonomyService } from '../../../service/e2e-taxonomy.service';
import { Technology } from '../../../api/e2e-taxonomy.api';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { UseCaseService } from '../../../service/use-case.service';
import { AuthService } from '../../../service/auth.service';
import { UseCase } from '../../../api/use-case.api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Organization } from 'src/app/rtp/api/common';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from '../../common/loading/loading.service';

@Component({
	selector: 'app-use-case-library-item',
	templateUrl: './use-case-library-item.component.html',
	styleUrls: ['./use-case-library-item.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class UseCaseLibraryItemComponent implements OnInit {
	@Input() useCase?: UseCaseLibrary;
	@Input() diagram?: DynamicDialogRef;

	@Inject(DynamicDialogRef) ref: DynamicDialogRef | undefined = undefined;


	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		private e2eTaxonomyService: E2ETaxonomyService,
		private simulationService: SimulationService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public useCaseService: UseCaseService,
		public authService: AuthService,
		public loadingService: LoadingService,
	) {
	}

	valuesDriversScores: { title: string; label: string; className: string }[] = [];

	getColorFromClass(className: string): string {
		switch (className) {
			case 'background-low':
				return '#9f5252';
			case 'background-medium':
				return '#8d744e';
			case 'background-high':
				return '#579043';
			default:
				return '#000000';
		}
	}

	getBackgroundColorFromClass(className: string): string {
		switch (className) {
			case 'background-none':
				return '#f9fafc';
			case 'background-low':
				return '#fbeded';
			case 'background-medium':
				return '#fcf2d7';
			case 'background-high':
				return '#dbf3de';
			default:
				return '#FFFFFF';
		}
	}

	valuesDriversScoresLabel(value: number = 0) {
		let label = '';
		if (value === 0) {
			label = '---';
		} else if (value < 4) {
			label = 'Low';
		} else if (value >= 4 && value <= 7) {
			label = 'Medium';
		} else {
			label = 'High';
		}
		return label;
	}

	valuesDriversScoresClassName(value: number = 0) {
		let className = '';
		if (value === 0) {
			className = 'background-none';
		} else if (value < 4) {
			className = 'background-low';
		} else if (value >= 4 && value <= 7) {
			className = 'background-medium';
		} else {
			className = 'background-high';
		}
		return className;
	}

	aiEnablers: Technology[] = [];
	organization: Organization | undefined = undefined;
	embeddedAIData: {
		platform: string;
		vendors: { vendor: string; functionalities: { name: string; description_and_implementation: string }[] }[];
	}[] = [];

	async ngOnInit() {
		this.organization = await this.authService.getCurrentOrganization();

		if (this.useCase) {
			if (
				this.organization &&
				this.useCase.data['embeddedAI'] &&
				this.useCase.data['embeddedAI'][this.organization.id as string]
			) {
				this.embeddedAIData = this.useCase.data['embeddedAI'][this.organization.id as string] || [];
			}

			await this.e2eTaxonomyService.getTechnologies().then((aiEnablers) => {
				this.aiEnablers = aiEnablers.map((t) => {
					t.svg = this.safeHTML(t.svg) as any;
					return t;
				});
			});
			this.valuesDriversScores = [
				{
					title: 'Revenue Growth',
					label: this.valuesDriversScoresLabel(this.useCase.data?.valuesDriversScores?.revenue_growth),
					className: this.valuesDriversScoresClassName(
						this.useCase.data?.valuesDriversScores?.revenue_growth,
					),
				},
				{
					title: 'Customer Experience',
					label: this.valuesDriversScoresLabel(this.useCase.data?.valuesDriversScores?.customer_experience),
					className: this.valuesDriversScoresClassName(
						this.useCase.data?.valuesDriversScores?.customer_experience,
					),
				},
				{
					title: 'Process Productivity',
					label: this.valuesDriversScoresLabel(this.useCase.data?.valuesDriversScores?.process_productivity),
					className: this.valuesDriversScoresClassName(
						this.useCase.data?.valuesDriversScores?.process_productivity,
					),
				},
				{
					title: 'Employee Productivity',
					label: this.valuesDriversScoresLabel(this.useCase.data?.valuesDriversScores?.employee_productivity),
					className: this.valuesDriversScoresClassName(
						this.useCase.data?.valuesDriversScores?.employee_productivity,
					),
				},
				{
					title: 'Cost Savings',
					label: this.valuesDriversScoresLabel(this.useCase.data?.valuesDriversScores?.cost_savings),
					className: this.valuesDriversScoresClassName(this.useCase.data?.valuesDriversScores?.cost_savings),
				},
			];
		}
	}

	safeHTML(html: string) {
		const parser = new DOMParser();
		const document = parser.parseFromString(html, 'text/html');
		return this.sanitizer.bypassSecurityTrustHtml(document.body.outerHTML);
	}

	addToMyUseCaseLoading: boolean = false;

	addToMyUseCase() {
		if (!this.addToMyUseCaseLoading) {
			this.confirmationService.confirm({
				header: 'Add to your AI Solutions?',
				message: 'This will add the AI Solution to your AI Solutions.',
				icon: 'pi pi-sparkles',
				acceptLabel: 'Add and edit',
				acceptIcon: 'pi pi-window-maximize',
				accept: () => {
					this._addToMyUseCase(true);
				},
				rejectLabel: 'Add',
				rejectIcon: 'pi pi-plus',
				reject: (type: ConfirmEventType) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this._addToMyUseCase(false);
							break;
					}
				},
			});
		}
	}

	async _addToMyUseCase(go: boolean) {
		this.loadingService.show();
		try {
			this.addToMyUseCaseLoading = true;
			if (this.useCase) {
				await this.useCaseService.setCurrentUseCase('', {});
				if (this.useCaseService.currentUseCase && this.useCase) {
					this.useCaseService.currentUseCase.name = this.useCase.name;
					this.useCaseService.currentUseCase.description = this.useCase.description;
					this.useCaseService.currentUseCase.diagram = this.useCaseService._newDiagramEmpty(
						this.useCaseService.currentUseCase,
					);
					this.useCaseService.currentUseCase.libraryData = { ...this.useCase };
					const organization = await this.authService.getCurrentOrganization();
					if (organization && this.useCaseService.currentUseCase?.diagram) {
						this.useCaseService.currentUseCase.diagram.organization = organization;
						this.useCaseService.currentUseCase.diagram.organizationId = organization.id as string;
						this.useCaseService.currentDiagram = this.useCaseService.currentUseCase.diagram;
					}

					const error = await this.useCaseService.saveCurrentUseCase();
					if (error) {
						this.messageSaveUseCase(error);
					} else {
						this.messageSaveUseCase();
						if (go && this.useCaseService.currentUseCase && this.ref) {
							this.ref.close();
							if (this.diagram) {
								this.diagram.close();
							}
							await this.goToMyUseCase(this.useCaseService.currentUseCase);
						}
					}
				}
			}
			this.addToMyUseCaseLoading = false;
		} finally {
			this.loadingService.hide();
		}
	}

	messageSaveUseCase(error?: string) {
		if (error) {
			if (error === 'no-name') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the new AI Solution',
				});
			} else if (error === 'no-description') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the new AI Solution',
				});
			} else if (error === 'no-updated') {
				this.messageService.add({ severity: 'warn', summary: 'No changes', detail: 'Nothing to save' });
			} else {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
			}
		} else {
			this.messageService.add({
				severity: 'success',
				summary: 'Confirmed',
				detail: 'AI Solution saved on My AI Solutions',
			});
		}
	}

	async goToMyUseCase(useCase: UseCase) {
		await this.useCaseService.goToUseCase(useCase.id as string, true);
	}

	embeddedAILoading: boolean = false;
	missingFields: string[] = [];

	async generateEmbeddedAI() {
		if (this.useCase) {
			this.embeddedAILoading = true;
			this.embeddedAIData = [];

			if (!this.useCase.data['embeddedAI'] || Array.isArray(this.useCase.data['embeddedAI'])) {
				this.useCase.data['embeddedAI'] = {};
			}

			if (this.simulationService.enterpriseContext === undefined) {
				await this.simulationService.getEnterpriseContext();
			}

			this.missingFields = [];

			if (!this.organization) {
				this.missingFields.push('Organization');
			}

			if (!this.simulationService?.enterpriseContext?.enterpriseContext?.description?.trim()) {
				this.missingFields.push('Description');
			}

			if (!this.simulationService?.enterpriseContext?.enterpriseContext?.industry?.length) {
				this.missingFields.push('Industry');
			}

			// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.revenue) {
			// 	this.missingFields.push('Revenue');
			// }

			// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.information?.length) {
			// 	this.missingFields.push('Information');
			// }

			// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.employee) {
			// 	this.missingFields.push('Employee');
			// }

			if (!this.simulationService?.enterpriseContext?.enterpriseContext?.data_landscape?.length) {
				this.missingFields.push('Data Landscape');
			}

			if (this.missingFields.length > 0) {
				const detailMessage = `Please fill ${this.missingFields.join(', ')} field${this.missingFields.length > 1 ? 's' : ''} inside Enterprise Context.`;
				this.messageService.add({
					severity: 'error',
					summary: 'Validation Error - Enterprise Context',
					detail: detailMessage,
				});
				this.embeddedAILoading = false;
				this.embeddedAIData = [];
				return;
			}

			const input = {
				name: this.organization?.name || '',
				description: this.simulationService?.enterpriseContext?.enterpriseContext?.description || '',
				additionalNotes: 'Additional Notes',
				scope: ['1'],
				search: false,
				generate: true,
				enterpriseContext: {
					companyName: this.organization?.name || '',
					industry: this.simulationService.enterpriseContext?.enterpriseContext?.industry || [''],
					revenue: this.simulationService.enterpriseContext?.enterpriseContext?.revenue || '',
					information: this.simulationService.enterpriseContext?.enterpriseContext?.information || [],
					employee: this.simulationService.enterpriseContext?.enterpriseContext?.employee || '',
					data_landscape: this.simulationService.enterpriseContext?.enterpriseContext?.data_landscape?.map(
						(d) => {
							const dn: { [key: string]: any } = {};
							dn[d.name] = {
								applicable: d.applicable,
								tech_vendor: d.tech_vendor,
								description: d.description,
							};
							return dn;
						},
					),
				},
				usesCasesContext: {
					aiEnablers: [],
					technologies: [],
					benefits: [],
					impact: {},
					taxonomy: [],
				},
				use_case: {
					...this.useCase,
					context: {
						aiEnablers: this.useCase.data.aiEnablers,
						business_values: [],
						tools: [],
						impact: [this.useCase.data.impact_assessment],
					},
				},
			};

			console.log(input);

			this.http
				.post<{ use_case: any[] }>(environment.url + '/api/ai/use_case_embedded/', JSON.stringify(input))
				.subscribe({
					error: (err) => {
						console.log(err);
						this.embeddedAILoading = false;
					},
					next: (data) => {
						console.log(data);

						if (
							this.useCase &&
							this.organization &&
							data?.use_case &&
							data?.use_case[0] &&
							data?.use_case[0].platforms
						) {
							this.embeddedAIData = data?.use_case[0].platforms || [];
							this.useCase.data['embeddedAI'][this.organization.id as string] = this.embeddedAIData;
							this.simulationService.dbService.data_use_case_library.put(this.useCase);
						}

						this.embeddedAILoading = false;
					},
				});
		}
	}

	embeddedAIRegenerate = false;

	regenerateEmbeddedAI() {
		this.missingFields = [];

		if (!this.organization) {
			this.missingFields.push('Organization');
		}

		if (!this.simulationService?.enterpriseContext?.enterpriseContext?.description?.trim()) {
			this.missingFields.push('Description');
		}

		if (!this.simulationService?.enterpriseContext?.enterpriseContext?.industry?.length) {
			this.missingFields.push('Industry');
		}

		// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.revenue) {
		// 	this.missingFields.push('Revenue');
		// }

		// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.information?.length) {
		// 	this.missingFields.push('Information');
		// }

		// if (!this.simulationService?.enterpriseContext?.enterpriseContext?.employee) {
		// 	this.missingFields.push('Employee');
		// }

		if (!this.simulationService?.enterpriseContext?.enterpriseContext?.data_landscape?.length) {
			this.missingFields.push('Data Landscape');
		}

		if (this.missingFields.length > 0) {
			const detailMessage = `Please fill ${this.missingFields.join(', ')} field${this.missingFields.length > 1 ? 's' : ''} inside Enterprise Context.`;
			this.messageService.add({
				severity: 'error',
				summary: 'Validation Error - Enterprise Context',
				detail: detailMessage,
			});
			this.embeddedAIRegenerate = false;
			return;
		}

		this.confirmationService.confirm({
			header: 'Regenerate Embedded AI?',
			message: 'This will regenerate the Embedded AI. Are you sure?',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Confirm',
			acceptIcon: 'pi pi-check',
			accept: () => {
				this.embeddedAIRegenerate = true;
				this.generateEmbeddedAI().then(() => {
					this.embeddedAIRegenerate = false;
				});
			},
		});
	}
}
