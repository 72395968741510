import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RtpModule } from './rtp/rtp.module';

@NgModule({
	declarations: [AppComponent],
	imports: [AppRoutingModule, RtpModule],
	providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
	bootstrap: [AppComponent],
})
export class AppModule {
}
