<div id="page-taxonomy" class="page p-0">
	<div class="flex gap-0">
		<div
			class="side-filter border-right-1 border-gray-300"
			[ngClass]="{
		'active': showSide,
		'extend': !showSideExtend,
		}"
		>
			<div class="flex flex-column gap-3 page-content justify-content-between">
				<div *ngIf="showSide" class="flex flex-column justify-content-start gap-2">
					<div class="flex justify-content-between gap-3">
						<span class="p-input-icon-right w-full">
							<i class="pi pi-search"></i>
							<input
								type="text"
								pInputText
								placeholder="Search process"
								class="search"
								[(ngModel)]="searchQuery"
								[disabled]="aiExplorer.loading"
								(ngModelChange)="filterSearch()"
							/>
						</span>
						<p-button
							*ngIf="!showSideExtend"
							[icon]="showSide ? 'pi pi-angle-up' : 'pi pi-angle-down'"
							styleClass="button-header"
							(onClick)="toggleSide()"
						></p-button>
					</div>
					<p-selectButton
						[options]="aiExplorer.selectionTypeFilterOptions"
						[(ngModel)]="aiExplorer.selectionTypeFilter"
						styleClass="selection-office-filter"
						(onOptionClick)="aiExplorer.selectionType()"
					>
						<ng-template let-item>
							<div class="flex justify-content-center align-items-center gap-3 w-full">
								<span>{{ item.label }}</span>
							</div>
						</ng-template>
					</p-selectButton>
					<div class="p-tree p-component p-tree-selectable">
						<div class="p-tree-wrapper">
							<ul class="p-tree-container">
								<li class="p-treenode" (click)="aiExplorer.clearSelectionTreeNode()">
									<div
										class="p-treenode-content p-treenode-selectable"
										[ngClass]="!aiExplorer.treeNodeSelected ? 'p-highlight' : ''"
									>
										<button
											class="p-ripple p-element p-tree-toggler p-link"
											style="width: 0"
										></button>
										<div
											class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
										>
											<span>All Taxonomy</span>
											<i *ngIf="aiExplorer.loading_all" class="pi pi-spin pi-spinner mr-1"></i>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="filter-content">
						<div class="p-tree p-component p-tree-selectable label-filter">
							<div class="p-tree-wrapper">
								<ul class="p-tree-container">
									<li class="p-treenode">
										<div class="p-treenode-content">
											<button
												class="p-ripple p-element p-tree-toggler p-link"
												style="width: 0"
											></button>
											<div
												class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
											>
												<span>Front Office</span>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<p-tree
							[value]="
								aiExplorer.selectionTypeFilter === '1'
									? aiExplorer.baselineFrontOfficeTreeNode
									: aiExplorer.industryFrontOfficeTreeNode
							"
							class="w-full"
							[loading]="aiExplorer.loading"
							[loadingIcon]="'pi pi-spin pi-spinner'"
							selectionMode="single"
							[(selection)]="aiExplorer.treeNodeSelected"
							(selectionChange)="aiExplorer.selectionTreeNode($event)"
						></p-tree>
						<div class="p-tree p-component p-tree-selectable label-filter">
							<div class="p-tree-wrapper">
								<ul class="p-tree-container">
									<li class="p-treenode">
										<div class="p-treenode-content">
											<button
												class="p-ripple p-element p-tree-toggler p-link"
												style="width: 0"
											></button>
											<div
												class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
											>
												<span>Mid Office</span>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<p-tree
							[value]="
								aiExplorer.selectionTypeFilter === '1'
									? aiExplorer.baselineMiddleOfficeTreeNode
									: aiExplorer.industryMiddleOfficeTreeNode
							"
							class="w-full"
							[loading]="aiExplorer.loading"
							[loadingIcon]="'pi pi-spin pi-spinner'"
							selectionMode="single"
							[(selection)]="aiExplorer.treeNodeSelected"
							(selectionChange)="aiExplorer.selectionTreeNode($event)"
						></p-tree>
						<div class="p-tree p-component p-tree-selectable label-filter">
							<div class="p-tree-wrapper">
								<ul class="p-tree-container">
									<li class="p-treenode">
										<div class="p-treenode-content">
											<button
												class="p-ripple p-element p-tree-toggler p-link"
												style="width: 0"
											></button>
											<div
												class="flex justify-content-between align-self-center align-content-center align-items-center w-full p-treenode-label overflow-hidden"
											>
												<span>Back Office</span>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<p-tree
							[value]="
								aiExplorer.selectionTypeFilter === '1'
									? aiExplorer.baselineBackOfficeTreeNode
									: aiExplorer.industryBackOfficeTreeNode
							"
							class="w-full"
							[loading]="aiExplorer.loading"
							[loadingIcon]="'pi pi-spin pi-spinner'"
							selectionMode="single"
							[(selection)]="aiExplorer.treeNodeSelected"
							(selectionChange)="aiExplorer.selectionTreeNode($event)"
						></p-tree>
					</div>
				</div>
				<div class="flex justify-content-end align-items-center">
					<p-button
						*ngIf="showSideExtend"
						click-stop-propagation
						class="filter-sidebar-button"
						styleClass="p-button-text text-primary hover:text-white"
						[icon]="'pi ' + (showSide ? 'pi-chevron-circle-left' : 'pi-chevron-circle-right')"
						(onClick)="toggleSide()"
						tippy
						[tippyContent]="showSide ? 'Collapse Panel' : 'Expand Panel'"
						tippyPlacement="bottom"
					></p-button>
				</div>
			</div>
		</div>
		<div
			*ngIf="showSideExtend || (!showSideExtend && !showSide)"
			class="side-content"
			[ngClass]="{
				'active': showSide,
				'extend': !showSideExtend,
			}"
		>
			<div class="flex flex-column gap-3 page-content">
				<div class="flex flex-column justify-content-start w-full">
					<div class="flex justify-content-between mb-1">
						<div class="flex flex-column gap-2">
							<p id="title-section" class="text-large font-bold">
								Hackett Industries Taxonomy Explorer <sup>TM</sup>
							</p>
						</div>
						<div class="flex gap-2">
							<p-selectButton
								[options]="filterTabOptions"
								[(ngModel)]="filterTab"
								styleClass="filter-tab"
							>
								<ng-template let-item>
									<ng-template [ngIf]="!item.value">
										<div class="flex justify-content-center align-items-center gap-3">
											<span class="pi pi-chart-bar"></span>
											<span class="font-bold text-primary">Taxonomy</span>
										</div>
									</ng-template>
									<ng-template [ngIf]="item.value">
										<div class="flex justify-content-center align-items-center gap-3">
											<span class="pi pi-sitemap"></span>
											<span class="font-bold text-primary">Diagrams</span>
										</div>
									</ng-template>
								</ng-template>
							</p-selectButton>

							<p-button icon="pi pi-bars"
							 	styleClass="button-header"
								tippy
								[tippyContent]="'Options'"
								tippyPlacement="bottom"
							 >
							</p-button>

							<p-button
								*ngIf="!showSideExtend"
								[icon]="showSide ? 'pi pi-angle-up' : 'pi pi-angle-down'"
								styleClass="button-header"
								(onClick)="toggleSide()"
								tippy
								[tippyContent]="showSide ? 'Collapse Panel' : 'Expand Panel'"
								tippyPlacement="bottom"
							></p-button>
						</div>
					</div>

					<div *ngIf="aiExplorer.currentPath" class="flex gap-2">
						<ng-template ngFor let-path let-i="index" [ngForOf]="aiExplorer.currentPath">
							<div>
								<span class="cursor-pointer" (click)="aiExplorer.selectPath(aiExplorer.currentPath, i)">
									{{ path.label }}
								</span>
								{{ aiExplorer.currentPath.length - 1 === i ? '' : '/' }}
							</div>
						</ng-template>
					</div>
					<p-scrollPanel
						[style]="{ width: '100%', height: 'calc(100vh - 150px)' }"
						*ngIf="aiExplorer.loading"
					>
						<div class="flex flex-wrap">
							<ng-container>
								<div class="col-6 md:col-6 lg:col-6" *ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8]">
									<mat-card class="custom-card">
										<div class="skeleton-header">
											<p-skeleton height="1rem" width="100%"></p-skeleton>
										</div>
										<div class="icon-container">
											<p-skeleton height="25px" width="25px" shape="square"></p-skeleton>
										</div>
										<mat-card-header class="pb-0">
											<p-skeleton height="1.5rem" width="350px"></p-skeleton>
										</mat-card-header>
										<mat-card-content class="card-content py-0">
											<p-divider></p-divider>
											<div class="metrics">
												<div class="metric">
													<p-skeleton height="1rem" width="100px"></p-skeleton>
													<p class="pt-2">
														<p-skeleton height="1.5rem" width="50px"></p-skeleton>
													</p>
												</div>
												<div class="metric">
													<p-skeleton height="1rem" width="100px"></p-skeleton>
													<p class="pt-2">
														<p-skeleton height="1.5rem" width="50px"></p-skeleton>
													</p>
												</div>
												<div class="metric">
													<p-skeleton height="1rem" width="100px"></p-skeleton>
													<p class="pt-2">
														<p-skeleton height="1.5rem" width="50px"></p-skeleton>
													</p>
												</div>
												<div class="metric">
													<p-skeleton height="1rem" width="100px"></p-skeleton>
													<p class="pt-2">
														<p-skeleton height="1.5rem" width="50px"></p-skeleton>
													</p>
												</div>
											</div>
											<p-divider></p-divider>
										</mat-card-content>
									</mat-card>
								</div>
							</ng-container>
						</div>
					</p-scrollPanel>

					<ng-template [ngIf]="!aiExplorer.loading">
						<p-scrollPanel [style]="{ width: '100%', height: 'calc(100vh - 150px)' }">
							<ng-template ngFor let-groupCards [ngForOf]="aiExplorer.currentData">
								<div class="flex flex-wrap">
									<div class="col-12">
										<span class="font-bold text-lg">{{ groupCards.label }}</span>
									</div>
									<ng-template
										ngFor
										let-card
										[ngForOf]="groupCards.cards"
										*ngIf="groupCards.cards && groupCards.cards.length > 0; else noResults"
									>
										<div *ngIf="filterTab" class="col-6 md:col-6 lg:col-6">
											<div
												class="p-4 border-1 surface-border card-limited surface-card border-round card-template grid-data"
												[ngClass]="{
													'cursor-pointer': card.diagram,
													'cursor-auto': !card.diagram,
												}"
												(click)="aiExplorer.editDiagramTemplate($event, card)"
											>
												<div class="h-full w-full text-center">
													<p class="text-sm font-bold text-primary">{{ card.label }}</p>
													<app-image
														*ngIf="card.diagramImage"
														[src]="card.diagramImage"
														[styleClass]="
															'image-grid' +
															(card.diagramImage.endsWith('thumb_emptyDiagram.svg')
																? ' image-empty'
																: '')
														"
													></app-image>
												</div>
											</div>
										</div>

										<div *ngIf="!filterTab" class="col-6 md:col-6 lg:col-6">
											<mat-card class="custom-card" (click)="aiExplorer.selectCard(card)">
												<div
													class="card-header-blue"
												></div>
												<div class="icon-container">
													<app-hackett-logo class="card-logo"></app-hackett-logo>
												</div>
												<mat-card-header class="card-header pb-0">
													<mat-card-title class="card-title"
														>{{ card.label }}
													</mat-card-title>
												</mat-card-header>
												<mat-card-content class="card-content py-0">
													<p-divider></p-divider>
													<div class="metrics">
														<div class="metric">
															<span class="metric-title font-bold text-primary"
																>AI Solution</span
															>
															<span class="metric-value">{{ card.values[0] }}</span>
														</div>
														<div class="metric">
															<span class="metric-title text-primary">Breakthrough</span>
															<span class="metric-value">{{ card.values[1] }}</span>
														</div>
														<div class="metric">
															<span class="metric-title text-primary"
																>Transformative</span
															>
															<span class="metric-value">{{ card.values[2] }}</span>
														</div>
														<div class="metric">
															<span class="metric-title text-primary">Incremental</span>
															<span class="metric-value">{{ card.values[3] }}</span>
														</div>
													</div>
													<p-divider></p-divider>
												</mat-card-content>
												<mat-card-actions class="card-footer flex justify-content-between items-center" *ngIf="!card.baseLevel">
													<p-chip 
														class="cursor-pointer text-primary bg-primary-reverse border-round"
														(click)="$event.stopPropagation(); togglePopover($event, card)"
													>
														<div class="py-1" 
															tippy
															[tippyContent]="'Open AI Solution List'"
															tippyPlacement="bottom"
														>
															<i class="pi pi-sparkles mr-1"></i> AI Solutions
														</div>
													</p-chip>
													<span class="flex-grow-1"></span>
													<p-button
														*ngIf="card.diagram"
														label="Diagram"
														icon="pi pi-sitemap"
														class="diagram-button ml-auto"
														(click)="$event.stopPropagation(); aiExplorer.editDiagramTemplate($event, card)"
														tippy
														[tippyContent]="'Open Diagram'"
														tippyPlacement="bottom"
													>
													</p-button>
													<p-overlayPanel #popover>
														<div class="popover-content">
															<div class="p-2">
																<ng-container *ngFor="let type of ['Breakthrough', 'Transformative', 'Incremental']">
																	<p class="font-bold text-primary mb-1 text-sm">{{ type.toUpperCase() }}</p>
													
																	<ng-container *ngIf="getAiSolutionsByType(selectedCard, type).length > 0; else noResults">
																		<ul class="list-none p-0 m-0">
																			<li *ngFor="let solution of getAiSolutionsByType(selectedCard, type)" 
																				class="py-1 border-b cursor-pointer hover:bg-gray-300 pl-1 py-3 rounded"
																				(click)="openAiSolutionModal(solution.id)"
																				>
																				<span
																				tippy
																				[tippyContent]="'Open AI Solution'"
																				tippyPlacement="bottom"
																				>
																					{{ solution.name }}
																				</span>
																			</li>
																		</ul>
																	</ng-container>
													
																	<ng-template #noResults>
																		<p class="pl-1 font-bold">No results found</p>
																	</ng-template>
																</ng-container>
															</div>
														</div>
													</p-overlayPanel>							
												</mat-card-actions>								
											</mat-card>
										</div>
									</ng-template>
									<ng-template #noResults>
										<div class="col-12 text-center">
											<p class="text-left font-bold">No results found.</p>
										</div>
									</ng-template>
								</div>
							</ng-template>
						</p-scrollPanel>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog
    header="AI Solution Library"
    [(visible)]="aiSolutionItemModal"
    [modal]="true"
    [style]="{ width: '80vw' }"
    styleClass="diagram-sub-modal"
    (onHide)="closeAiSolutionModal()"
>
<app-ai-solution-item
	[aiSolution]="currentAISolution"
></app-ai-solution-item>
</p-dialog>