<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div class="w-full p-0">
	<p-accordion class="px-0">
		<p-accordionTab header="Simulated Gen AI Solutions" (onclick)="simulationService.accordionDashboardTableOpen = !simulationService.accordionDashboardTableOpen" [selected]="simulationService.accordionDashboardTableOpen" class="accordion-set-parameters">
			<div class="relative" *ngIf="hasValidUseCases()">
				<div class="absolute buttons-actions-main">
					<div class="flex justify-content-end gap-2 card p-0">
						<p-button
							*ngIf="dtSelection"
							icon="pi pi-trash"
							[label]="'Delete (' + dtSelection + ') selections'"
							styleClass="p-button-text"
							(onClick)="deleteUseCases()"
						></p-button>
					</div>
				</div>
			</div>
			<div class="col-12" style="position: relative">
				<p
					*ngIf="hasValidUseCases()"
					class="text-lg m-0 fw-bold"
					style="position: absolute; padding-bottom: 0 !important; top: 27px"
				>
					Simulated Gen AI Solutions
				</p>

				<div *ngIf="!hasValidUseCases() && simulationService.loaded" class="no-data-container" style="flex-direction: column">
					<svg class="pb-3" width="48" height="45" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M44.0844 4.51177L47.4496 5.63349C48.1836 5.87817 48.1836 6.91645 47.4496 7.16113L44.0844 8.28286C43.844 8.363 43.6553 8.55165 43.5752 8.79207L42.4535 12.1572C42.2088 12.8913 41.1705 12.8913 40.9258 12.1572L39.8041 8.79207C39.724 8.55165 39.5353 8.363 39.2949 8.28286L35.9297 7.16113C35.1957 6.91645 35.1957 5.87817 35.9297 5.63349L39.2949 4.51177C39.5353 4.43163 39.724 4.24297 39.8041 4.00255L40.9258 0.637386C41.1705 -0.096657 42.2088 -0.0966583 42.4535 0.637384L43.5752 4.00255C43.6553 4.24297 43.844 4.43163 44.0844 4.51177ZM18.0551 3.84082C18.7484 1.76101 21.6902 1.76105 22.3834 3.84082L26.5072 16.2122L38.8785 20.3359C40.9583 21.0292 40.9583 23.971 38.8785 24.6643L26.5072 28.788L22.3834 41.1594C21.6901 43.2392 18.7483 43.2391 18.0551 41.1594L13.9313 28.788L1.55996 24.6643C-0.519843 23.971 -0.519807 21.0292 1.55996 20.3359L13.9313 16.2122L18.0551 3.84082ZM20.2192 10.0787L17.4746 18.3127C17.2475 18.9938 16.713 19.5284 16.0318 19.7554L7.79785 22.5001L16.0318 25.2448C16.713 25.4718 17.2475 26.0063 17.4746 26.6875L20.2192 34.9215L22.9639 26.6875C23.191 26.0063 23.7255 25.4718 24.4067 25.2448L32.6406 22.5001L24.4067 19.7554C23.7255 19.5284 23.191 18.9939 22.9639 18.3127L20.2192 10.0787ZM47.4496 37.8391L44.0844 36.7173C43.844 36.6372 43.6553 36.4485 43.5752 36.2081L42.4535 32.843C42.2088 32.1089 41.1705 32.1089 40.9258 32.843L39.8041 36.2081C39.724 36.4485 39.5353 36.6372 39.2949 36.7173L35.9297 37.8391C35.1957 38.0837 35.1957 39.122 35.9297 39.3667L39.2949 40.4884C39.5353 40.5686 39.724 40.7572 39.8041 40.9976L40.9258 44.3628C41.1705 45.0969 42.2088 45.0969 42.4535 44.3628L43.5752 40.9976C43.6553 40.7572 43.844 40.5686 44.0844 40.4884L47.4496 39.3667C48.1836 39.122 48.1836 38.0837 47.4496 37.8391Z"
							fill="#1B54F8"
						/>
					</svg>
					<span class="font-bold text-lg pb-3">Ready to Simulate AI Opportunities</span>
					<span class="pb-3"
						>Run simulations to discover and analyze potential AI SOlutions across E2E, Process, and Subprocess
						level</span
					>
					<p-button
						label="Run Simulation"
						icon="pi pi-play-circle"
						(onClick)="onRunSimulationClick()"
						class="pb-3"
					></p-button>
				</div>

				<div *ngIf="hasValidUseCases()">
					<table #tableUseCases></table>
				</div>
			</div>
		</p-accordionTab>
	</p-accordion>
</div>

<p-dialog header="AI Solutions" [(visible)]="displayModal" [modal]="true" [style]="{ width: '80vw' }">
	<app-use-case-simulation-item
		*ngIf="currentUseCase"
		[useCase]="currentUseCase"
		[useCasesSaved]="true"
	></app-use-case-simulation-item>
</p-dialog>
