import { Injectable } from '@angular/core';
import { Client, createClient } from './graphql';
import { ClientOptions } from './graphql/runtime';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Assistant } from './services.data/assistant';
import { Organization } from '../api/common';

type ObjectType = 'use_case'
	| 'poc'
	| 'function'
	| 'level1'
	| 'level2'
	| 'diagram_e2e'
	| 'diagram_process'
	| 'diagram_template'
	| 'tool'
	| 'solution'
	| 'flow'
	| 'diagram'
	| 'category_question_maturity'
	| 'question_maturity'
	| 'sub_category_question_maturity'
	| 'assessment_maturity'
	| 'enterprise_context'
	| 'simulation'
	| 'use_case_simulation'
	| 'use_case_library'
	| 'm1_prep';

interface InputArgs {
	includeOrganization?: boolean;
	queryArgs?: Record<string, any>;
	sortKeys?: string[];
	fields?: InputFieldArg[];
}

export type InputFieldArg = (string | {
	child: string;
	sortKeys?: string[];
});

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	private _client: Client | undefined;
	private _organization: Organization | undefined;

	constructor(private authService: AuthService, private http: HttpClient) {
		this.authService.updateSource$.subscribe(() => {
			this._client = createClient(this._clientOptions);
			this.authService.getCurrentOrganization().then((organization) => {
				this._organization = organization;
			});
		});

		this.authService.getCurrentOrganization().then((organization) => {
			this._organization = organization;
		});
	}

	private get _clientOptions(): ClientOptions {
		return {
			url: environment.url + '/graphql',
			headers: localStorage.getItem('auth-token')
				? {
					Authorization: 'JWT ' + localStorage.getItem('auth-token'),
				}
				: {},
		};
	}

	get client(): Client {
		if (!this._client) {
			this._client = createClient(this._clientOptions);
		}
		return this._client;
	}

	async organizationArgs() {
		if (!this._organization) {
			this._organization = await this.authService.getCurrentOrganization();
		}
		return {
			__args: {
				organizationId: this._organization?.id || '',
			},
		};
	}

	async organizationTaxonomy() {
		if (!this._organization) {
			this._organization = await this.authService.getCurrentOrganization(true);
		}
		if (this._organization) {
			return this.queryArgs({ id__in: this._organization.taxonomy.value });
		} else {
			return {
				__args: {},
			};
		}
	}

	queryArgs(query: any) {
		return {
			__args: {
				query,
			},
		};
	}

	sortArgs(...sort: string[]) {
		return {
			__args: {
				sort,
			},
		};
	}

	paginationArgs(pagination: { page: number; size: number }) {
		return {
			__args: {
				pagination,
			},
		};
	}

	idArgs(id: string) {
		return this.queryArgs({ id });
	}

	mergeArgs<T>(...args: { __args: any }[]): T {
		return {
			__args: args.reduce(
				(acc, current) => ({
					...acc,
					...current.__args,
				}),
				{},
			),
		} as any;
	}

	private get_urls_event(key: ObjectType): string {
		const data = {
			use_case: 'use_case/use_case/',
			poc: 'use_case/poc/',
			function: 'e2e_taxonomy/function/',
			level1: 'e2e_taxonomy/level1/',
			level2: 'e2e_taxonomy/level2/',
			diagram_e2e: 'e2e_taxonomy/diagram_e2e/',
			diagram_process: 'e2e_taxonomy/diagram_process/',
			diagram_template: 'e2e_taxonomy/diagram/',
			tool: 'solution/tool/',
			solution: 'solution/solution/',
			flow: 'solution/flow/',
			diagram: 'use_case/diagram/',
			category_question_maturity: 'maturity/category_question/',
			question_maturity: 'maturity/question/',
			sub_category_question_maturity: 'maturity/sub_category_question/',
			assessment_maturity: 'maturity/assessment/',
			enterprise_context: 'simulation/context/',
			simulation: 'simulation/simulation/',
			use_case_simulation: 'simulation/use_case_simulation/',
			use_case_library: 'simulation/use_case_library/',
			m1_prep: 'simulation/m1_prep/',
		};
		return data[key];
	}

	async putObject<T extends { id: string }>(obj: T, type: ObjectType, organization: boolean = false) {
		if (this._organization && organization) {
			(obj as any).organizationId = this._organization.id;
		}
		if (!(obj as any).created_at) {
			(obj as any).created_at = new Date().getTime();
		}
		(obj as any).updated_at = new Date().getTime();
		return firstValueFrom(this.http.patch('@api/' + this.get_urls_event(type) + obj.id + '/', obj));
	}

	async removeObject<T extends { id: string }>(obj: T, type: ObjectType, organization: boolean = false) {
		if (this._organization && organization) {
			(obj as any).organizationId = this._organization.id;
		}
		if (!(obj as any).deleted_at) {
			(obj as any).deleted_at = new Date().getTime();
		}
		return firstValueFrom(this.http.patch('@api/' + this.get_urls_event(type) + obj.id + '/', obj));
	}

	async putObjects<T extends { id: string }>(objs: T[], type: ObjectType, organization: boolean = false) {
		if (this._organization && organization) {
			objs = objs.map((obj) => {
				(obj as any).organizationId = this._organization?.id || '';
				if (!(obj as any).created_at) {
					(obj as any).created_at = new Date().getTime();
				}
				(obj as any).updated_at = new Date().getTime();
				return obj;
			});
		}
		return Promise.all(
			objs.map((obj) => firstValueFrom(this.http.patch('@api/' + this.get_urls_event(type) + obj.id + '/', obj))),
		);
	}

	getAssistant<T>(assistant: string, content: string, callback: (data: T) => void, error: (error: any) => void) {
		const instance = new Assistant(this.http);
		instance.run(assistant, content, callback);
	}

	sortArrayObjects<T>(key: keyof T, data: T[]): T[] {
		return data.sort((a, b) => {
			const regex = /(\D*)(\d*\.?\d*)/;
			const matchA = (a[key] as string).match(regex);
			const matchB = (b[key] as string).match(regex);

			if (!matchA || !matchB) {
				return 0;
			}

			const textA = matchA[1].trim().toLowerCase();
			const textB = matchB[1].trim().toLowerCase();
			const numA = parseFloat(matchA[2]) || 0;
			const numB = parseFloat(matchB[2]) || 0;

			if (textA !== textB) {
				return textA.localeCompare(textB);
			}

			return numA - numB;
		});
	}

	filterOrganization<T extends { id: string }>(data: T[]): T[] {
		return data.filter((d) => {
			if (this._organization && this._organization.taxonomy?.value?.length && d.id) {
				return this._organization.taxonomy.value.includes(d.id);
			} else {
				return true;
			}
		});
	}

	prepareDataTaxonomy<T extends { id: string; name: string }>(data: T[]): T[] {
		return this.filterOrganization(this.sortArrayObjects('name', data));
	}

	async getQueryArgs({
						   includeOrganization = true,
						   sortKeys,
						   queryArgs,
						   fields,
					   }: InputArgs) {
		let processedFields = {};
		const args = [];

		if (includeOrganization) {
			const orgArgs = await this.organizationArgs();
			args.push(orgArgs);
		}

		if (sortKeys && sortKeys.length) {
			const sortArgs = this.sortArgs(...sortKeys);
			args.push(sortArgs);
		}

		if (queryArgs && Object.keys(queryArgs).length) {
			args.push(this.queryArgs(queryArgs));
		}

		if (fields && fields.length) {
			processedFields = this.processFields(fields);
		}

		return {
			...this.mergeArgs<any>(...args),
			...processedFields,
		};
	}

	findQueryResponse<T>(obj: Record<string, any>): T | undefined {
		for (const key in obj) {
			if (['fetch', 'all', 'get'].includes(key)) {
				return obj[key];
			} else if (typeof obj[key] === 'object' && obj[key] !== null) {
				const result = this.findQueryResponse(obj[key]);
				if (result !== undefined) return result as any;
			}
		}
		return undefined;
	}

	private processFields(fields: InputFieldArg[]): Record<string, any> {
		let result: Record<string, any> = {};

		const processField = (field: string, sortKeys?: string[]): void => {
			const keys = field.split('.');
			let current = result;

			keys.forEach((key, index) => {
				if (index === keys.length - 1) {
					if (sortKeys) {
						current[key] = {
							...(current[key] || {}),
							...this.sortArgs(...sortKeys),
						};
						return
					}

					current[key] = true;
				} else {
					current[key] = current[key] || {};
					current = current[key];
				}
			});
		};

		fields.forEach((field) => {
			if (typeof field === 'string') {
				processField(field);
			} else if (typeof field === 'object' && 'child' in field) {
				const { child: parent, sortKeys }: any = field;

				if (typeof parent === 'string' && sortKeys) {
					processField(parent, sortKeys);
				}
			}
		});

		return result;
	}
}
