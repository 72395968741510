import { UseCase } from '../../../api/use-case.api';
import PptxGenJS from 'pptxgenjs';
import { format } from 'date-fns';
import { Style } from './style';
import { Cursor, CursorOutput } from './cursor';
import { Context, DataUseCaseContent } from './types';
import { Content } from './content';
import TableRow = PptxGenJS.TableRow;
import HAlign = PptxGenJS.HAlign;
import VAlign = PptxGenJS.VAlign;
import { htmlToPptxText } from './htmlToText';

export class Report {
	public readonly pres: PptxGenJS;
	public style: Style;
	public cursor: Cursor;
	public content: Content;

	/**
	 * Constructor for initializing the class.
	 *
	 * @param {Context} context - The context object.
	 *
	 * @constructor
	 */
	constructor(public context: Context) {
		this.pres = new PptxGenJS();
		this.style = new Style();
		this.cursor = new Cursor(this.pres, { y: 0.3, x: 0.5 });
		this.content = new Content(this.cursor, this.style);
	}

	/**
	 * Creates a report document with multiple pages based on the given use cases and content.
	 *
	 * @returns {Promise} A promise that resolves when the report document is created.
	 */
	async create(): Promise<void> {
		const content = await this.content.getContent(this.context);

		await this.createIntroPage();
		await this.createProcessPrioritization();

		for (let i = 0; i < this.context.useCases.length; i++) {
			await this.createPageUseCase(this.context.useCases[i], content.useCases[i]);
		}

		await this.pres.writeFile({ fileName: 'report' });
	}

	public async createIntroPage(): Promise<void> {
		this.cursor.addBackground({ background: { path: '../../../../../assets/rtp/img/report-background.png' } });

		this.cursor.slide.addImage({
			path: '../../../../../assets/rtp/img/thg_cover-01.png',
			w: '100%',
			h: '61%',
		});

		this.cursor.slide.addImage({
			path: '../../../../../assets/rtp/img/report-rectround.png',
			y: 3,
			x: 0,
			w: 9.5,
			h: 0.75,
		});

		this.cursor.slide.addImage({
			path: '../../../../../assets/rtp/img/hackett-logo.png',
			y: 3.25,
			x: '6%',
			w: 2.708,
			h: 0.364,
		});

		this.cursor.slide.addImage({
			path: '../../../../../assets/rtp/img/iso_aiExplorer_color.png',
			y: 3.2,
			x: 8,
			w: 0.364,
			h: 0.364,
		});

		this.cursor.slide.addText('AI XPLR', {
			y: 3.21,
			x: 8.364,
			h: 0.364,
			w: 1,
			color: '#18263E',
			fontSize: 16,
			bold: true,
		});

		this.cursor.newPosition({ y: 4, x: '5.5%', h: 0.35, yS: 0.001, maxY: 5.63 }, (p, slide) => {
			slide.addText('AI XPLR: Intelligent Automation Report', {
				...p,
				...this.style.getText(true),
			});
		});

		this.cursor.newPosition({ x: '5.5%', h: 0.35, yS: 0.001, maxY: 5.63 }, (p, slide) => {
			slide.addText('Requested by: ' + this.context.user, {
				...p,
				...this.style.getText(true),
			});
		});

		this.cursor.newPosition({ x: '5.5%', h: 0.35, yS: 0.001, maxY: 5.63 }, (p, slide) => {
			slide.addText('Account: ' + this.context.account, {
				...p,
				...this.style.getText(true),
			});
		});

		this.cursor.newPosition({ x: '5.5%', h: 0.35, yS: 0.001, maxY: 5.63 }, (p, slide) => {
			slide.addText('Date: ' + format(new Date(), 'yyyy-MM-dd HH:mm'), {
				...p,
				...this.style.getText(true),
			});
		});
	}

	public async createProcessPrioritization(): Promise<void> {
		const image = this.content.captureCanvas('chartMain');

		const dataImage = { data: image, ...(await this.content.getImageDimensions(image)) };

		const colorText: { [key: string]: string } = {
			High: '#994d4d',
			Medium: '#99794d',
			Low: '#66994d',
			'---': '#000',
		};

		const tableLarge: TableRow[] = [
			[
				{ text: '#', options: this.style.getTitle('h6') },
				{ text: 'AI Solution', options: this.style.getTitle('h6') },
				{ text: 'Effort', options: { ...this.style.getTitle('h6'), align: 'center' as HAlign } },
				{ text: 'Impact', options: { ...this.style.getTitle('h6'), align: 'center' as HAlign } },
			],
		];

		this.context.useCases.forEach((u, rowIndex) => {
			tableLarge.push([
				{
					text: (rowIndex + 1).toString(),
					options: { ...this.style.getText(false, 9.5), valign: 'middle' as VAlign },
				},
				{ text: u.name, options: { ...this.style.getText(false, 9.5), valign: 'middle' as VAlign } },
				{
					text: u.calculatedData.matrixReadiness.label_value,
					options: {
						color: colorText[u.calculatedData.matrixReadiness.label_value.toUpperCase()],
						fontSize: 9.5,
						align: 'center' as HAlign,
						bold: true,
						fill: { color: u.calculatedData.matrixReadiness.color || '#ffffff' },
						valign: 'middle' as VAlign,
						border: {
							color: '#ffffff',
							pt: 6,
						},
					},
				},
				{
					text: u.calculatedData.matrixValue.label_value.toUpperCase(),
					options: {
						color: colorText[u.calculatedData.matrixValue.label_value.toUpperCase()],
						fontSize: 9.5,
						align: 'center' as HAlign,
						bold: true,
						fill: { color: u.calculatedData.matrixValue.color || '#ffffff' },
						valign: 'middle' as VAlign,
						border: {
							color: '#ffffff',
							pt: 4,
						},
					},
				},
			]);
		});

		const options: { h: number; rowH: number } = {
			h: 3.775,
			rowH: 0.35,
		};

		this.content.tableLarge(tableLarge, options).forEach((table) => {
			this.createProcessPrioritizationSlide(options, dataImage, table);
		});
	}

	public async createProcessPrioritizationSlide(
		options: { h: number; rowH: number },
		image: {
			data: string;
			h: number;
			w: number;
		},
		table: TableRow[],
	): Promise<void> {
		this.cursor.newSlide();

		this.content.title('Process Prioritization');

		this.cursor.toggleOrientation();

		this.cursor.setPosition({ y: this.cursor.getPosition().y + 0.1 });

		const { h: hImage, w: wImage } = image;

		let pBox: any;

		this.cursor.newPosition({ h: 2.37, w: 3.95 }, (p, slide) => {
			if (p.h && p.w) {
				p.h = (hImage * p.w) / wImage;
			}

			pBox = {
				w: (p.w || 10) + 0.3,
				h: options.h,
			};

			slide.addImage({
				path: '../../../../../assets/rtp/img/report-box.png',
				...p,
				...pBox,
				x: p.x - 0.15,
				y: p.y - 0.15,
			});
			slide.addImage({
				data: image.data,
				...p,
				y: p.y + (pBox.h / 2 - (p.h || 10) / 2),
			});
		});

		this.cursor.newPosition({}, (p, slide) => {
			if (this.cursor.getOrientation() === 'x') {
				this.cursor.toggleOrientation();
			}

			slide.addImage({
				path: '../../../../../assets/rtp/img/report-box.png',
				...p,
				...pBox,
				x: p.x - 0.05,
				y: p.y - 0.15,
				w: pBox.w + 0.35,
			});
			slide.addTable(table, {
				...p,
				x: p.x + 0.05,
				rowH: options.rowH,
				colW: [0.5, 2, 0.95, 0.95],
			});
		});
	}

	public async createPageUseCase(useCase: UseCase, content: DataUseCaseContent): Promise<void> {
		this.cursor.newSlide({ footer: false, background: { color: this.style.colors.dark } });

		let pTitle: CursorOutput;

		this.content.title(useCase.name, {
			inverted: true,
			afterAdded: (p, slide) => {
				pTitle = p;
			},
		});

		let firstTitle = true;

		this.content.textLarge(
			useCase.description,
			{
				w: '90%',
				initY: 1.5,
				maxY: 5.05,
				footerInverted: true,
			},
			{
				...this.style.getText(true, 12),
			},
			{ footer: false, background: { color: this.style.colors.dark } },
			{
				beforeAdded: (p, slide) => {
					if (firstTitle) {
						firstTitle = false;
					} else {
						this.content.title(useCase.name, { slide, p: pTitle, inverted: true });
					}

					slide.addShape('roundRect', {
						h: 3.75,
						w: (p.w || 9) + 0.6,
						x: p.x - 0.3,
						y: p.y - 0.2,
						rectRadius: 0.1,
						fill: {
							color: this.style.colors.primary,
						},
					});
				},
				afterAdded: (p, slide) => {
					this.cursor.addFooter(slide);
				},
			},
		);

		this.cursor.newSlide({ background: null });

		this.content.title('Diagram');

		const { h: hImage, w: wImage } = await this.content.getImageDimensions(useCase.capture_url);

		this.cursor.newPosition({ h: '90%', w: '80%', align: 'center' }, (p, slide) => {
			if (p.h && p.w) {
				p.x = p.x + p.w / 2;
				p.w = (wImage * p.h) / hImage;
				p.x = p.x - p.w / 2;
			}
			if (p.h && p.w && p.w > 9) {
				p.w = 9;
				p.x = 0.5;
				p.y = p.y + p.h / 2;
				p.h = (hImage * p.w) / wImage;
				p.y = p.y - p.h / 2;
			}
			slide.addImage({
				path: useCase.capture_url,
				...p,
			});
		});

		this.cursor.newSlide({ background: null });

		this.content.title('Assessments');

		const labels = ['Governance', 'Data', 'Talent'];
		const values: string[] = useCase.values.map((cd) => cd.value);

		let yInit = 0;

		this.cursor.setPosition({ x: 0.9 });

		for (let i = 0; i < labels.length; i++) {
			this.cursor.newPosition({ h: 0.25, w: 1.75 }, (p, slide) => {
				if (!yInit) {
					yInit = p.y - 0.15;
				}
				slide.addText(labels[i], {
					...p,
					...this.style.getTextPrimary(),
					align: 'center',
				});
			});
			this.content.chartCustom({ h: 1, w: 1.25, yS: 0.05, xS: 0.4 }, parseFloat(values[i]));
			this.cursor.newPosition({ h: 0.25, w: 1.75, yS: -0.6 }, (p, slide) => {
				slide.addText(parseInt(values[i]) + '%', {
					...p,
					...this.style.getTextSecondary(12),
					align: 'center',
				});
			});
		}

		this.cursor.setPosition({ y: yInit });

		const xBD = 4;

		this.cursor.newPosition({ h: 0.25, w: 4.5, x: xBD }, (p, slide) => {
			slide.addText('Business Value Drivers', {
				...p,
				...this.style.getTextPrimary(),
			});
		});

		yInit = 0;
		const hTable = 3;

		const sImage = (hTable / content.business_drivers.length) * 0.6;

		const colorBackground = {
			High: '#5152a3',
			Medium: '#7e83d1',
			Low: '#b3b9ee',
		};

		this.cursor.newPosition({ h: hTable, w: 4.5, x: xBD }, (p, slide) => {
			if (!yInit) {
				yInit = p.y;
			}
			slide.addTable(
				[
					...content.business_drivers.map(
						(row): TableRow => [
							{ text: '' },
							{
								text: row.tag,
								options: { ...this.style.getText(false, 12), valign: 'middle' as VAlign },
							},
							{
								text: row.value,
								options: {
									...this.style.getText(row.value !== '---', 12),
									align: 'center',
									valign: 'middle' as VAlign,
									fill: {
										color: colorBackground[row.value as 'High' | 'Medium' | 'Low'] || '#f9fafc',
									},
									border: {
										color: '#ffffff',
										pt: 6,
									},
								},
							},
						],
					),
				],
				{
					...p,
					rowH: 0.45,
					colW: [1, 2.5, 1],
				},
			);
		});

		this.cursor.setPosition({ y: yInit - 0.12 });

		content.business_drivers.forEach((d) => {
			this.cursor.newPosition(
				{
					h: sImage,
					w: sImage,
					x: xBD + 0.2,
					yS: 0.155,
				},
				(p, slide) => {
					slide.addImage({
						data: d.svg,
						...p,
					});
				},
			);
		});

		if (useCase.aiValues?.score) {
			if (useCase.aiValues?.score) {
				this.cursor.newSlide({ background: null });

				this.content.title('AI Analysis');

				this.cursor.newPosition({ h: 1, yS: 0.05, w: '90%', y: 1 }, (p, slide) => {
					slide.addText(useCase.aiValues.description, {
						...p,
						...this.style.getText(false, 12),
					});
				});

				const initPos = this.cursor.getPosition();

				initPos.y = initPos.y + 0.15;

				this.content.progressBar(
					{ w: 2, h: 0.25, y: initPos.y },
					'Effort',
					useCase.aiValues.effort_score,
					this.style.getText(false, 12),
				);

				this.content.progressBar(
					{ w: 2, h: 0.25, y: initPos.y, x: initPos.x + 4.25 },
					'Impact',
					useCase.aiValues.impact_score,
					this.style.getText(false, 12),
				);

				initPos.y = initPos.y + 1;

				this.content.progressBar(
					{ w: 2, h: 0.25, y: initPos.y },
					'AI Enablers',
					useCase.aiValues.technologies_score,
					this.style.getText(false, 12),
				);

				this.content.progressBar(
					{ w: 2, h: 0.25, y: initPos.y, x: initPos.x + 4.25 },
					'Solutions',
					useCase.aiValues.solutions_score,
					this.style.getText(false, 12),
				);
			}
		}
	}
}
