import { Injectable } from '@angular/core';
import { DataUpdateDiagram } from './diagram.types';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DiagramComponent } from '../components/diagram/diagram.component';

/**
 * Service class for interacting with the E2E Taxonomy data.
 */
@Injectable({
	providedIn: 'root',
})
export class DiagramService {
	constructor(public dialogService: DialogService) {}

	hasModalDiagram: boolean = false;

	openDiagramModal(data: DataUpdateDiagram): DynamicDialogRef | undefined {
		if (!this.hasModalDiagram) {
			const preventClick = (e: any) => {
				e.stopPropagation();
				e.preventDefault();
			};

			document.body.style.cursor = 'wait';
			document.body.addEventListener('click', preventClick, true);

			this.hasModalDiagram = true;
			const ref: DynamicDialogRef = this.dialogService.open(DiagramComponent, {
				data,
				header: 'Diagram',
				width: '100%',
				height: '100%',
				styleClass: 'diagram-modal',
				contentStyle: { overflow: 'auto' },
				showHeader: false,
				baseZIndex: 100,
				maximizable: false,
				closeOnEscape: false,
			});
			setTimeout(() => {
				document.body.style.cursor = 'default';
			}, 150);
			ref.onClose.subscribe(() => {
				this.hasModalDiagram = false;
			});
			ref.onDestroy.subscribe(() => {
				this.hasModalDiagram = false;
			});

			setTimeout(() => {
				document.body.style.cursor = 'default';
				document.body.removeEventListener('click', preventClick, true);
			}, 150);

			return ref;
		}
		return undefined;
	}
}
