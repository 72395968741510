import { Component, ViewChild, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UseCaseService } from '../../../../service/use-case.service';
import { UseCaseReadinessModalService } from '../use-case-readiness-modal.service';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';

@Component({
	selector: 'app-use-cases-readiness',
	templateUrl: './use-case-readiness.component.html',
	styleUrls: ['./use-case-readiness.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class UseCaseReadinessComponent implements OnInit {
	@ViewChild('stepper') stepper: MatStepper;

	constructor(
		public service: UseCaseReadinessModalService,
		public useCaseService: UseCaseService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig<{ useCaseId: string; current_index?: number }>,
	) {}

	async ngOnInit() {
		await this.service.onLoad(this.ref, this.config, (message) => {
			this.messageService.add(message);
		});
		const findStepper = () =>
			setTimeout(() => {
				if (this.stepper) {
					this.service.stepper = this.stepper;
				} else {
					findStepper();
				}
			}, 100);

		findStepper();
	}

	modalClose() {
		if (this.service.updatedUseCase && this.service.can_edit) {
			this.confirmationService.confirm({
				header: 'Your AI Solution has changed',
				message: 'Do you want to save the AI Solution before closing?',
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: 'Save',
				acceptIcon: 'pi pi-save',
				accept: () => {
					this.service.saveUseCase().then(() => {
						this.ref.close();
					});
				},
				rejectLabel: 'Close',
				reject: (type: ConfirmEventType) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.ref.close();
							break;
						case ConfirmEventType.CANCEL:
							break;
					}
				},
			});
		} else {
			this.ref.close();
		}
	}
}
