import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
	selector: 'app-image',
	template: `
		<div *ngIf="load" class="text-center my-2 loading-spinner" (click)="onClick.emit($event)">
			<i class="pi pi-spin pi-spinner" style="font-size: 2rem; color: #c8c8fb"></i>
		</div>

		<img [src]="_src" (load)="_onLoad($event)" (error)="onError.emit($event)" class="hidden" />

		<img *ngIf="!load" [src]="_src" (click)="onClick.emit($event)" [class]="styleClass" />
	`,
	styles: [
		`
			.loading-spinner {
				// padding-top: 25% !important;
			}
		`,
	],
})
export class ImageComponent {
	load = true;

	@Input() src: string | undefined;
	@Input() styleClass: string;

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onClick = new EventEmitter<Event>();

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onLoad = new EventEmitter<Event>();

	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onError = new EventEmitter<Event>();

	constructor(public el: ElementRef) {}

	_onLoad(evt: Event): void {
		this.load = false;
		this.onLoad.emit(evt);
	}

	get _src() {
		return this.src || 'assets/rtp/img/thumb_emptyDiagram.svg';
	}
}
