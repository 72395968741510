import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { SimulationService } from '../simulation.service';
import { TreeNode } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

@Component({
	selector: 'app-filter-context',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
	@Input() isOpen: boolean = false;
	@Output() selectedFilterCountChange = new EventEmitter<number>();

	constructor(public simulationService: SimulationService) {
		this.simulationService.counterGenAiObservable.subscribe(() => {});
	}

	selectedTaxonomy: string | undefined = undefined;

	async updateSelectedFilters() {
		this.simulationService.loadSimulations(undefined, [], [this.selectedTaxonomy as string]);
	}

	async removeFilter() {
		this.selectedTaxonomy = '';

		await this.updateSelectedFilters();
	}
}
