import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CountUp } from 'countup.js';
import { SimulationService } from '../simulation.service';

@Component({
	selector: 'app-taxonomy-context',
	templateUrl: './taxonomy.component.html',
	styleUrls: ['./taxonomy.component.scss'],
})
export class TaxonomyComponent implements OnInit {
	@ViewChild('processCountUp', { static: true }) processElement!: ElementRef;
	@ViewChild('subProcessCountUp', { static: true }) subProcessElement!: ElementRef;
	@ViewChild('useCasesCountUp', { static: true }) useCasesElement!: ElementRef;
	@ViewChild('breakthroughCountUp', { static: true }) breakthroughElement!: ElementRef;
	@ViewChild('transformativeCountUp', { static: true }) transformativeElement!: ElementRef;
	@ViewChild('incrementalCountUp', { static: true }) incrementalElement!: ElementRef;
	@ViewChild('aiOpportunityAgentCountUp', { static: true }) aiOpportunityAgentElement!: ElementRef;
	@ViewChild('aiOpportunitiesOptionalCountUp', { static: true }) aiOpportunitiesOptionalElement!: ElementRef;
	@ViewChild('aiOpportunitiesEssentialCountUp', { static: true }) aiOpportunitiesEssentialElement!: ElementRef;
	@ViewChild('stepsCountUp', { static: true }) stepsElement!: ElementRef;
	@ViewChild('aiOpportunitiesCountUp', { static: true }) aiOpportunitiesElement!: ElementRef;
	@ViewChild('e2eSourceRelCountUp', { static: true }) e2eSourceRelElement!: ElementRef;

	constructor(public simulationService: SimulationService) {}

	ngOnInit() {
		this.simulationService.counterGenAiObservable.subscribe(() => {
			this.startCountUps();
		});
	
		if (this.simulationService.loaded) {
			this.startCountUps();
		}
	}

	startCountUps() {
		const options = {
			startVal: 0,
			duration: 2,
			useGrouping: true,
			separator: ',',
		};

		if (this.e2eSourceRelElement) {
			const countUp = new CountUp(
				this.e2eSourceRelElement.nativeElement,
				this.simulationService.counters.e2e,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.processElement) {
			const countUp = new CountUp(
				this.processElement.nativeElement,
				this.simulationService.counters.process,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.subProcessElement) {
			const countUp = new CountUp(
				this.subProcessElement.nativeElement,
				this.simulationService.counters.subProcess,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.useCasesElement) {
			const countUp = new CountUp(
				this.useCasesElement.nativeElement,
				this.simulationService.counters.useCases,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.breakthroughElement) {
			const countUp = new CountUp(
				this.breakthroughElement.nativeElement,
				this.simulationService.counters.breakthrough,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.transformativeElement) {
			const countUp = new CountUp(
				this.transformativeElement.nativeElement,
				this.simulationService.counters.transformative,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.incrementalElement) {
			const countUp = new CountUp(
				this.incrementalElement.nativeElement,
				this.simulationService.counters.incremental,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.aiOpportunityAgentElement) {
			const countUp = new CountUp(
				this.aiOpportunityAgentElement.nativeElement,
				this.simulationService.counters.aiOpportunityAgent,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.aiOpportunitiesOptionalElement) {
			const countUp = new CountUp(
				this.aiOpportunitiesOptionalElement.nativeElement,
				this.simulationService.counters.aiOpportunitiesOptional,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.aiOpportunitiesEssentialElement) {
			const countUp = new CountUp(
				this.aiOpportunitiesEssentialElement.nativeElement,
				this.simulationService.counters.aiOpportunitiesEssential,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.stepsElement) {
			const countUp = new CountUp(
				this.stepsElement.nativeElement,
				this.simulationService.counters.steps,
				options
			);
			if (!countUp.error) countUp.start();
		}

		if (this.aiOpportunitiesElement) {
			const countUp = new CountUp(
				this.aiOpportunitiesElement.nativeElement,
				this.simulationService.counters.aiOpportunities,
				options
			);
			if (!countUp.error) countUp.start();
		}
	}
}
