import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, ConfirmEventType, MessageService, TreeNode } from 'primeng/api';
import { AuthService } from '../../service/auth.service';
import { E2ETaxonomyService } from '../../service/e2e-taxonomy.service';
import { SolutionService } from '../../service/solution.service';
import { FunctionFilter, Solution } from '../../api/solution.api';
import { Technology } from '../../api/e2e-taxonomy.api';
import { DbService } from '../../service/db.service';
import { UseCaseLibrary } from '../../api/simulation.api';
import { CategoryFunctionModelObjectType } from '../../service/graphql';
import { ApiService } from '../../service/api.service';

export interface QuerySolutions {
	functionsSelection: string[];
	technologiesSelection: string[];
	aiLeversSelection: string[];
	toolsSelection: string[];
	benefitsSelection: string[];
	effortToImplementSelection: string[];
	timeToImplementSelection: string[];
	technologySelection: string[];
}

@Injectable({
	providedIn: 'root',
})
export class UseCasesService {
	public confirmationService: ConfirmationService;
	public messageService: MessageService;

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		public authService: AuthService,
		public e2ETaxonomyService: E2ETaxonomyService,
		public solutionService: SolutionService,
		public dbService: DbService,
		public apiService: ApiService,
	) {
		this.router.events.subscribe((evt) => {
			if ((evt as any)['url'] && !((evt as any)['url'] as string).startsWith('/ai-solutions')) {
				this.searchText = '';
				this.functionsTreeSelection = [];
				this.technologiesTreeSelection = [];
				this.aiLeversTreeSelection = [];
				if (this.baseSolutions) {
					this.solutions = [...this.baseSolutions];
				}
			}
		});

		this.solutionService.updateCurrentSolution$.subscribe(async () => {
			this.loading = true;

			if (this.solutionService.currentSolution) {
				this.baseSolutions = this.baseSolutions.map((d) => {
					if (this.solutionService.currentSolution && d.id === this.solutionService.currentSolution.id) {
						return this.solutionService.currentSolution;
					} else {
						return d;
					}
				});

				this.solutions = this.solutions.map((d) => {
					if (this.solutionService.currentSolution && d.id === this.solutionService.currentSolution.id) {
						return this.solutionService.currentSolution;
					} else {
						return d;
					}
				});
			}
			this.loading = false;
		});
	}

	searchText: string = '';

	functionsTree: TreeNode[] = [];
	functionsAllTree: TreeNode[] = [];
	technologiesTree: TreeNode[] = [];
	aiLeversTree: TreeNode[] = [];
	toolsTree: TreeNode[] = [];
	benefitsTree: TreeNode[] = [];
	effortToImplementTree: TreeNode[] = [];
	timeToImplementTree: TreeNode[] = [];
	technologyTree: TreeNode[] = [];

	functionsTreeSelection: TreeNode[] = [];
	technologiesTreeSelection: TreeNode[] = [];
	aiLeversTreeSelection: TreeNode[] = [];
	toolsTreeSelection: TreeNode[] = [];
	benefitsTreeSelection: TreeNode[] = [];
	effortToImplementTreeSelection: TreeNode[] = [];
	timeToImplementTreeSelection: TreeNode[] = [];
	technologyTreeSelection: TreeNode[] = [];

	functionsDropdown: FunctionFilter[];
	technologiesDropdown: Technology[];

	baseSolutions!: Solution[];
	solutions!: Solution[];

	init: boolean = false;
	loading: boolean = true;

	async prepareData() {
		this.init = true;
		await this.getData();

		this.authService.organizationSource$.subscribe(() => {
			setTimeout(() => {
				this.getData();
			}, 150);
		});

		this.dbService.dbBaseUpdateSource$.subscribe((id) => {
			setTimeout(() => {
				this.getData();
			}, 150);
		});
	}

	aiDriversBenefits: string[] = ['Revenue', 'Efficiency', 'Effectiveness', 'Experience'];
	aiDriversEffortToImplement: string[] = ['Extensive (5+ FTEs)', 'Moderate (5 FTEs)', 'Easy (1 FTE)'];
	aiDriversTimeToImplement: string[] = ['Extensive (Quarters)', 'Moderate (Months)', 'Short (Weeks)'];
	aiDriversTechnology: string[] = ['Native (custom)', 'Embedded (in platform)', 'Domain (LLMs)'];

	async getData() {
		this.solutionService.clearCurrentSolution();

		await this.getFunctions();
		this.technologiesTree = await this.e2ETaxonomyService.getTechnologies({}, true);
		this.aiLeversTree = (await this.solutionService.getAiLeverAsTreeNode()).sort((a, b) => {
			if (a.label === undefined) {
				return 1;
			}
			if (b.label === undefined) {
				return -1;
			}
			if (a.label! < b.label!) {
				return -1;
			} else if (a.label! > b.label!) {
				return 1;
			}
			return 0;
		});
		this.toolsTree = (await this.solutionService.getToolAsTreeNode()).sort((a, b) => {
			if (a.label === undefined) {
				return 1;
			}
			if (b.label === undefined) {
				return -1;
			}
			if (a.label! < b.label!) {
				return -1;
			} else if (a.label! > b.label!) {
				return 1;
			}
			return 0;
		});

		this.benefitsTree = this.aiDriversBenefits.map((d, i) => ({
			label: d,
			key: i.toString(),
		}));

		this.effortToImplementTree = this.aiDriversEffortToImplement.map((d, i) => ({
			label: d,
			key: i.toString(),
		}));

		this.timeToImplementTree = this.aiDriversTimeToImplement.map((d, i) => ({
			label: d,
			key: i.toString(),
		}));

		this.technologyTree = this.aiDriversTechnology.map((d, i) => ({
			label: d,
			key: i.toString(),
		}));

		this.baseSolutions = await this.solutionService.getSolutions();
		this.solutions = [...this.baseSolutions];

		this.functionsDropdown = await this.solutionService.getFunctions();
		this.technologiesDropdown = await this.e2ETaxonomyService.getTechnologies();

		this.loading = false;
	}

	treeNodeSelected: boolean = false;

	async getFunctions() {
		await this.apiService.client
			.query({
				categoryFunction: {
					fetch: {
						id: true,
						name: true,
						taxonomy: {
							id: true,
							industry: {
								id: true,
							},
						},
					},
				},
			})
			.then((res) => {
				const data = this.apiService.prepareDataTaxonomy(
					(res.categoryFunction?.fetch || []) as CategoryFunctionModelObjectType[],
				);

				this.functionsAllTree = data.map((cf) => ({
					key: cf.id,
					label: cf.name,
					data: {
						industry: cf.taxonomy?.industry?.id || '',
					},
				}));

				this.functionsTree = this.functionsAllTree
					.filter((cf) => !cf.data?.industry)
					.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
			});
	}

	updateFunctionsTree(industriesTree: (string | TreeNode)[]) {
		const industries = industriesTree.map((d) => (typeof d === 'string' ? d : (d.key as string)));
		if (industries.length) {
			if (industries.includes('baseline')) {
				this.functionsTree = this.functionsAllTree
					.filter((cf) => industries.includes(cf.data?.industry) || !cf.data?.industry)
					.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
			} else {
				this.functionsTree = this.functionsAllTree
					.filter((cf) => industries.includes(cf.data?.industry))
					.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
			}
		} else {
			this.functionsTree = this.functionsAllTree
				.filter((cf) => !cf.data?.industry)
				.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
		}
	}

	async changeSelection(query?: QuerySolutions) {
		if (!query) {
			query = {
				functionsSelection: [],
				technologiesSelection: [],
				aiLeversSelection: [],
				toolsSelection: [],
				benefitsSelection: [],
				effortToImplementSelection: [],
				timeToImplementSelection: [],
				technologySelection: [],
			};
		}
		if (!query.functionsSelection || !query.functionsSelection.length) {
			query.functionsSelection = this.functionsTreeSelection.map((d) => d.key as string);
		} else if (query && query.functionsSelection) {
			this.functionsTreeSelection = this.functionsTree.filter(
				(d) => query && d.key && query.functionsSelection.includes(d.key),
			);
		}
		if (!query.technologiesSelection || !query.technologiesSelection.length) {
			query.technologiesSelection = this.technologiesTreeSelection.map((d) => d.key as string);
		} else if (query && query.technologiesSelection) {
			this.technologiesTreeSelection = this.technologiesTree.filter(
				(d) => query && d.key && query.technologiesSelection.includes(d.key),
			);
		}
		if (!query.aiLeversSelection || !query.aiLeversSelection.length) {
			query.aiLeversSelection = this.aiLeversTreeSelection.map((d) => d.key as string);
		} else if (query && query.aiLeversSelection) {
			this.aiLeversTreeSelection = this.aiLeversTree.filter(
				(d) => query && d.key && query.aiLeversSelection.includes(d.key),
			);
		}
		if (!query.toolsSelection || !query.toolsSelection.length) {
			query.toolsSelection = this.toolsTreeSelection.map((d) => d.key as string);
		} else if (query && query.toolsSelection) {
			this.toolsTreeSelection = this.toolsTree.filter(
				(d) => query && d.key && query.toolsSelection.includes(d.key),
			);
		}
		if (!query.benefitsSelection || !query.benefitsSelection.length) {
			query.benefitsSelection = this.benefitsTreeSelection.map((d) => d.key as string);
		} else if (query && query.benefitsSelection) {
			this.benefitsTreeSelection = this.benefitsTree.filter(
				(d) => query && d.key && query.benefitsSelection.includes(d.key),
			);
		}
		if (!query.effortToImplementSelection || !query.effortToImplementSelection.length) {
			query.effortToImplementSelection = this.effortToImplementTreeSelection.map((d) => d.key as string);
		} else if (query && query.effortToImplementSelection) {
			this.effortToImplementTreeSelection = this.effortToImplementTree.filter(
				(d) => query && d.key && query.effortToImplementSelection.includes(d.key),
			);
		}
		if (!query.timeToImplementSelection || !query.timeToImplementSelection.length) {
			query.timeToImplementSelection = this.timeToImplementTreeSelection.map((d) => d.key as string);
		} else if (query && query.timeToImplementSelection) {
			this.timeToImplementTreeSelection = this.timeToImplementTree.filter(
				(d) => query && d.key && query.timeToImplementSelection.includes(d.key),
			);
		}
		if (!query.technologySelection || !query.technologySelection.length) {
			query.technologySelection = this.technologyTreeSelection.map((d) => d.key as string);
		} else if (query && query.technologySelection) {
			this.technologyTreeSelection = this.technologyTree.filter(
				(d) => query && d.key && query.technologySelection.includes(d.key),
			);
		}
		this.treeNodeSelected =
			!!query.functionsSelection.length ||
			!!query.technologiesSelection.length ||
			!!query.aiLeversSelection.length ||
			!!query.toolsSelection.length ||
			!!query.benefitsSelection.length ||
			!!query.effortToImplementSelection.length ||
			!!query.timeToImplementSelection.length ||
			!!query.technologySelection.length;
		if (this.treeNodeSelected) {
			this.searchText = '';
			this.solutions = this.baseSolutions.filter((s) => {
				let valid =
					(!!query &&
						!!query.functionsSelection.filter(
							(f) => !!(s.levels2 || []).filter((l2) => l2.level1.function.categoryId === f).length,
						).length) ||
					!query ||
					!query.functionsSelection.length;
				if (valid && query && query.technologiesSelection.length) {
					valid = !!query.technologiesSelection.filter((t) => s.getTechnologiesIds().includes(t)).length;
				}
				if (valid && query && query.aiLeversSelection.length) {
					valid = !!query.aiLeversSelection.filter((t) => s.aiLeversIds.includes(t)).length;
				}
				if (valid && query && query.toolsSelection.length) {
					valid = !!query.toolsSelection.filter((t) => s.toolsIds.includes(t)).length;
				}
				if (valid && query && query.benefitsSelection.length) {
					valid = !!query.benefitsSelection
						.map((t) => parseInt(t))
						.filter((t) => ((s.aiDrivers.benefits || []) as string[]).includes(this.aiDriversBenefits[t]))
						.length;
				}
				if (valid && query && query.effortToImplementSelection.length) {
					valid = !!query.effortToImplementSelection
						.map((t) => parseInt(t))
						.filter((t) =>
							((s.aiDrivers.effortToImplement || []) as string[]).includes(
								this.aiDriversEffortToImplement[t],
							),
						).length;
				}
				if (valid && query && query.timeToImplementSelection.length) {
					valid = !!query.timeToImplementSelection
						.map((t) => parseInt(t))
						.filter((t) =>
							((s.aiDrivers.timeToImplement || []) as string[]).includes(
								this.aiDriversTimeToImplement[t],
							),
						).length;
				}
				if (valid && query && query.technologySelection.length) {
					valid = !!query.technologySelection
						.map((t) => parseInt(t))
						.filter((t) =>
							((s.aiDrivers.technology || []) as string[]).includes(this.aiDriversTechnology[t]),
						).length;
				}
				return valid;
			});
		} else {
			this.solutions = [...this.baseSolutions];
		}
		await this.router.navigate(['/ai-solutions'], {
			relativeTo: this.route,
			queryParams: { ...query },
		});
	}

	async clearSelectionTreeNode() {
		if (this.treeNodeSelected && !this.loading) {
			this.functionsTreeSelection = [];
			this.technologiesTreeSelection = [];
			this.aiLeversTreeSelection = [];
			await this.changeSelection();
		}
	}

	async changeSearchText() {
		this.functionsTreeSelection = [];
		this.technologiesTreeSelection = [];
		this.aiLeversTreeSelection = [];
		this.toolsTreeSelection = [];
		await this.changeSelection();
		if (this.searchText) {
			const search = new RegExp(this.searchText, 'i');
			const solutions = [];
			for (const s of this.baseSolutions) {
				if (
					s.name.toLowerCase().search(search) >= 0 ||
					s.description.toLowerCase().search(search) >= 0 ||
					s.summary.toLowerCase().search(search) >= 0
				) {
					solutions.push(s);
				}
			}
			this.solutions = [...solutions];
		} else {
			this.solutions = [...this.baseSolutions];
		}
	}

	useCaseLibraryItemModal: boolean = false;
	currentUseCaseLibrary: UseCaseLibrary | undefined = undefined;

	async goToDetail(useCase: UseCaseLibrary) {
		this.currentUseCaseLibrary = useCase;
		this.useCaseLibraryItemModal = true;
	}

	async addSolutionDialog() {
		await this.router.navigate(['/simulation/ai-solutions/detail']);
	}

	deleteSolutionModal(id?: string) {
		if (id) {
			this.confirmationService.confirm({
				message: 'Do you want to delete this Solution?',
				header: 'Delete Confirmation',
				icon: 'pi pi-info-circle',
				accept: () => {
					this.solutions = [];
					this.solutionService.deleteSolution(id).then(async () => {
						this.baseSolutions = await this.solutionService.getSolutions(false);
						this.solutions = [...this.baseSolutions];
						this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Solution deleted' });
					});
				},
				reject: (type: ConfirmEventType) => {
					switch (type) {
						case ConfirmEventType.REJECT:
							this.messageService.add({
								severity: 'error',
								summary: 'Rejected',
								detail: 'You have rejected',
							});
							break;
						case ConfirmEventType.CANCEL:
							this.messageService.add({
								severity: 'warn',
								summary: 'Cancelled',
								detail: 'You have cancelled',
							});
							break;
					}
				},
			});
		}
	}
}
