import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { LayoutService } from '../../../../layout/service/app.layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { SimulationService } from '../simulation.service';
import { IUseCaseSimulation, Simulation, UseCaseSimulation } from '../../../api/simulation.api';
import type { MediumEditor } from 'medium-editor';
import { plainToInstance } from 'class-transformer';
import { UseCaseService } from '../../../service/use-case.service';
import { AuthService } from '../../../service/auth.service';

@Component({
	templateUrl: './simulation-item.component.html',
	styleUrls: ['./simulation-item.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class SimulationItemComponent implements OnInit {
	constructor(
		private layoutService: LayoutService,
		private router: Router,
		private route: ActivatedRoute,
		private http: HttpClient,
		public simulationService: SimulationService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public useCaseService: UseCaseService,
		public authService: AuthService,
	) {}

	public sub: any;

	public simulationId: string = '';
	public organizationId: string = '';
	public simulation: Simulation | undefined = undefined;

	async ngOnInit() {
		this.layoutService.updateTitle('Simulation');

		this.sub = this.route.params.subscribe((params) => {
			this.simulationId = params['id'];
			this.setCurrentSimulation();
		});
	}

	goToSimulationList() {
		this.router.navigateByUrl('/simulation');
	}

	filterIndex: number = 0;

	changeFilter(i: number) {
		if (i === this.filterIndex) {
			this.filterIndex = 0;
		} else {
			this.filterIndex = i;
		}
	}

	aiEnablersTreeSelections: TreeNode[] = [];
	businessValueDriversTreeSelections: TreeNode[] = [];
	technologyTreeSelections: TreeNode[] = [];
	scopeTreeSelections: TreeNode[] = [];

	showParameters: boolean = true;

	toggleParameters() {
		this.showParameters = !this.showParameters;
	}

	showUseCases: boolean = true;

	toggleUseCases() {
		this.showUseCases = !this.showUseCases;
	}

	hasSimulation: boolean = false;

	currentUseCaseIndex: number = 0;
	currentUseCase: UseCaseSimulation | undefined = undefined;

	selectUseCase(i: number) {
		if (this.simulation) {
			this.currentUseCaseIndex = i;
			this.currentUseCase = this.simulation.use_cases[i];
		}
	}

	loaded: boolean = false;

	impact: {
		breakthrough: boolean;
		transformative: boolean;
		incremental: boolean;
	} = {
		breakthrough: false,
		transformative: false,
		incremental: false,
	};

	async setCurrentSimulation() {
		this.simulation = await this.simulationService.getSimulation(this.simulationId);
		if (this.simulation) {
			this.content = [
				this.simulationService.safeHTML(this.simulation.description || '<span></span>'),
				this.simulationService.safeHTML(this.simulation.notes || '<span></span>'),
			];

			this.aiEnablersTreeSelections = this.simulationService.aiEnablersTreeOptions.filter((t) =>
				(this.simulation?.simulationContext.aiEnablers || []).includes(t.key as string),
			);

			this.businessValueDriversTreeSelections = this.simulationService.businessValueDriversTreeOptions.filter(
				(b) => (this.simulation?.simulationContext.business_values || []).includes(b.key as string),
			);

			this.technologyTreeSelections = this.simulationService.technologyTreeOptions.filter((t) =>
				(this.simulation?.simulationContext.technologies || []).includes(t.key as string),
			);

			this.scopeTreeSelections = this.simulationService.scopeTreeOptions.filter(
				(s) => (this.simulation?.taxonomyRel || '3') === (s.key as string),
			);

			this.impact.breakthrough = !!this.simulation.simulationContext.impact.breakthrough;
			this.impact.transformative = !!this.simulation.simulationContext.impact.transformative;
			this.impact.incremental = !!this.simulation.simulationContext.impact.incremental;

			if (this.simulation.use_cases.length) {
				this.currentUseCase = this.simulation.use_cases[0];
				this.hasSimulation = true;
			}
			this.organizationId = this.simulation.organizationId;
		}
		this.loaded = true;
	}

	editor: MediumEditor | undefined = undefined;
	backupText: string = '';
	content: SafeHtml[] = [];
	contentEditable: boolean[] = [false, false];

	editContentEditable(i: number) {
		if (this.simulation) {
			this.backupText = i ? this.simulation.notes : this.simulation.description;
			this.contentEditable[i] = true;
			this.editor = new MediumEditor('#content-' + i, {
				extensions: {
					autolist: new ((window as any).AutoList as any)(),
				},
				toolbar: {
					buttons: ['bold', 'italic', 'underline', 'h3', 'h4', 'h5', 'unorderedlist', 'orderedlist'],
				},
			});
		}
	}

	closeContentEditable(i: number) {
		if (this.simulation) {
			this.contentEditable[i] = false;
			//this.editor.stop(true);
			if (this.editor) {
				this.editor.destroy();
				this.editor = undefined;
			}
			if (i) {
				this.simulation.notes = this.backupText;
			} else {
				this.simulation.description = this.backupText;
			}
			this.content[i] = this.simulationService.safeHTML(
				(i ? this.simulation.notes : this.simulation.description) || '<span></span>',
			);
		}
	}

	async saveContentEditable(i: number) {
		if (this.simulation) {
			this.contentEditable[i] = false;
			if (this.editor) {
				if (i) {
					this.simulation.notes = this.editor.getContent();
				} else {
					this.simulation.description = this.editor.getContent();
				}
				this.editor.destroy();
				this.editor = undefined;
			}
			this.content[i] = this.simulationService.safeHTML(
				(i ? this.simulation.notes : this.simulation.description) || '<span></span>',
			);
			// save
		}
	}

	showDialog: boolean = false;

	saveSimulation() {
		this.confirmationService.confirm({
			header: 'Save simulation?',
			message: 'Saving the simulation will save all the AI Solutions.',
			icon: 'pi pi-save',
			accept: () => {
				this._saveSimulation();
			},
		});
	}

	async _saveSimulation() {
		if (this.simulation) {
			this.simulation.enterpriseContext = {
				industry: this.simulationService.industryTreeSelections.map((d) => d.key as string),
				revenue: this.simulationService.revenueTreeSelections.label as string,
				information: this.simulationService.additionalInformationTreeSelections.map((d) => d.key as string),
				employee: this.simulationService.employeesTreeSelections.label as string,
				country: this.simulationService.countriesTreeSelections.key as string,
				topProducts: this.simulationService.topProductsTreeSelections.map((d) => d as string),
				topServices: this.simulationService.topServicesTreeSelections.map((d) => d as string),
				description: this.simulationService.descriptionSelection || '',
				data_landscape: this.simulationService.data_landscape || [],
			};
			this.simulation.simulationContext = {
				aiEnablers: this.aiEnablersTreeSelections.map((d) => d.key as string),
				technologies: this.technologyTreeSelections.map((d) => d.key as string),
				business_values: this.businessValueDriversTreeSelections.map((d) => d.key as string),
				impact: {
					...this.simulation.simulationContext.impact,
				},
				taxonomy: [],
				scope: this.scopeTreeSelections.map((d) => d.key as string),
				coldStorage: this.simulation.simulationContext.coldStorage || false,
				industries: this.simulation.simulationContext.industries || [],
			};
			await this.simulationService.saveSimulation(this.simulation);
			this.messageService.add({ severity: 'success', summary: 'Confirmed', detail: 'Simulation saved' });
			console.log('this.simulation', this.simulation);
		}
	}

	hasRunSimulation: boolean = false;

	runSimulation() {
		this.confirmationService.confirm({
			header: 'Run simulation?',
			message: 'This will generate new AI Solutions and delete the current ones.',
			icon: 'pi pi-sparkles',
			accept: () => {
				this._runSimulation();
			},
		});
	}

	_runSimulation() {
		if (this.simulation) {
			this.hasRunSimulation = true;
			const data = {
				name: this.simulation.name,
				description: this.simulation.description,
				additionalNotes: this.simulation.notes,
				scope: { 1: 'E2E', 2: 'Process', 3: 'Process' }[this.scopeTreeSelections[0]?.key || '3'],
				enterpriseContext: {
					industry: this.simulationService.industryTreeSelections.map((d) => d.label),
					revenue: this.simulationService.revenueTreeSelections.label,
					information: this.simulationService.additionalInformationTreeSelections.map((d) => d.label),
					employee: this.simulationService.employeesTreeSelections.label,
					country: this.simulationService.countriesTreeSelections.label,
					topProducts: this.simulationService.topProductsTreeSelections.map((d) => d as string),
					topServices: this.simulationService.topServicesTreeSelections.map((d) => d as string),
					description: this.simulationService.descriptionSelection,
					data_landscape: this.simulationService.data_landscape,
				},
				usesCasesContext: {
					aiEnablers: this.aiEnablersTreeSelections.map((d) => d.label),
					technologies: this.technologyTreeSelections.map((d) => d.label),
					benefits: this.businessValueDriversTreeSelections.map((d) => d.label),
					impact: {
						...this.simulation.simulationContext.impact,
					},
				},
			};

			console.log(data);

			this.http
				.post<IUseCaseSimulation[]>(environment.url + '/api/ai/simulation/', {
					description: JSON.stringify(data),
				})
				.subscribe({
					error: (err) => {
						console.log(err);
					},
					next: (useCases) => {
						this.currentUseCase = undefined;
						if (this.simulation) {
							this.simulationService.clearUseCaseSimulation(this.simulation).then(() => {
								if (this.simulation) {
									this.simulation.use_cases = useCases.map((d) =>
										plainToInstance(UseCaseSimulation, d),
									);
									this.currentUseCase = this.simulation.use_cases[0];

									this.simulation.use_cases.forEach((d) => {
										d.organizationId = this.organizationId;
										d.simulationId = this.simulationId;
										this.simulationService.saveUseCase(d).then(() => {});
									});
								}
							});
						}
					},
					complete: () => {
						this.hasSimulation = true;
						this.hasRunSimulation = false;
					},
				});
		}
	}

	addToMyUseCase() {
		this.confirmationService.confirm({
			header: 'Add to your AI Solutions?',
			message: 'This will add the AI Solution and an AI-generated diagram to your AI Solutions.',
			icon: 'pi pi-sparkles',
			accept: () => {
				this._addToMyUseCase();
			},
		});
	}

	addToMyUseCaseLoading: boolean = false;

	_addToMyUseCase() {
		this.addToMyUseCaseLoading = true;
		if (this.currentUseCase) {
			const params: { [key: string]: string } = {};
			if (this.simulation?.taxonomyId && this.simulation?.taxonomyRel === '3') {
				params['level2Id'] = this.simulation.taxonomyId;
			}
			this.useCaseService.setCurrentUseCase('', params).then(() => {
				if (this.useCaseService.currentUseCase && this.currentUseCase) {
					this.useCaseService.currentUseCase.name = this.currentUseCase.name;
					this.useCaseService.currentUseCase.description = this.currentUseCase.description;
					this.useCaseService.currentUseCase.diagram = this.useCaseService._newDiagramEmpty(
						this.useCaseService.currentUseCase,
					);
					this.authService.getCurrentOrganization().then((organization) => {
						if (organization && this.useCaseService.currentUseCase?.diagram) {
							this.useCaseService.currentUseCase.diagram.organization = organization;
							this.useCaseService.currentUseCase.diagram.organizationId = organization.id as string;
							this.useCaseService.currentDiagram = this.useCaseService.currentUseCase.diagram;
						}
					});
				}
			});
			this.http
				.post<{ xml: string }>(environment.url + '/api/ai/diagram/', {
					description: JSON.stringify({
						name: this.currentUseCase.name,
						description: this.currentUseCase.description,
						keyFeatures: this.currentUseCase.keyFeatures,
						context: this.currentUseCase.context,
					}),
					format: 'json',
				})
				.subscribe({
					error: (err) => {
						console.log(err);
					},
					next: (res) => {
						console.log(res);
						if (res && res.xml) {
							this.useCaseService.saveCurrentUseCase().then(async (error) => {
								if (error) {
									this.messageSaveUseCase(error);
								} else {
									if (this.useCaseService.currentUseCase?.diagram) {
										this.useCaseService
											.saveFilesDiagram(this.useCaseService.currentUseCase.diagram, res.xml, '')
											.then(async (error) => {
												if (error) {
													this.messageSaveUseCase(error);
												} else {
													if (this.useCaseService.currentUseCase?.diagram) {
														this.useCaseService
															.saveDiagram(this.useCaseService.currentUseCase.diagram)
															.then(async () => {
																this.messageSaveUseCase();
																if (
																	this.currentUseCase &&
																	this.useCaseService.currentUseCase
																) {
																	this.currentUseCase.deployedId = this.useCaseService
																		.currentUseCase.id as string;
																	this.simulationService
																		.saveUseCase(this.currentUseCase)
																		.then(() => {
																			if (this.simulation) {
																				this.simulation.use_cases =
																					this.simulation.use_cases.map(
																						(u) => {
																							if (
																								this.currentUseCase &&
																								u.id ===
																									this.currentUseCase
																										.id
																							) {
																								return this
																									.currentUseCase;
																							} else {
																								return u;
																							}
																						},
																					);
																			}
																		});
																}
															});
													}
												}
											});
									}
								}
							});
						}
					},
					complete: () => {
						this.hasSimulation = true;
						this.addToMyUseCaseLoading = false;
					},
				});
		}
	}

	messageSaveUseCase(error?: string) {
		if (error) {
			if (error === 'no-name') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a name for the new AI Solution',
				});
			} else if (error === 'no-description') {
				this.messageService.add({
					severity: 'warn',
					summary: 'No description',
					detail: 'Provide a description for the new AI Solution',
				});
			} else if (error === 'no-updated') {
				this.messageService.add({ severity: 'warn', summary: 'No changes', detail: 'Nothing to save' });
			} else {
				this.messageService.add({ severity: 'error', summary: 'Error', detail: error });
			}
		} else {
			this.messageService.add({
				severity: 'success',
				summary: 'Confirmed',
				detail: 'AI Solution saved on My AI Solutions',
			});
		}
	}

	async goToMyUseCase() {
		if (this.currentUseCase && this.currentUseCase.deployedId) {
			await this.useCaseService.goToUseCase(this.currentUseCase.deployedId, true);
		}
	}
}
