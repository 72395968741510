import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

type ModelEvent =
	| 'CommonData'
	| 'CategoryFunction'
	| 'Function'
	| 'Level1'
	| 'Level2'
	| 'DiagramE2E'
	| 'DiagramProcess'
	| 'DiagramTemplate'
	| 'Technology'
	| 'DigitalWorker'
	| 'FunctionFilter'
	| 'Tool'
	| 'Skill'
	| 'AiLever'
	| 'Solution'
	| 'Flow'
	| 'CategoryQuestion'
	| 'SubCategoryQuestion'
	| 'Question'
	| 'Diagram'
	| 'UseCase'
	| 'EnterpriseContext'
	| 'Simulation'
	| 'UseCaseSimulation'
	| 'UseCaseLibrary'
	| 'M1Prep';

interface EventData {
	type: string;
	event: 'create' | 'update' | 'delete';
	object_id: string;
	object_ids: string[];
	model_name: 'user' | 'Organization' | ModelEvent;
	user_id: string;
	module: '0' | '1' | '2' | '3' | '4' | '5';
}

interface MessageToast {
	summary: string;
	detail: string;
}

@Injectable({
	providedIn: 'root',
})
export class WebSocketAPI {

	private dbMessageToast = new Subject<MessageToast>();
	dbMessageToastSource$ = this.dbMessageToast.asObservable();

	private dbUseCaseChange = new Subject<string>();
	private dbAuthChange = new Subject<EventData>();
	dbUseCaseChange$ = this.dbUseCaseChange.asObservable();
	dbAuthChange$ = this.dbAuthChange.asObservable();

	run() {
		if (localStorage.getItem('auth-token')) {
			const url = environment.url.replace('http', 'ws');
			const socket = new WebSocket(url + '/updates/' + '?token=' + localStorage.getItem('auth-token'));

			socket.addEventListener('message', (event) => {
				console.log('message', event);

				if (!!localStorage.getItem('auth-token')) {
					const data: EventData = JSON.parse(event.data);
					// console.log('Message from server: ', data);
					if (data.module === '0' && data.model_name !== 'CommonData') {
						this.dbAuthChange.next(data);
					}
					if (data.module === '3') {
						console.log('dbOrganizationUpdateSource', data);
						if (data.model_name == 'UseCase') {
							this.dbUseCaseChange.next(data.object_id);
						}
					}
					if (data.module === '4' || data.module === '5') {
						// this.webSocketEvent(data, () => {});
					}
				}
			});

			socket.onerror = () => {
				if (!!localStorage.getItem('auth-token')) {
					this.dbMessageToast.next({
						summary: 'Connection error',
						detail: 'There were connection errors and the session has been closed for security and to maintain data integrity.',
					});
				} else {
					setTimeout(() => this.run(), 5000);
				}
			};
		} else {
			setTimeout(() => this.run(), 5000);
		}
	}
}
