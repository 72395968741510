<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<div class="flex flex-column w-full gap-5 px-2 card-content-simulation">
	<div class="flex flex-wrap justify-content-start align-content-start">
		<div class="col-12 col-content py-1">
			<p-accordion>
				<p-accordionTab header="Set Parameters" [selected]="showSetParameters" class="accordion-set-parameters">
					<div class="flex justify-content-between align-items-center">
						<label class="text-md lg:text-lg xl:text-xl font-bold pb-3">Set Parameters</label>
					</div>
					<div class="flex flex-wrap">
						<div class="col-6 py-0">
							<div class="flex flex-wrap">
								<div class="col-12 px-0 pt-1">
									<div class="flex flex-column justify-content-start align-items-start gap-2">
										<label for="simulationName" class="font-bold label-filter-details"
											>Simulation Name</label
										>
										<input
											*ngIf="loaded && simulation && simulationService.loaded"
											type="text"
											pInputText
											placeholder="Enter Simulation Name"
											id="simulationName"
											class="w-full"
											[(ngModel)]="simulationName"
										/>
										<p-skeleton
											*ngIf="!loaded || !simulation || !simulationService.loaded"
											width="100%"
											height="2rem"
											class="w-full"
										></p-skeleton>
									</div>
									<div class="flex flex-column justify-content-start align-items-start gap-2 pt-3">
										<label for="industry" class="font-bold label-filter-details">Industry</label>
										<p-multiSelect
											*ngIf="loaded && simulation"
											class="w-full w-100"
											styleClass="filter-content min-token"
											[options]="simulationService.industries"
											[(ngModel)]="simulation.simulationContext.industries"
											display="chip"
											id="industry"
											[disabled]="!simulationService.loaded"
											inputId="industry"
											placeholder="Select Industries"
											appendTo="body"
											optionLabel="name"
											optionValue="id"
											[filter]="false"
											[showHeader]="false"
											(onChange)="updateIndustry()"
										></p-multiSelect>
										<p-skeleton
											*ngIf="!loaded || !simulation"
											width="100%"
											height="2rem"
											class="w-full"
										></p-skeleton>
									</div>
									<div class="flex flex-column justify-content-start align-items-start gap-2 pt-3">
										<label for="taxonomy" class="font-bold label-filter-details">Taxonomy</label>
										<p-treeSelect
											*ngIf="loaded && simulation"
											class="w-full w-100"
											containerStyleClass="w-full w-100"
											[(ngModel)]="taxonomyTreeSelections"
											[options]="simulationService.addProcessTaxonomyTreeSelect"
											placeholder="All"
											display="chip"
											[disabled]="!simulationService.loaded"
											[metaKeySelection]="false"
											selectionMode="checkbox"
											appendTo="body"
											id="taxonomy"
											inputId="taxonomy"
											[propagateSelectionUp]="false"
											(onNodeSelect)="updateTaxonomy()"
											(onNodeUnselect)="updateTaxonomy()"
										></p-treeSelect>
										<p-skeleton
											*ngIf="!loaded || !simulation"
											width="100%"
											height="2rem"
											class="w-full"
										></p-skeleton>
									</div>
									<div class="flex flex-column justify-content-start align-items-start gap-2 pt-3">
										<label for="scope" class="font-bold label-filter-details">Scope</label>
										<p-multiSelect
											*ngIf="loaded && simulation"
											class="element-filter-100 w-full"
											styleClass="filter-content min-token"
											[(ngModel)]="scopeTreeSelections"
											[options]="simulationService.scopeTreeOptions"
											[disabled]="!simulationService.loaded"
											display="chip"
											id="scope"
											inputId="scope"
											placeholder="Select scope"
											appendTo="body"
											[filter]="false"
											[showHeader]="false"
										></p-multiSelect>
										<p-skeleton
											*ngIf="!loaded || !simulation"
											width="100%"
											height="2rem"
											class="w-full"
										></p-skeleton>
									</div>
								</div>
							</div>
						</div>
						<div class="col-6 py-0">
							<div class="flex flex-wrap">
								<div class="col-12 px-0 pt-1">
									<div class="flex flex-wrap">
										<div
											class="flex flex-column justify-content-start align-items-start gap-2 w-full"
										>
											<label for="simulationDescription" class="font-bold label-filter-details"
												>Prompt Instructions</label
											>
											<textarea
												*ngIf="loaded && simulation && simulationService.loaded"
												type="text"
												pInputTextarea
												placeholder="Enter Prompt Instructions"
												id="simulationDescription"
												class="w-full"
												[(ngModel)]="simulation.description"
											></textarea>
											<p-skeleton
												*ngIf="!loaded || !simulation || !simulationService.loaded"
												width="100%"
												height="4rem"
												class="w-full"
											></p-skeleton>
										</div>
									</div>
								</div>
								<div class="col-6 pt-1">
									<div class="flex flex-column gap-3">
										<div class="flex flex-column justify-content-start align-items-start gap-2">
											<label class="font-bold label-filter-details">Benefit Area</label>
											<div *ngIf="loaded && simulation" class="flex flex-column gap-3">
												<div
													*ngFor="let option of businessValueDriversOptions"
													class="pr-3 flex align-items-center"
												>
													<p-checkbox
														[binary]="true"
														[(ngModel)]="option.selected"
														inputId="{{ option.key }}"
													></p-checkbox>
													<label for="{{ option.key }}" class="pl-2 cursor-pointer">{{
														option.label
													}}</label>
												</div>
											</div>
											<div *ngIf="!loaded || !simulation" class="flex flex-column gap-3">
												<div
													*ngFor="let _ of [1, 2, 3, 4, 5]"
													class="pr-3 flex align-items-center"
												>
													<div
														style="
															width: 24px;
															height: 24px;
															display: flex;
															align-items: center;
															justify-content: center;
														"
													>
														<p-skeleton width="24px" height="24px"></p-skeleton>
													</div>
													<div class="pl-3">
														<p-skeleton width="200px" height="20px"></p-skeleton>
													</div>
												</div>
											</div>
										</div>
										<div class="flex align-items-center">
											<p-inputSwitch
												*ngIf="loaded && simulation"
												class="change-org-switch"
												[(ngModel)]="simulation.search"
												inputId="hackettTaxonomy"
											></p-inputSwitch>
											<p-skeleton
												*ngIf="!loaded || !simulation"
												width="48px"
												height="28px"
												borderRadius="30px"
											></p-skeleton>
											<label
												class="font-bold label-filter-details pl-2 cursor-pointer"
												(click)="toggleInputSwitch('hackettTaxonomy')"
												>Hackett Taxonomy AI Solutions</label
											>
										</div>
									</div>
								</div>

								<div class="col-6 pt-1 d-flex flex-column h-100">
									<div class="flex flex-column gap-2">
										<div class="w-full px-0">
											<label class="font-bold label-filter-details mb-2"
												>AI Solutions By Impact Category</label
											>
											<div class="flex flex-column pt-3 gap-3" *ngIf="loaded || simulation">
												<div class="pr-3 flex align-items-center">
													<p-checkbox
														[binary]="true"
														[(ngModel)]="impact.breakthrough"
														inputId="breakthrough"
														id="breakthrough"
													></p-checkbox>
													<span
														class="pl-2 cursor-pointer"
														(click)="impact.breakthrough = !impact.breakthrough"
													>
														Breakthrough
													</span>
												</div>
												<div class="pr-3 flex align-items-center">
													<p-checkbox
														[binary]="true"
														[(ngModel)]="impact.transformative"
														inputId="transformative"
														id="transformative"
													></p-checkbox>
													<span
														class="pl-2 cursor-pointer"
														(click)="impact.transformative = !impact.transformative"
													>
														Transformative
													</span>
												</div>
												<div class="pr-3 flex align-items-center">
													<p-checkbox
														[binary]="true"
														[(ngModel)]="impact.incremental"
														inputId="incremental"
														id="incremental"
													></p-checkbox>
													<span
														class="pl-2 cursor-pointer"
														(click)="impact.incremental = !impact.incremental"
													>
														Incremental
													</span>
												</div>
											</div>
											<div *ngIf="!loaded || !simulation" class="flex flex-column gap-3 pt-2">
												<div *ngFor="let _ of [1, 2, 3]" class="pr-3 flex align-items-center">
													<div
														style="
															width: 24px;
															height: 24px;
															display: flex;
															align-items: center;
															justify-content: center;
														"
													>
														<p-skeleton width="24px" height="24px"></p-skeleton>
													</div>
													<div class="pl-3">
														<p-skeleton width="200px" height="20px"></p-skeleton>
													</div>
												</div>
											</div>
										</div>
										<div class="pt-5"></div>
										<div class="w-full px-0 mt-auto pt-5">
											<div class="flex flex-column gap-2">
												<div class="flex align-items-center">
													<p-inputSwitch
														*ngIf="loaded && simulation"
														class="change-org-switch"
														[(ngModel)]="simulation.generate"
														inputId="hackettIndustries"
													></p-inputSwitch>
													<p-skeleton
														*ngIf="!loaded || !simulation"
														width="48px"
														height="28px"
														class="pt-3"
														borderRadius="30px"
													></p-skeleton>
													<label
														class="font-bold label-filter-details pl-2 cursor-pointer"
														[ngClass]="{ 'pt-3': !loaded || !simulation }"
														(click)="toggleInputSwitch('hackettIndustries')"
													>
														Hackett Industries AI Solutions
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 p-1 flex justify-content-end align-items-center pt-3 gap-3">
									<p-button
										label="Clear"
										icon="pi pi-eraser"
										styleClass="button-header"
										[disabled]="hasRunSimulation"
										[loading]="loadingSave"
										(onClick)="clearSimulationConfirm()"
									></p-button>
									<p-button
										label="Save"
										icon="pi pi-save"
										styleClass="button-header"
										[disabled]="hasRunSimulation"
										[loading]="loadingSave"
										(onClick)="saveSimulation()"
									></p-button>
									<p-button
										label="Run Simulation"
										icon="pi pi-play-circle"
										(onClick)="runSimulation()"
										[loading]="hasRunSimulation || loadingSave"
									></p-button>
								</div>
							</div>
						</div>
					</div>
				</p-accordionTab>
				<div class="pt-5"></div>
				<p-accordionTab
					header="AI Solution Overview"
					[selected]="showAISolutionOverview"
					class="accordion-use-cases-overview"
				>
					<div *ngIf="!hasSimulation" class="mt-3 py-3">
						<div class="flex flex-column justify-content-start gap-1">
							<div
								*ngIf="!hasRunSimulation"
								class="flex justify-content-center align-items-center h-15rem text-color-normal"
							>
								<span class="text-empty">
									Set the parameters and run a simulation to see the AI Solutions
								</span>
							</div>
						</div>
					</div>
					<div
						*ngIf="hasRunSimulation"
						class="mt-5 pt-5 d-flex justify-content-center align-items-center text-center"
						style="height: 200px"
					>
						<i class="pi pi-spin pi-spinner" style="font-size: 5rem; color: #d9d9d9"></i>
						<div class="pt-5">Exploring Hackett Taxonomy...</div>
					</div>
					<div *ngIf="hasSimulation" class="pb-3">
						<div class="flex flex-column justify-content-start gap-1">
							<div *ngIf="showUseCases">
								<div class="relative">
									<div class="absolute buttons-actions">
										<div class="flex justify-content-end gap-2 card p-0">
											<p-button
												*ngIf="dtSelection"
												icon="pi pi-trash"
												[label]="'Delete (' + dtSelection + ') selections'"
												styleClass="p-button-text"
												(onClick)="deleteUseCases()"
											></p-button>
										</div>
									</div>
								</div>
								<div *ngIf="!hasRunSimulation">
									<table #tableUseCases class="display w-full"></table>
								</div>
							</div>
							<div
								*ngIf="!showUseCases && !hasRunSimulation"
								class="flex justify-content-center align-items-center h-15rem text-color-normal"
							>
								<span class="text-empty">
									Set the parameters and run a simulation to see the AI Solutions
								</span>
							</div>
						</div>
					</div>
				</p-accordionTab>
			</p-accordion>

			<div *ngIf="loaded && !simulation" class="col-12 col-content">
				<div class="flex justify-content-center align-items-center h-25rem text-color-normal">
					<div class="flex flex-column gap-3 align-content-center justify-content-center text-center">
						<div class="w-full text-center">
							<img src="assets/rtp/img/simulations-empty.png" alt="" class="w-5rem" />
						</div>
						<span class="text-lg text-primary">No simulation found</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<p-dialog
	header="AI Solution"
	[(visible)]="displayModal"
	[modal]="true"
	[style]="{ width: '80vw' }"
	(onHide)="onClose()"
>
	<app-use-case-simulation-item *ngIf="currentUseCase" [useCase]="currentUseCase" [useCasesSaved]="useCasesSaved">
	</app-use-case-simulation-item>
</p-dialog>

<p-dialog
	header="Saving Simulation..."
	[visible]="loadingSave && !hasRunSimulation"
	[modal]="true"
	[style]="{ width: '50vw' }"
>
	<div class="flex justify-content-center align-items-center h-5rem w-full">
		<i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
	</div>
</p-dialog>
