import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SimulationService } from '../simulation.service';
import { HttpClient } from '@angular/common/http';
import { UseCasesService } from '../use-cases.service';
import { AuthService } from '../../../service/auth.service';
import { M1Prep, UseCaseSimulation } from '../../../api/simulation.api';
import { environment } from '../../../../../environments/environment';
import { of, Subject, Subscription } from 'rxjs';
import type { Api, Config } from 'datatables.net';
import { Chart, registerables } from 'chart.js';
import { format } from 'date-fns';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Organization } from 'src/app/rtp/api/common';

@Component({
	selector: 'app-m1-prep',
	providers: [ConfirmationService, MessageService],
	templateUrl: './m1-prep.component.html',
	styleUrls: ['./m1-prep.component.scss'],
})
export class M1PrepComponent implements OnInit, OnDestroy {
	@ViewChild('tableUseCases') tableUseCases: ElementRef<HTMLTableElement>;
	@ViewChild('salesforceChart') salesforceChart!: ElementRef<HTMLCanvasElement>;

	constructor(
		private http: HttpClient,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public authService: AuthService,
		public simulationService: SimulationService,
		public useCasesService: UseCasesService,
	) {}

	subSearch: Subscription;
	subM1Prep: Subscription;

	organization: Organization;

	async ngOnInit() {
		this.subSearch = this.searchTerm$
			.pipe(
				debounceTime(300),
				distinctUntilChanged(),
				switchMap((term) => this.searchCompany(term)),
			)
			.subscribe((companies) => {
				if (companies && companies.length > 0) {
					this.suggestions = companies;
				} else {
					this.suggestions = [];
				}
			});

		this.subM1Prep = this.simulationService.selectedM1PrepObservable.subscribe({
			next: (M1Prep) => {
				this.currentM1Prep = M1Prep;
				this.hasRun = true;
			},
		});

		this.authService.getCurrentOrganization().then((organization) => {
			if (organization) {
				this.organization = organization;
				this.emptyM1Prep();
			}
		});
	}

	ngOnDestroy() {
		this.subSearch.unsubscribe;
		this.subM1Prep.unsubscribe;
	}

	loadingSearch: boolean = false;
	suggestions: any[] = [];
	searchTerm$ = new Subject<string>();
	selectedCompany: any = null;
	searchCache: { [term: string]: any[] } = {};

	searchCompany(term: string) {
		const cachedResults = this.searchCache[term];

		if (cachedResults) {
			const resultsWithoutDuplicates = cachedResults.filter(
				(result) => result.name.toLowerCase() !== term.toLowerCase(),
			);
			return of([{ name: term }, ...resultsWithoutDuplicates]);
		}

		const apiUrl = `${environment.url}/api/salesforce/account-names/?q=${term}`;
		return this.http.get<any[]>(apiUrl).pipe(
			tap((response: any[]) => {
				this.searchCache[term] = response;
			}),
			map((response) => {
				const resultsWithoutDuplicates = response.filter(
					(result) => result.name.toLowerCase() !== term.toLowerCase(),
				);
				return [{ name: term }, ...resultsWithoutDuplicates];
			}),
		);
	}

	onCompanySelect(event: any) {
		if (
			event.industry_group_or_industry &&
			event.industry_group_or_industry.trim().length > 0 &&
			event.industry_group_or_industry !== '-'
		) {
			const industryNode = {
				label: event.industry_group_or_industry,
				data: event.industry_group_or_industry,
				leaf: true,
			};

			this.currentM1Prep.context.industryTreeSelected = [industryNode];
		} else {
			this.currentM1Prep.context.industryTreeSelected = [];
		}
	}

	search(event: any) {
		const query = event.query;

		if (query.length > 2) {
			this.searchCompany(query).subscribe({
				next: (companies) => {
					this.suggestions = companies;
				},
				error: (err) => {
					console.error('Error', err);
				},
			});
		} else {
			this.suggestions = [];
		}
	}

	loadingA: boolean = false;
	loadingB: boolean = false;
	loadingC: boolean = false;
	loadingD: boolean = false;
	loadingE: boolean = false;

	additionalInformation: string = '';

	recommendedUsesCases: UseCaseSimulation[] = [];

	chart: any;
	contacts: string[] = [];
	selectedContact: string = '';
	groupedDataByContact: any[] = [];
	filteredTableData: any[] = [];
	filteredChartData: any[] = [];
	filteredData: any[] = [];
	selectedYear: number | '' = '';
	availableYears: number[] = [];

	applyFilter() {
		if (this.currentM1Prep.salesforce.length) {
			let filteredData = this.currentM1Prep.salesforce;

			if (this.selectedContact) {
				filteredData = filteredData.filter((item) => item['OpportunityOwner'] === this.selectedContact);
			}

			if (this.selectedYear) {
				filteredData = filteredData.filter((item) => {
					const createdDate = item['CreatedDate'];
					return createdDate.includes(this.selectedYear.toString());
				});
			}
			this.filteredTableData = this.groupDataByContact(filteredData);
			this.filteredChartData = filteredData;

			this.updateTable();
			this.updateChart();
		}
	}

	getUniqueYears(): number[] {
		const years = this.currentM1Prep.salesforce.length
			? this.currentM1Prep.salesforce
					.map((item) => new Date(item['CreatedDate']).getFullYear())
					.filter((year) => !isNaN(year))
			: [];
		return Array.from(new Set(years)).sort((a, b) => a - b);
	}

	createChart() {
		Chart.register(...registerables);

		if (this.chart) {
			this.chart.destroy();
		}

		const canvas = this.salesforceChart.nativeElement;
		const context = canvas.getContext('2d');

		if (context) {
			const devicePixelRatio = window.devicePixelRatio || 1;
			context.scale(devicePixelRatio, devicePixelRatio);

			if (!this.currentM1Prep.hasSalesforce) {
				return;
			}
			canvas.width = canvas.offsetWidth * devicePixelRatio;
			canvas.height = 350 * devicePixelRatio;
			const filteredData = this.currentM1Prep.salesforce.length
				? this.currentM1Prep.salesforce.filter((item) => {
						const amount = item['Amount'];
						const date = new Date(item['CreatedDate']);
						return !isNaN(date.getTime()) && amount > 0;
				  })
				: [];

			const uniqueDates = [
				...new Set(filteredData.map((item) => format(new Date(item['CreatedDate']), 'yyyy-MM-dd'))),
			].sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

			const groupedData: { [key: string]: { [date: string]: number } } = {
				'Closed - Cancelled': {},
				'Closed - Withdrawn / Abandoned': {},
				Identification: {},
				'Contracted Win': {},
				'Closed - Lost': {},
			};

			filteredData.forEach((item) => {
				const date = format(new Date(item['CreatedDate']), 'yyyy-MM-dd');
				const amount = item['Amount'];
				if (item['StageName'] === 'Closed - Cancelled') {
					groupedData['Closed - Cancelled'][date] = (groupedData['Closed - Cancelled'][date] || 0) + amount;
				} else if (item['StageName'] === 'Closed - Withdrawn / Abandoned') {
					groupedData['Closed - Withdrawn / Abandoned'][date] =
						(groupedData['Closed - Withdrawn / Abandoned'][date] || 0) + amount;
				} else if (item['StageName'] === 'Identification') {
					groupedData['Identification'][date] = (groupedData['Identification'][date] || 0) + amount;
				} else if (item['StageName'] === 'Contracted Win') {
					groupedData['Contracted Win'][date] = (groupedData['Contracted Win'][date] || 0) + amount;
				} else if (item['StageName'] === 'Closed - Lost') {
					groupedData['Closed - Lost'][date] = (groupedData['Closed - Lost'][date] || 0) + amount;
				}
			});

			const datasets = [
				{
					label: 'Closed - Cancelled',
					data: uniqueDates.map((date) => groupedData['Closed - Cancelled'][date] || 0),
					backgroundColor: '#F4AA81',
					borderColor: '#F4AA81',
					borderWidth: 1,
				},
				{
					label: 'Closed - Withdrawn / Abandoned',
					data: uniqueDates.map((date) => groupedData['Closed - Withdrawn / Abandoned'][date] || 0),
					backgroundColor: '#81C784',
					borderColor: '#81C784',
					borderWidth: 1,
				},
				{
					label: 'Identification',
					data: uniqueDates.map((date) => groupedData['Identification'][date] || 0),
					backgroundColor: '#64B5F6',
					borderColor: '#64B5F6',
					borderWidth: 1,
				},
				{
					label: 'Contracted Win',
					data: uniqueDates.map((date) => groupedData['Contracted Win'][date] || 0),
					backgroundColor: '#FFB74D',
					borderColor: '#FFB74D',
					borderWidth: 1,
				},
				{
					label: 'Closed - Lost',
					data: uniqueDates.map((date) => groupedData['Closed - Lost'][date] || 0),
					backgroundColor: '#D32F2F',
					borderColor: '#D32F2F',
					borderWidth: 1,
				},
			];

			this.chart = new Chart(context, {
				type: 'bar',
				data: {
					labels: uniqueDates,
					datasets: datasets,
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						x: {
							title: { display: false },
							type: 'category',
							grid: { display: false },
						},
						y: {
							grid: { display: true },
							ticks: { stepSize: 500000 },
							title: { display: false },
						},
					},
					plugins: {
						datalabels: {
							display: false,
						},
						legend: {
							position: 'top',
							align: 'start',
						},
					},
				},
			});
		} else {
			console.error('Canvas context is null');
		}
	}

	updateChart() {
		if (!this.currentM1Prep.hasSalesforce) {
			return;
		}
		if (!this.chart || !this.salesforceChart.nativeElement) {
			console.error('The chart or canvas is not initialized');
			return;
		}

		const filteredData = this.filteredChartData.filter((item) => {
			const amount = item['Amount'];
			const date = new Date(item['CreatedDate']);
			return !isNaN(date.getTime()) && amount > 0;
		});

		const uniqueDates = [
			...new Set(filteredData.map((item) => format(new Date(item['CreatedDate']), 'yyyy-MM-dd'))),
		].sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

		const groupedData: { [key: string]: { [date: string]: number } } = {
			'Closed - Cancelled': {},
			'Closed - Withdrawn / Abandoned': {},
			Identification: {},
			'Contracted Win': {},
			'Closed - Lost': {},
		};

		filteredData.forEach((item) => {
			const date = format(new Date(item['CreatedDate']), 'yyyy-MM-dd');
			const amount = item['Amount'];

			if (item['StageName'] === 'Closed - Cancelled') {
				groupedData['Closed - Cancelled'][date] = (groupedData['Closed - Cancelled'][date] || 0) + amount;
			} else if (item['StageName'] === 'Closed - Withdrawn / Abandoned') {
				groupedData['Closed - Withdrawn / Abandoned'][date] =
					(groupedData['Closed - Withdrawn / Abandoned'][date] || 0) + amount;
			} else if (item['StageName'] === 'Identification') {
				groupedData['Identification'][date] = (groupedData['Identification'][date] || 0) + amount;
			} else if (item['StageName'] === 'Contracted Win') {
				groupedData['Contracted Win'][date] = (groupedData['Contracted Win'][date] || 0) + amount;
			} else if (item['StageName'] === 'Closed - Lost') {
				groupedData['Closed - Lost'][date] = (groupedData['Closed - Lost'][date] || 0) + amount;
			}
		});

		this.chart.data.labels = uniqueDates;
		this.chart.data.datasets = [
			{
				label: 'Closed - Cancelled',
				data: uniqueDates.map((date) => groupedData['Closed - Cancelled'][date] || 0),
				backgroundColor: '#F4AA81',
				borderColor: '#F4AA81',
				borderWidth: 1,
			},
			{
				label: 'Closed - Withdrawn / Abandoned',
				data: uniqueDates.map((date) => groupedData['Closed - Withdrawn / Abandoned'][date] || 0),
				backgroundColor: '#81C784',
				borderColor: '#81C784',
				borderWidth: 1,
			},
			{
				label: 'Identification',
				data: uniqueDates.map((date) => groupedData['Identification'][date] || 0),
				backgroundColor: '#64B5F6',
				borderColor: '#64B5F6',
				borderWidth: 1,
			},
			{
				label: 'Contracted Win',
				data: uniqueDates.map((date) => groupedData['Contracted Win'][date] || 0),
				backgroundColor: '#FFB74D',
				borderColor: '#FFB74D',
				borderWidth: 1,
			},
			{
				label: 'Closed - Lost',
				data: uniqueDates.map((date) => groupedData['Closed - Lost'][date] || 0),
				backgroundColor: '#D32F2F',
				borderColor: '#D32F2F',
				borderWidth: 1,
			},
		];

		this.chart.update();
	}

	updateTable() {
		const data = this.filteredTableData.map((contact) => ({
			contact: contact.contact,
			role: contact.role,
			opportunities: contact.opportunities,
			totalAmount: contact.totalAmount,
		}));

		if (this.dTable) {
			this.dTable.clear().rows.add(data).draw();
		} else {
			const tableElement = this.tableUseCases.nativeElement;
			this.dTable = new DataTable(tableElement, {
				data: data,
				columns: [
					{ title: 'Opportunity Owner', data: 'contact' },
					{
						title: 'Name(s)',
						data: 'opportunities',
						render: function (data: any) {
							return Array.isArray(data)
								? data.map((opportunity) => opportunity.name).join(', ')
								: 'No Opportunities';
						},
					},
					{
						title: 'Sum of Amount',
						data: 'totalAmount',
						render: $.fn.dataTable.render.number(',', '.', 2, '$'),
					},
				],
				responsive: true,
				scrollX: true,
				pageLength: 50,
				autoWidth: true,
				lengthChange: false,
				searching: false,
				info: false,
				paging: false,
			});
		}
	}

	clearM1Prep() {
		this.emptyM1Prep();

		this.hasRun = false;

		this.filteredTableData = [];
		this.filteredChartData = [];

		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}

		if (this.dTable) {
			this.dTable.clear().destroy();
			this.dTable = undefined;
		}

		this.loadingA = false;
		this.loadingB = false;
		this.loadingC = false;
		this.loadingD = false;
		this.loadingE = false;
	}

	onFilterChange(event: Event) {
		const target = event.target as HTMLSelectElement;
		const selectedContact = target.value;

		if (!selectedContact || selectedContact === 'todos') {
			this.filteredData = [...(this.currentM1Prep.salesforce.length ? this.currentM1Prep.salesforce : [])];
		} else {
			const filteredData =
				this.currentM1Prep.salesforce.filter((item) => item['OpportunityOwner'] === this.selectedContact) ?? [];
		}

		this.groupedDataByContact = this.groupDataByContact(this.filteredData);
		this.updateTable();

		this.filteredChartData = this.filteredData;
		this.updateChart();
	}

	groupDataByContact(data: any[] = this.currentM1Prep.salesforce.length ? this.currentM1Prep.salesforce : []) {
		const groupedData: {
			contact: string;
			role: string;
			opportunities: { name: string; amount: number }[];
			totalAmount: number;
		}[] = [];

		data.forEach((item) => {
			const contactIndex = groupedData.findIndex((data) => data.contact === item['OpportunityOwner']);

			if (contactIndex === -1) {
				groupedData.push({
					contact: item['OpportunityOwner'],
					role: item['OpportunityOwner Role'],
					opportunities: [{ name: item['Name'], amount: item['Amount'] }],
					totalAmount: item['Amount'],
				});
			} else {
				groupedData[contactIndex].opportunities.push({ name: item['Name'], amount: item['Amount'] });
				groupedData[contactIndex].totalAmount += item['Amount'];
			}
		});

		return groupedData;
	}

	activeSalesforce: boolean = false;
	runSimulationSubject: Subject<string> = new Subject<string>();

	missingFields: string[] = [];

	hasRun: boolean = false;

	runAssistant() {
		this.missingFields = [];

		if (!this.currentM1Prep.context.info || !this.currentM1Prep.context.info.name?.trim()) {
			this.missingFields.push('Company');
		}

		if (!this.currentM1Prep.context.industryTreeSelected.length) {
			this.missingFields.push('Industry');
		}

		if (!this.currentM1Prep.context.taxonomyTreeSelected.length) {
			this.missingFields.push('Taxonomy');
		}

		if (this.missingFields.length > 0) {
			const detailMessage = `Please fill ${this.missingFields.join(', ')} field${
				this.missingFields.length > 1 ? 's' : ''
			}.`;
			this.messageService.add({
				severity: 'error',
				summary: 'Validation Error',
				detail: detailMessage,
			});
			return;
		}
		this.currentM1Prep.companyAnalysis = {};
		this.currentM1Prep.competitiveLandscape = {};
		this.currentM1Prep.products = {};
		this.currentM1Prep.salesforce = [];

		this.filteredTableData = [];
		this.filteredChartData = [];
		this.activeSalesforce = false;

		if (this.chart) {
			this.chart.destroy();
			this.chart = null;
		}

		if (this.dTable) {
			this.dTable.clear().destroy();
			this.dTable = undefined;
		}

		this.loadingA = true;
		this.loadingB = true;
		this.loadingC = true;
		this.loadingD = true;
		this.loadingE = true;
		const m1prepData = {
			enterpriseContext: {
				companyName: this.currentM1Prep.context.info.name || '',
				industry: this.currentM1Prep.context.industryTreeSelected.map((d) => d.label).join(', ') || '',
				additionalNotes: this.additionalInformation || '',
				taxonomy: this.currentM1Prep.context.taxonomyTreeSelected.map((d) => d.label) || [''],
			},
		};
		const apiUrl = `${environment.url}/api/salesforce/account-names/?q=${this.currentM1Prep.context.info.name}`;
		this.http.get<any[]>(apiUrl).subscribe({
			next: (response) => {
				const existsInAccountNames = response.some(
					(result) => result.name.toLowerCase() === this.currentM1Prep.context.info.name?.toLowerCase(),
				);
				this.activeSalesforce = existsInAccountNames;

				if (this.activeSalesforce) {
					const salesforceApiUrl = `${environment.url}/api/salesforce/account?company_name=${this.currentM1Prep.context.info.name}`;
					this.http
						.get<any>(salesforceApiUrl)
						.pipe(
							map((response: { data: any }) => {
								this.loadingD = false;
								const parsedData = response.data.opportunity.map((opportunity: any) => {
									return {
										...opportunity,
										OpportunityOwner:
											opportunity?.Owner?.FirstName + ' ' + opportunity?.Owner?.LastName || 'N/A',
									};
								});
								return parsedData;
							}),
						)
						.subscribe({
							next: (data: any) => {
								this.currentM1Prep.salesforce = data;
								this.groupedDataByContact = this.groupDataByContact(this.currentM1Prep.salesforce);

								this.availableYears = this.getUniqueYears();
								this.contacts = [...new Set(this.groupedDataByContact.map((data) => data.contact))];
								this.filteredTableData = this.groupedDataByContact;
								this.filteredChartData = this.currentM1Prep.hasSalesforce
									? [...this.currentM1Prep.salesforce]
									: [];
								if (this.currentM1Prep.hasSalesforce) {
									this.createChart();
									this.updateTable();
									this.updateChart();
								}
								this.loadingD = true;
							},
							error: (err) => {
								console.error('API call failed:', err);
								this.loadingD = false;
							},
						});
				} else {
					this.activeSalesforce = false;
					this.loadingD = false;
				}
			},
			error: (err) => {
				console.error('Error fetching account names:', err);
				this.loadingD = false;
			},
		});

		this.http.post<any>(environment.url + '/api/ai/m1prep/analysis', m1prepData).subscribe({
			error: (err) => {
				console.log(err);
				this.loadingA = false;
			},
			next: (analysisData) => {
				this.currentM1Prep.companyAnalysis = analysisData;
				this.loadingA = false;

				if (!this.loadingA && !this.loadingB && !this.loadingC) {
					this.sendConversationRequest();
				}
			},
		});

		this.http.post<any>(environment.url + '/api/ai/m1prep/products', m1prepData).subscribe({
			error: (err) => {
				console.log(err);
				this.loadingB = false;
			},
			next: (productsData) => {
				this.currentM1Prep.products = productsData;
				this.loadingB = false;

				if (!this.loadingA && !this.loadingB && !this.loadingC) {
					this.sendConversationRequest();
				}
			},
		});

		this.http.post<any>(environment.url + '/api/ai/m1prep/landscape', m1prepData).subscribe({
			error: (err) => {
				console.log(err);
				this.loadingC = false;
			},
			next: (landscapeData) => {
				this.currentM1Prep.competitiveLandscape = landscapeData;
				this.loadingC = false;

				if (!this.loadingA && !this.loadingB && !this.loadingC) {
					this.sendConversationRequest();
				}
			},
		});
	}

	saveM1Prep() {
		if (this.currentM1Prep) {
			if (!this.currentM1Prep.context.info.name) {
				this.messageService.add({
					severity: 'warn',
					summary: 'No name',
					detail: 'Provide a Company for the M1 Prep',
				});
			} else {
				this.confirmationService.confirm({
					header: 'Save M1 Prep?',
					message: 'Saving the M1 Prep will save all data.',
					icon: 'pi pi-save',
					accept: () => {
						this._saveM1Prep();
					},
				});
			}
		}
	}

	_saveM1Prep() {
		this.simulationService
			.saveM1Prep(this.currentM1Prep)
			.then(() => {
				this.messageService.add({
					severity: 'success',
					summary: 'Confirmed',
					detail: 'M1 Prep Saved successfully',
				});
				console.log('Data saved successfully');
			})
			.catch((error) => {
				console.error('Error saving data', error);
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'M1 Prep could not be saved',
				});
			});
	}

	sendConversationRequest() {
		const conversationData = {
			company_analysis: this.currentM1Prep.companyAnalysis,
			product_services: this.currentM1Prep.products,
			competitive_landscape: this.currentM1Prep.competitiveLandscape,
		};

		this.http.post<any>(environment.url + '/api/ai/m1prep/conversation', conversationData).subscribe({
			error: (err) => {
				console.log(err);
				this.loadingE = false;
			},
			next: (conversationResponse) => {
				this.currentM1Prep.conversation = {
					introduction: conversationResponse.Introduction || '',
					financial_highlights: conversationResponse.Financial_Highlights || '',
					strategic_priorities: conversationResponse.Strategic_Priorities || '',
					key_initiatives_and_focus_areas: conversationResponse.Key_Initiatives_and_Focus_Areas || '',
					product_portfolio_overview: conversationResponse.Product_Portfolio_Overview || '',
					market_positioning_and_competitors: conversationResponse.Market_Positioning_and_Competitors || '',
					sales_performance_and_growth_opportunities:
						conversationResponse.Sales_Performance_and_Growth_Opportunities || '',
					kpis_to_monitor: conversationResponse.KPIs_to_Monitor || '',
					industry_trends_to_watch: conversationResponse.Industry_Trends_to_Watch || '',
				};
				this.loadingE = false;
				this.hasRun = true;
			},
		});
	}

	exportData() {
		console.log('Exporting data...');
	}

	selectedProductIndex: number = 0;
	selectedProduct: any | undefined = undefined;

	selectProduct(index: number) {
		this.selectedProductIndex = index;
		this.selectedProduct = this.currentM1Prep.products?.products?.length
			? this.currentM1Prep.products?.products[index]
			: undefined;
	}

	selectedServiceIndex: number = 0;
	selectedService: any | undefined = undefined;

	selectService(index: number) {
		this.selectedServiceIndex = index;
		this.selectedService = this.currentM1Prep.products?.services?.length
			? this.currentM1Prep.products?.services[index]
			: undefined;
	}
	loadTable() {
		const renderBox = (data: any, type: any, row: any) => {
			let label = '';
			let className = '';
			if (data === 0) {
				label = '---';
				className = 'background-none';
			} else if (data < 4) {
				label = 'Low';
				className = 'background-low';
			} else if (data >= 4 && data <= 7) {
				label = 'Medium';
				className = 'background-medium';
			} else {
				label = 'High';
				className = 'background-high';
			}
			return `<p class="${className}"> ${label} </p>`;
		};

		this.renderTable(
			() => this.tableUseCases,
			() => ({
				columns: [
					{
						title: 'AI Solution',
						className: 'bold-column',
						data: 'name',
					},
					{
						title: 'Revenue Growth',
						data: 'revenue_growth',
						render: renderBox,
					},
					{
						title: 'Customer Experience',
						data: 'customer_experience',
						render: renderBox,
					},
					{
						title: 'Process Productivity',
						data: 'process_productivity',
						render: renderBox,
					},
					{
						title: 'Employee Productivity',
						data: 'employee_productivity',
						render: renderBox,
					},
					{
						title: 'Cost Savings',
						data: 'cost_savings',
						render: renderBox,
					},
					{
						title: 'Impact',
						data: 'impact_assessment',
					},
				],
				select: true,
				paging: false,
				info: false,
			}),
			() => {
				if (this.recommendedUsesCases && this.recommendedUsesCases.length) {
					return this.recommendedUsesCases.map((d: UseCaseSimulation) => {
						return {
							id: d.id as string,
							name: d.name,
							description: d.description,
							revenue_growth: d.data?.valuesDriversScores?.revenue_growth || 0,
							customer_experience: d.data?.valuesDriversScores?.customer_experience || 0,
							process_productivity: d.data?.valuesDriversScores?.process_productivity || 0,
							employee_productivity: d.data?.valuesDriversScores?.employee_productivity || 0,
							cost_savings: d.data?.valuesDriversScores?.cost_savings || 0,
							impact_assessment: Array.isArray(d.context?.impact) ? d.context.impact.join(',') : '',
						};
					});
				} else {
					return [];
				}
			},
		);
	}

	renderTable(
		element: () => ElementRef<HTMLTableElement>,
		getOptions: () => Config,
		getData: () => {
			id: string;
		}[],
	) {
		setTimeout(() => {
			const data = getData();
			if (data && data.length) {
				const el = element();
				if (el && el.nativeElement) {
					this._renderTable(el.nativeElement, getOptions, getData);
				} else {
					this.renderTable(element, getOptions, getData);
				}
			}
		});
	}

	dTable: Api | undefined = undefined;

	_renderTable(
		table: HTMLTableElement,
		getOptions: () => Config,
		getData: () => {
			id: string;
		}[],
	) {
		const data = getData();
		if (data.length) {
			if (this.dTable) {
				this.dTable.destroy();
			}
			const options = getOptions();
			this.dTable = new DataTable(table, {
				data,
				...options,
				initComplete: () => {
					if (
						table.parentElement &&
						table.parentElement.parentElement &&
						table.parentElement.parentElement.parentElement
					) {
						table.parentElement.parentElement.parentElement.classList.add('table-recommended-use-cases');
					}
				},
			});
		}
	}

	currentM1Prep: M1Prep;

	emptyM1Prep() {
		this.currentM1Prep = new M1Prep(
			{
				info: { name: '' },
				additionalInformation: '',
				industryTreeSelected: [],
				taxonomyTreeSelected: [],
			},
			{},
			{},
			{},
			{},
			[],
			this.organization.id || '',
		);
	}
}
