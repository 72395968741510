import { Component, OnInit, ViewChild } from '@angular/core';
import { UseCaseService } from '../../service/use-case.service';
import { UseCaseUIService } from '../../service/use-case_ui.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { Menu } from 'primeng/menu';
import { ActivatedRoute } from '@angular/router';
import { UseCase } from '../../api/use-case.api';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../service/api.service';

@Component({
	selector: 'app-use-cases',
	templateUrl: './use-cases.component.html',
	styleUrls: ['./use-cases.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class UseCasesComponent implements OnInit {
	@ViewChild('menu') menu: Menu;

	constructor(
		public http: HttpClient,
		public route: ActivatedRoute,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public layoutService: LayoutService,
		public useCaseService: UseCaseService,
		public useCaseUIService: UseCaseUIService,
		private apiService: ApiService,
	) {}

	async ngOnInit() {
		this.layoutService.updateTitle('AI Solutions');

		this.useCaseUIService.messageService = this.messageService;
		this.useCaseUIService.confirmationService = this.confirmationService;

		await this.useCaseUIService.onLoad();

		this.route.queryParams.subscribe((params) => {
			if (params['id'] && params['assessments']) {
				this.useCaseUIService.loadUseCaseAssessment(params['id']);
			} else if (params['id']) {
				this.useCaseUIService.loadUseCaseDiagram(params['id'], params['back']);
			}
		});

		this.menu.onShow.subscribe({
			next: () => {
				this.useCaseUIService.activatedMenu = true;
			},
		});

		this.menu.onHide.subscribe({
			next: () => {
				this.useCaseUIService.activatedMenu = false;
			},
		});
	}

	selectAll(event: Event) {
		const inputElement = event.target as HTMLInputElement;
		inputElement.select();
	}

	protected readonly Array = Array;

	draggedUseCaseHeatmap: UseCase | undefined = undefined;

	dragStartHeatmap(useCase: UseCase) {
		this.draggedUseCaseHeatmap = useCase;
	}

	async dropHeatmap(evt: any, i: number) {
		if (this.draggedUseCaseHeatmap) {
			this.useCaseUIService.dataHeatmap = this.useCaseUIService.dataHeatmap.filter(
				(d) => d.id !== this.draggedUseCaseHeatmap?.id,
			);
			if (this.useCaseUIService.dataHeatmap.length - 1 === i) {
				this.useCaseUIService.dataHeatmap.push(this.draggedUseCaseHeatmap);
			} else {
				this.useCaseUIService.dataHeatmap.splice(i, 0, this.draggedUseCaseHeatmap);
			}
			this.useCaseUIService.dataHeatmap = this.useCaseUIService.dataHeatmap.map((d, j) => {
				d.benefitsOrder.heatmap = j;
				return d;
			});
			this.useCaseUIService.dataHeatmap = this.useCaseUIService.dataHeatmap.sort(
				(a, b) => a.benefitsOrder.heatmap - b.benefitsOrder.heatmap,
			);
			const data = this.useCaseUIService.dataHeatmap.map((d) => ({ sort: d.benefitsOrder.heatmap, id: d.id }));
			this.draggedUseCaseHeatmap = undefined;
			await firstValueFrom(this.http.post('@api/use_case/use_case_sort/?key=heatmap', data));
			this.useCaseService.dbService.canHooks = false;
			await this.useCaseService.dbService.data_use_case.bulkPut(this.useCaseUIService.dataHeatmap);
			this.useCaseService.dbService.canHooks = true;
		}
	}

	draggedUseCase: UseCase | undefined = undefined;

	dragStart(useCase: UseCase) {
		this.draggedUseCase = useCase;
	}

	async drop(evt: any, i: number) {
		if (this.draggedUseCase) {
			this.useCaseUIService.searchTerm = this.useCaseUIService.searchTerm + ' ';
			this.useCaseUIService.data = this.useCaseUIService.data.filter((d) => d.id !== this.draggedUseCase?.id);
			if (this.useCaseUIService.data.length - 1 === i) {
				this.useCaseUIService.data.push(this.draggedUseCase);
			} else {
				this.useCaseUIService.data.splice(i, 0, this.draggedUseCase);
			}
			this.useCaseUIService.data = this.useCaseUIService.data.map((d, j) => {
				d.benefitsOrder.benefits = j;
				return d;
			});
			this.useCaseUIService.data = this.useCaseUIService.data.sort(
				(a, b) => a.benefitsOrder.benefits - b.benefitsOrder.benefits,
			);
			const data = this.useCaseUIService.data.map((d) => ({ sort: d.benefitsOrder.benefits, id: d.id }));
			this.draggedUseCase = undefined;
			await firstValueFrom(this.http.post('@api/use_case/use_case_sort/?key=benefits', data));
			this.useCaseService.dbService.canHooks = false;
			await this.useCaseService.dbService.data_use_case.bulkPut(this.useCaseUIService.data);
			this.useCaseService.dbService.canHooks = true;
			this.useCaseUIService.searchTerm = this.useCaseUIService.searchTerm.substring(0, this.useCaseUIService.searchTerm.length - 1);
			this.useCaseUIService.onSearchAiSolutionChange(this.useCaseUIService.searchTermBenefits);
			// console.log('Updated data:', this.useCaseUIService.data)
		}
	}

	runAgentBenefits() {
		this.apiService.getAssistant(
			'assistant_id',
			'prompt',
			(res) => {
				console.log(res);
			},
			(err) => {
				console.log(err);
			},
		);
	}
}
