import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { ConfirmationService, MenuItem, MessageService, TreeNode } from 'primeng/api';
import { AiExplorerService } from './ai-explorer.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { EventService } from '../../service/event.service';
import { ApiService } from '../../service/api.service';
import { LoadingService } from '../common/loading/loading.service';
import {
	AISolutionModelObjectType,
} from '../../service/graphql';

@Component({
	templateUrl: './ai-explorer.component.html',
	styleUrls: ['./ai-explorer.component.scss'],
})
export class AiExplorerComponent implements OnInit {
	@ViewChild('scrollList') private scrollList: ElementRef;
	@ViewChild('scrollGrid') private scrollGrid: ElementRef;
	selectedCard: { id: string; label: string; values: number[]; aiSolutionsEvaluations: { Breakthrough: string[]; Transformative: string[]; Incremental: string[]; }; };
	messageService: MessageService;
	constructor(
		public cdr: ChangeDetectorRef,
		public layoutService: LayoutService,
		private apiService: ApiService,
		public aiExplorer: AiExplorerService,
		private eventService: EventService,
		private loading: LoadingService,
	) {}

	// Side Filter

	showSide = true;
	showSideExtend = true;
	containerWidth = 0;
	openMenu = false;
	searchQuery: string = '';

	aiSolutionItemModal: boolean = false;

	diagramAiSolutions: AISolutionModelObjectType[] = [];
	currentAISolution: any;
	async goToAISolutionItem(solutionId: string) {
			this.currentAISolution = undefined;
			this.loading.show();
			try {
				const response = await this.apiService.client.query({
					aiSolution: {
						get: {
							...this.apiService.idArgs(solutionId),
							id: true,
							name: true,
							description: true,
							agentCollaboration: true,
							stepsInvolved: true,
							aiOpportunitiesUsed: true,
							valueDriversScores: true,
							impactAssessment: true,
							potentialChallenges: true,
						},
					},
				});
	
				const aiSolution = response.aiSolution?.get as any;
				aiSolution.allDataRequirements = aiSolution.aiOpportunitiesUsed
					.map((item: any) => {
						const { data_requirements } = item;
						return data_requirements.join(', ');
					})
					.join(', ');
				const allTopBenefits = Object.entries(aiSolution.valueDriversScores).map(([title, value]) => ({
					title: this.valuesDriversScoresTitle(title),
					label: this.valuesDriversScoresLabel(value as number),
					className: this.valuesDriversScoresClassName(value as number),
				}));
	
				aiSolution.allTopBenefits = this.valuesDriversScoreSort(allTopBenefits);
	
				if (aiSolution.agentCollaboration.length) {
					const hasInvalidAgent = aiSolution.agentCollaboration.some((collaborationItem: any) =>
						collaborationItem.agents.some((agent: any) => !agent || !agent.agent_id || !agent.agent_name),
					);
	
					if (hasInvalidAgent) {
						this.messageService.add({
							severity: 'warn',
							summary: `Invalid agents for AI Solution`,
							detail: `The AI Solution contains invalid agent data. Please ensure all agents are properly defined.`,
						});
						return;
					}
	
					aiSolution.allAgents = {
						description: aiSolution.agentCollaboration
							.map((collaborationItem: any) => collaborationItem.collaboration)
							.join(' '),
						agents: aiSolution.agentCollaboration
							.flatMap((collaborationItem: any) => collaborationItem.agents)
							.map((agent: any) => {
								const matchingOpportunity = aiSolution.aiOpportunitiesUsed.find(
									(opportunity: any) => opportunity.ai_opportunity_id === agent.agent_id,
								);
	
								const isMandatory = matchingOpportunity && matchingOpportunity.must_have === 'MUST HAVE';
	
								return {
									label: agent.agent_name,
									mustHave: matchingOpportunity.must_have,
									mustHaveLabel: isMandatory ? 'Essential' : 'Optional',
									className: isMandatory ? 'yellow' : 'blue',
								};
							}),
					};
				}
	
				this.currentAISolution = aiSolution;
			} finally {
				this.loading.hide();
			}
			this.aiSolutionItemModal = true;
		}

    closeAiSolutionModal(): void {
        this.aiSolutionItemModal = false;
        this.currentAISolution = null;
    }

	filterSearch() {
		// console.log('filterSearch', this.searchQuery);
		this.aiExplorer.filterTreeNodes(this.searchQuery);
		this.cdr.detectChanges();
	}

	toggleSide() {
		this.showSide = !this.showSide;
	}

	openSide() {
		if (this.showSideExtend && !this.showSide) {
			this.showSide = true;
		}
	}

	sizeSide() {
		const container: HTMLDivElement | null = document.querySelector('#page-taxonomy');
		if (container) {
			const containerWidth = container.offsetWidth;

			if (containerWidth !== this.containerWidth) {
				this.containerWidth = containerWidth;
				if (containerWidth < 990 && this.layoutService.isDesktop() && this.openMenu) {
					this.layoutService.onMenuClose();
					this.openMenu = true;
				}
				if (containerWidth < 880 && this.showSide) {
					this.showSide = false;
					this.showSideExtend = false;
				}

				/*if (containerWidth >= 990 && this.layoutService.isDesktop() && !this.openMenu && this.showSide) {
					this.layoutService.onMenuOpen();
					this.openMenu = false;
				}*/
				if (containerWidth >= 880 && !this.showSideExtend) {
					this.showSide = true;
					this.showSideExtend = true;
				}
			}
		}
	}

	// Content

	activeFilter: boolean = true;

	async ngOnInit() {
		this.layoutService.updateTitle('Taxonomy Explorer');

		this.aiExplorer.onUpdate = () => {
			this.cdr.detectChanges();
		};

		this.aiExplorer.scrollList = this.scrollList;
		this.aiExplorer.scrollGrid = this.scrollGrid;

		this.eventService.getEventSimple('reloadTaxonomyData').subscribe(() => {
			this.aiExplorer.prepareData();
		});

		window.addEventListener('resize', () => this.sizeSide());

		this.sizeSide();
	}

	filterTab: number = 0;

	filterTabOptions: { value: number }[] = [{ value: 0 }, { value: 1 }];

	@ViewChild('popover') popover!: OverlayPanel;

	togglePopover(event: Event, card: any) {
		if (this.popover && this.popover.overlayVisible) {
			this.popover.hide();
			setTimeout(() => {
				this.openPopover(event, card);
			}, 50);
		} else {
			this.openPopover(event, card);
		}
	}
	openPopover(event: Event, card: any) {
		this.selectedCard = { ...card } as {
			id: string;
			label: string;
			values: number[];
			aiSolutionsEvaluations: { Breakthrough: string[]; Transformative: string[]; Incremental: string[] };
		};
	
		setTimeout(() => {
			this.popover.show(event);
			this.cdr.detectChanges();
		}, 50);
	}
	
	getAiSolutionsByType(card: any, type: string): { id: string; name: string }[] { 
		// console.log('card', card);
		// console.log('type', type);
		// console.log('aiSolutionsEvaluations', card?.aiSolutionsEvaluations);
	
		if (!card || !card.aiSolutionsEvaluations) {
			return [];
		}
	
		if (!(type in card.aiSolutionsEvaluations)) {
			// console.error(`Type '${type}' not found in aiSolutionsEvaluations`, card.aiSolutionsEvaluations);
			return [];
		}

		return [...card.aiSolutionsEvaluations[type]];
	}

	openAiSolutionModal(solutionId: string): void {
		this.goToAISolutionItem(solutionId);
	}

	hasAiSolutions(card: any): boolean {
		if (!card || !card.aiSolutionsEvaluations) return false;
	
		return (
			card.aiSolutionsEvaluations.Breakthrough.length > 0 ||
			card.aiSolutionsEvaluations.Transformative.length > 0 ||
			card.aiSolutionsEvaluations.Incremental.length > 0
		);
	}
	valuesDriversScoresLabel(value: number = 0) {
		let label = '';
		if (value === 0) {
			label = '---';
		} else if (value < 4) {
			label = 'Low';
		} else if (value >= 4 && value <= 7) {
			label = 'Medium';
		} else {
			label = 'High';
		}
		return label;
	}

	valuesDriversScoresClassName(value: number = 0) {
		let className = '';
		if (value === 0) {
			className = 'background-none';
		} else if (value < 4) {
			className = 'background-low';
		} else if (value >= 4 && value <= 7) {
			className = 'background-medium';
		} else {
			className = 'background-high';
		}
		return className;
	}

	valuesDriversScoresTitle(key: string = '') {
		const labels: any = {
			revenue_growth: 'Revenue Growth',
			customer_experience: 'Customer Experience',
			process_productivity: 'Process Productivity',
			employee_productivity: 'Employee Productivity',
			cost_savings: 'Cost Savings',
		};

		return labels[key] || 'Unknown Category';
	}

	valuesDriversScoreSort(allTopBenefits: any[]) {
		const customOrder = [
			'Revenue Growth',
			'Customer Experience',
			'Process Productivity',
			'Employee Productivity',
			'Cost Savings',
		];

		return allTopBenefits.sort((a, b) => {
			const indexA = customOrder.indexOf(a.title);
			const indexB = customOrder.indexOf(b.title);

			if (indexA === -1) return 1;
			if (indexB === -1) return -1;

			return indexA - indexB;
		});
	}
}
