<div class="section-container mb-4" [formGroup]="form">
	<div class="flex justify-content-between align-items-center">
		<ng-template [ngIf]="canEditTitle" [ngIfElse]="viewMode">
			<input class="w-full border-none bg-transparent font-bold"
				   pInputText
				   formControlName="title"
				   (blur)="toggleEditTitle()"
				   (keydown.enter)="toggleEditTitle()" />
		</ng-template>
		<ng-template #viewMode>
			<span class="font-bold" (dblclick)="toggleEditTitle()">
				{{ title }}
			</span>
		</ng-template>
		<div class="flex gap-2">
			<button
				class="p-button p-button-icon-only p-button-link toggle-parent-row"
				type="button"
				(click)="openAttachmentModal()"
			>
				<span
					class="pi pi-paperclip"
					[class.text-gray-900]="!form.get('attachments')?.value?.length"
					[class.text-primary]="form.get('attachments')?.value?.length"
					tippy
					[tippyContent]="'Attach file'"
					tippyPlacement="bottom"
				></span>
			</button>
			<button
				*ngIf="!form.get('required')?.value"
				class="p-button p-button-icon-only p-button-link toggle-parent-row text-primary"
				type="button"
				(click)="handleRemoveSection()"
				tippy
				[tippyContent]="'Remove Section'"
				tippyPlacement="bottom"
			>
				<span class="pi pi-trash"></span>
			</button>
		</div>
	</div>
	<div class="data-container"> 
		<ng-container formArrayName="items">
			<div *ngFor="let field of items.controls; let i = index" class="p-field mb-2">
				{{ field.get('title')?.value }}
				<div class="flex items-center" [formGroupName]="i">
					<p-editor
						class="w-full flex-grow"
						placeholder="Enter {{ title }} Field"
						formControlName="text"
					></p-editor>
					<div class="flex items-center align-items-center text-center ml-2 gap-1">
						<button
							pButton
							icon="pi pi-plus"
							class="p-button-rounded p-button-sm p-button-outlined"
							style="height: 1.5rem; width: 1.5rem; padding: 0;"
							(click)="addItem()"
							tippy
							[tippyContent]="'Add Field'"
							tippyPlacement="bottom"
						></button>
						<button
							pButton
							icon="pi pi-minus"
							class="p-button-rounded p-button-sm p-button-outlined"
							style="height: 1.5rem; width: 1.5rem; padding: 0;"
							(click)="removeItem(i)"
							[disabled]="items.length === 1"
							tippy
							[tippyContent]="items.length === 1 ? '' : 'Remove Field'"
							tippyPlacement="bottom"
						></button>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
