import { Component, OnDestroy, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { LayoutService } from './service/app.layout.service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AuthService } from '../rtp/service/auth.service';
import { DbService } from '../rtp/service/db.service';
import { MessageService } from 'primeng/api';
import { DotLottie } from '@lottiefiles/dotlottie-web';
import { EventService } from '../rtp/service/event.service';
import { WebSocketAPI } from '../rtp/api/websocket.api';

@Component({
	selector: 'app-layout',
	templateUrl: './app.layout.component.html',
	providers: [MessageService],
})
export class AppLayoutComponent implements OnDestroy, AfterViewInit {
	overlayMenuOpenSubscription: Subscription;

	menuOutsideClickListener: any;

	profileMenuOutsideClickListener: any;

	@ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

	@ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;

	init = false;
	renderLayout = false;
	progress = 0;

	constructor(
		public layoutService: LayoutService,
		public renderer: Renderer2,
		public authService: AuthService,
		public router: Router,
		public dbService: DbService,
		public messageService: MessageService,
		private eventService: EventService,
		private webSocketAPI: WebSocketAPI,
	) {
		this.authService.getUser().then(async (user) => {
			if (user) {
				if (user.change_password) {
					await this.router.navigateByUrl('/change-password');
				} else {
					this.prepare();
					this.startup();
				}
			} else {
				await this.router.navigateByUrl('/sign-in');
			}
		});

		this.webSocketAPI.dbMessageToastSource$.subscribe((value) => {
			if (this.layoutService.warnings) {
				this.messageService.add({
					severity: 'error',
					...value,
				});
			}
		});
	}

	ngAfterViewInit() {
		this.webSocketAPI.run();
		const canvas = document.querySelector<HTMLCanvasElement>('#dotlottie-canvas');
		if (canvas) {
			new DotLottie({
				autoplay: true,
				loop: true,
				canvas: canvas,
				src: 'assets/icons/loaderIcon.json',
			});
		}
	}

	startup() {
		let step = 75;
		const interval_progress = () => {
			const interval_id = setTimeout(() => {
				let top = 10;
				if (this.progress < 40) {
					top = 10;
				} else if (this.progress < 60) {
					top = 5;
				} else if (this.progress < 80) {
					top = 3;
				} else if (this.progress < 99) {
					top = 2;
				} else {
					top = 0;
				}
				if (top) {
					this.progress += Math.ceil(Math.random() * top);
				}
				if (this.progress < 99) {
					step = step + 50;
					clearTimeout(interval_id);
					interval_progress();
				}
			}, step);
		};
		interval_progress();

		this.eventService.getStartup().subscribe(() => {
			setTimeout(() => {
				this.init = true;
				setTimeout(() => {
					this.renderLayout = true;
					this.progress = 0;
				}, 150);
			}, 150);
		});

		this.dbService.dbErrorSource$.subscribe(() => {
			if (this.dbService.dbError) {
				this.authService.signOut().then(async () => {
					await this.router.navigateByUrl('/sign-in');
				});
			}
		});
	}

	prepare() {
		this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe(() => {
			if (!this.menuOutsideClickListener) {
				this.menuOutsideClickListener = this.renderer.listen('document', 'click', (event) => {
					const isOutsideClicked = !(
						this.appSidebar.el.nativeElement.isSameNode(event.target) ||
						this.appSidebar.el.nativeElement.contains(event.target) ||
						this.appTopbar.menuButton.nativeElement.isSameNode(event.target) ||
						this.appTopbar.menuButton.nativeElement.contains(event.target)
					);

					if (isOutsideClicked) {
						this.hideMenu();
					}
				});
			}

			if (this.layoutService.state.staticMenuMobileActive) {
				this.blockBodyScroll();
			}
		});

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
			this.hideMenu();
			this.hideProfileMenu();
		});
	}

	hideMenu() {
		this.layoutService.state.overlayMenuActive = false;
		this.layoutService.state.staticMenuMobileActive = false;
		this.layoutService.state.menuHoverActive = false;
		if (this.menuOutsideClickListener) {
			this.menuOutsideClickListener();
			this.menuOutsideClickListener = null;
		}
		this.unblockBodyScroll();
	}

	hideProfileMenu() {
		this.layoutService.state.profileSidebarVisible = false;
		if (this.profileMenuOutsideClickListener) {
			this.profileMenuOutsideClickListener();
			this.profileMenuOutsideClickListener = null;
		}
	}

	blockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.add('blocked-scroll');
		} else {
			document.body.className += ' blocked-scroll';
		}
	}

	unblockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.remove('blocked-scroll');
		} else {
			document.body.className = document.body.className.replace(
				new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
				' ',
			);
		}
	}

	get containerClass() {
		return {
			'layout-theme-light': this.layoutService.config.colorScheme === 'light',
			'layout-theme-dark': this.layoutService.config.colorScheme === 'dark',
			'layout-overlay': this.layoutService.config.menuMode === 'overlay',
			'layout-static': this.layoutService.config.menuMode === 'static',
			'layout-static-inactive':
				this.layoutService.state.staticMenuDesktopInactive && this.layoutService.config.menuMode === 'static',
			'layout-overlay-active': this.layoutService.state.overlayMenuActive,
			'layout-mobile-active': this.layoutService.state.staticMenuMobileActive,
			'p-input-filled': this.layoutService.config.inputStyle === 'filled',
			'p-ripple-disabled': !this.layoutService.config.ripple,
		};
	}

	ngOnDestroy() {
		if (this.overlayMenuOpenSubscription) {
			this.overlayMenuOpenSubscription.unsubscribe();
		}

		if (this.menuOutsideClickListener) {
			this.menuOutsideClickListener();
		}
	}
}
