<p-toast position="top-center"></p-toast>
<p-confirmDialog class="diagram-confirm-dialog" [style]="{ width: '50vw' }"></p-confirmDialog>

<app-blockable-div #diagramModal class="diagram-modal">
	<p-messages [(value)]="messages" [enableService]="false"></p-messages>
	<ng-template [ngIf]="ready">
		<div class="actions actions-box actions-1">
			<div class="flex align-items-start justify-content-start gap-2">
				<div class="icon-diagram">
					<span class="pi pi-sitemap p-button-icon text-primary"></span>
				</div>
				<span class="mb-0 mt-2 pl-2 font-bold text-primary diagram-tile">
					{{ textPath }} /
					<span class="text-bluegray-700 font-bold white-space-nowrap overflow-hidden text-overflow-ellipsis">
						{{ diagramMetaData?.name || 'Unknown' }}
					</span>
				</span>
				<p-button
					icon="pi pi-pencil"
					styleClass="p-button-rounded p-button-secondary p-button-text"
					(click)="openDiagramMetaDataModal()"
					[disabled]="blockedSaved"
					tippy
					[tippyContent]="blockedSaved ? '' : 'Edit Diagram Info'"
					tippyPlacement="bottom"
				>
				</p-button>
			</div>
		</div>
		<div class="actions actions-box actions-2">
			<div class="flex align-items-end justify-content-end gap-2">
				<p-button
					*ngIf="hasNewUseCase && !blockedDiagram"
					[disabled]="blockedSaved"
					label="Add to My AI Solution"
					icon="pi pi-check-square"
					(onClick)="newUseCase()"
				></p-button>
				<p-button
					*ngIf="showAssessments && !blockedDiagram"
					[disabled]="blockedSaved"
					label="Go to Assessments"
					icon="pi pi-folder-open"
					(onClick)="goToAssessments()"
				></p-button>
				<p-button
					*ngIf="can_edit"
					label="Save"
					icon="pi pi-save"
					(onClick)="saveDiagram()"
					[loading]="blockedDiagram || blockedSaved"
				></p-button>
				<p-button
					styleClass="p-button-text p-button-secondary"
					icon="pi pi-times"
					(onClick)="checkCloseDiagram()"
					tippy
					[tippyContent]="'Close Diagram'"
					tippyPlacement="bottom"
				></p-button>
			</div>
		</div>
		<div *ngIf="!blockedDiagram" class="actions actions-box actions-3" [ngClass]="action3Class">
			<div class="flex align-items-start justify-content-start gap-2">
				<div
					*ngIf="xmlForCanvasAfter"
					class="flex align-items-center justify-content-center gap-2"
					style="padding: 3px"
				>
					<span class="font-bold" [style.color]="!isFutureState ? '#212529' : '#aaa'">Original</span>
					<p-inputSwitch
						[(ngModel)]="isFutureState"
						styleClass="button-switch"
						(onChange)="toggleDiagramState()"
					>
					</p-inputSwitch>
					<span class="font-bold" [style.color]="isFutureState ? '#212529' : '#aaa'">Future State</span>
				</div>

				<p-button
					*ngIf="xmlForCanvasAfter"
					styleClass="p-button-secondary p-button-text button-action btnDiff"
					tippy
					tippyContent="Diff diagram"
					tippyPlacement="bottom"
					(onClick)="diffDiagram()"
				>
					<ng-template pTemplate="icon">
						<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_105_1836)">
								<path
									d="M13 3.99976H6C4.89543 3.99976 4 4.89519 4 5.99976V17.9998C4 19.1043 4.89543 19.9998 6 19.9998H13M17 3.99976H18C19.1046 3.99976 20 4.89519 20 5.99976V6.99976M20 16.9998V17.9998C20 19.1043 19.1046 19.9998 18 19.9998H17M20 10.9998V12.9998M12 1.99976V21.9998"
									stroke="#292929"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</g>

							<defs>
								<clipPath id="clip0_105_1836">
									<rect fill="white" height="24" transform="translate(0 -0.000244141)" width="24" />
								</clipPath>
							</defs>
						</svg>
					</ng-template>
				</p-button>

				<p-button
					*ngIf="useCasesEnabled"
					styleClass="p-button-secondary p-button-text button-action btnAI"
					(onClick)="openUseCaseLibraryModal()"
					tippy
					tippyContent="AI Solution Library"
					tippyPlacement="bottom"
					[disabled]="blockedSaved"
				>
					<ng-template pTemplate="icon">
						<svg
							*ngIf="!useCasesLoading"
							width="19"
							height="20"
							viewBox="0 0 19 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0_3650_2953)">
								<path
									d="M11.4405 5.18509L13.1208 9.4945C13.2 9.69617 13.3582 9.85539 13.5587 9.93499L17.8427 11.6253C18.4969 11.8827 18.4969 12.8141 17.8427 13.0715L13.5587 14.7618C13.3582 14.8415 13.2 15.0007 13.1208 15.2023L11.4405 19.5117C11.1846 20.1698 10.2587 20.1698 10.0029 19.5117L8.32252 15.2023C8.24338 15.0007 8.08511 14.8415 7.88463 14.7618L3.60069 13.0715C2.9465 12.8141 2.9465 11.8827 3.60069 11.6253L7.88463 9.93499C8.08511 9.85539 8.24338 9.69617 8.32252 9.4945L10.0029 5.18509C10.2587 4.52701 11.1846 4.52701 11.4405 5.18509Z"
									class="icon"
								/>
								<path
									d="M3.94102 2.85791L4.73502 4.89851C4.77195 4.99404 4.84581 5.06834 4.94078 5.10549L6.96932 5.90422C7.27795 6.02628 7.27795 6.46677 6.96932 6.58884L4.94078 7.38757C4.84581 7.42472 4.77195 7.49902 4.73502 7.59454L3.94102 9.63514C3.81968 9.94561 3.38179 9.94561 3.26044 9.63514L2.46644 7.59454C2.42951 7.49902 2.35565 7.42472 2.26068 7.38757L0.232146 6.58884C-0.0764869 6.46677 -0.0764869 6.02628 0.232146 5.90422L2.26068 5.10549C2.35565 5.06834 2.42951 4.99404 2.46644 4.89851L3.26044 2.85791C3.38179 2.54744 3.81968 2.54744 3.94102 2.85791Z"
									class="icon"
								/>
								<path
									d="M8.91868 0.12471L9.34338 1.21268C9.36449 1.26309 9.40406 1.3029 9.45418 1.32413L10.5357 1.75135C10.7019 1.81769 10.7019 2.05121 10.5357 2.11755L9.45418 2.54477C9.40406 2.566 9.36449 2.6058 9.34338 2.65622L8.91868 3.74419C8.85274 3.91136 8.6206 3.91136 8.55466 3.74419L8.12995 2.65622C8.10885 2.6058 8.06928 2.566 8.01916 2.54477L6.93763 2.11755C6.77144 2.05121 6.77144 1.81769 6.93763 1.75135L8.01916 1.32413C8.06928 1.3029 8.10885 1.26309 8.12995 1.21268L8.55466 0.12471C8.6206 -0.0424652 8.85274 -0.0424652 8.91868 0.12471Z"
									class="icon"
								/>
							</g>
							<defs>
								<clipPath id="clip0_3650_2953">
									<rect width="18.3333" height="20" fill="white" />
								</clipPath>
							</defs>
						</svg>
						<i *ngIf="useCasesLoading" class="pi pi-spin pi-spinner"></i>
					</ng-template>
				</p-button>

				<p-button
					styleClass="p-button-secondary p-button-text button-action"
					icon="pi pi-building"
					tippy
					tippyContent="Enterprise Context"
					tippyPlacement="bottom"
					(click)="simulationService.enterpriseContext ? openEnterpriseContext() : null"
					[loading]="!this.simulationService.enterpriseContext"
					[disabled]="!this.simulationService.enterpriseContext || blockedSaved"
				></p-button>

				<p-button
					[icon]="showAllTippy ? 'pi pi-eye-slash' : 'pi pi-eye'"
					styleClass="p-button-secondary p-button-text button-action"
					(onClick)="toggleAllTippy()"
					tippy
					tippyPlacement="bottom"
					[tippyContent]="blockedSaved ? '' : (showAllTippy ? 'Hide AI Enablers' : 'Show AI Enablers')"
					[disabled]="blockedSaved"
				></p-button>

				<p-button
					icon="pi pi-bars"
					styleClass="p-button-secondary p-button-text button-action"
					(onClick)="menu.toggle($event)"
					[disabled]="blockedSaved"
					tippy
					tippyContent="Options"
					tippyPlacement="bottom"
				></p-button>
				<p-tieredMenu #menu [model]="menuBarItems" [popup]="true" appendTo="body"></p-tieredMenu>
			</div>
		</div>
		<div class="actions actions-box actions-4" [ngClass]="action4Class">
			<div class="flex justify-content-between align-items-center pt-2 pl-3">
				<p class="text-black-alpha-90 text-xl font-bold my-auto">Hackett AI Hubble</p>

				<p-button
					icon="pi pi-times"
					styleClass="p-button-rounded p-button-text"
					(onClick)="closeStepInfo()"
					tippy
					[tippyContent]="'Close AI Hubble'"
					tippyPlacement="bottom"
				></p-button>
			</div>
			<div *ngIf="shapeData" class="content">
				<p-tabView [(activeIndex)]="activeIndexHubble">
					<p-tabPanel header="AI Hubble">
						<div class="flex flex-column flex-wrap gap-3">
							<div class="flex flex-column gap-2 w-full">
								<label for="name">Name</label>
								<textarea
									pInputTextarea
									id="name"
									placeholder="Enter name"
									aria-describedby="name-help"
									[disabled]="!can_edit || !!shapeData.taxonomyRel"
									[(ngModel)]="shapeData.name"
									(change)="shapeDataNameUpdate = true"
									rows="1"
									[autoResize]="true">
								</textarea>
							</div>
							<div class="flex flex-column gap-2 w-full">
								<label for="description">Description</label>
								<textarea
									rows="10"
									cols="30"
									placeholder="Enter description"
									pInputTextarea
									id="description"
									aria-describedby="description-help"
									[disabled]="!can_edit || !!shapeData.taxonomyRel"
									[(ngModel)]="shapeData.description"
								></textarea>
							</div>

							<div class="flex flex-column gap-3 w-full mt-4">
								<div class="flex justify-content-between align-items-center">
									<label>AI Enablers</label>
								</div>

								<div class="flex gap-2 flex-wrap mt-4">
									<ng-template [ngIf]="shapeData.technologiesIds && shapeData.technologiesIds.length">
										<p-chip
											*ngFor="let technologyId of shapeData.technologiesIds"
											[label]="getTechnology(technologyId)?.name || '---'"
											class="text-sm"
										></p-chip>
									</ng-template>
									<ng-template
										[ngIf]="!(shapeData.technologiesIds && shapeData.technologiesIds.length)"
									>
										---
									</ng-template>
								</div>
							</div>

							<ng-template
								[ngIf]="
									shapeAIOpportunity && shapeData.technologiesIds && shapeData.technologiesIds.length
								"
							>
								<div class="flex flex-column gap-2 w-full">
									<div class="flex justify-content-end align-items-center gap-3">
										<p-button
											id="ai-opportunities"
											label="Discover AI Opportunities"
											(onClick)="activeIndexHubble = 1"
										></p-button>
									</div>
								</div>
							</ng-template>
						</div>
					</p-tabPanel>
					<p-tabPanel header="Opportunities">
						<div class="flex flex-column flex-wrap gap-3 w-full" style="overflow-x: auto">
							<div class="flex justify-content-between align-items-center gap-3 w-full">
								<p-multiSelect display="chip" class="w-full" styleClass="w-full"></p-multiSelect>
								<div class="flex justify-content-end align-items-center gap-3 w-fit">
									<p-button
										*ngIf="can_edit"
										icon="pi pi-sitemap"
										styleClass="button-action btnGenAI"
										class="p-0"
										(onClick)="sendOpportunities()"
										[loading]="sendAIOpportunitiesLoading"
										tippy
										tippyContent="Send Opportunities to AI"
										tippyPlacement="bottom"
									></p-button>
									<p-button
										*ngIf="diagramEditOpportunity && can_edit"
										(onClick)="createOpportunity()"
										icon="pi pi-plus"
										styleClass="p-button-text p-button-plain"
										class="p-0"
									></p-button>
									<p-button
										*ngIf="diagramEditOpportunity && can_edit"
										icon="pi pi-sparkles"
										styleClass="p-button-text p-button-plain"
										class="p-0"
										[loading]="generateAIOpportunitiesLoading"
										(onClick)="generateAIOpportunities()"
									></p-button>
								</div>
							</div>

							<div class="flex flex-column flex-wrap gap-3 w-full">
								<ng-template ngFor let-opportunity [ngForOf]="currentAIOpportunities || []">
									<div
										class="flex flex-column pt-1 gap-2 opportunity-item p-2 w-full"
										[ngClass]="embeddedAIOpportunityId === opportunity.id ? 'active' : ''"
										style="min-width: 300px"
									>
										<div class="flex justify-content-start w-full gap-3">
											<p-checkbox
												[binary]="true"
												styleClass="button-action cbGenAI"
												[(ngModel)]="opportunity.checked"
											></p-checkbox>
											<span class="font-bold pl-0 w-full">{{ opportunity.name }}</span>
										</div>
										<span class="pl-0 w-full">{{ opportunity.description }}</span>

										<div
											*ngIf="opportunity.data?.knowledgeData?.length"
											class="flex flex-column justify-content-center align-items-start gap-3 border-round border-1 border-100 border-solid p-2 my-3 w-full"
										>
											<div
												*ngFor="let knowledgeData of opportunity.data?.knowledgeData"
												class="flex justify-content-start gap-2 align-items-center"
											>
												<span class="pi pi-database"></span>
												<span>{{ knowledgeData }}</span>
											</div>
										</div>

										<div class="flex justify-content-between w-full gap-3">
											<div
												*ngIf="
													can_edit &&
													opportunity.technologies?.length &&
													getTechnology(opportunity.technologies[0])
												"
												class="flex justify-content-between align-items-center gap-2 border-round border-1 border-100 border-solid p-2"
												style="min-width: 150px"
											>
												<span class="pi pi-sparkles text-primary p-button-icon"></span>
												<span class="w-full">{{
													getTechnology(opportunity.technologies[0])?.name || ''
												}}</span>
											</div>

											<div class="row justify-content-end gap-2 w-full mr-1">
												<p-button
													(onClick)="toggleEmbeddedAI(opportunity)"
													[disabled]="embeddedAILoading"
													styleClass="p-button-text p-button-plain"
													tippy
													[tippyContent]="'Generate Embedded AI'"
													tippyPlacement="top"
												>
													<ng-template pTemplate="icon">
														<app-embedded-icon
															*ngIf="!embeddedAILoading"
															[active]="embeddedAIOpportunityId === opportunity.id"
														></app-embedded-icon>
														<i
															*ngIf="
																embeddedAILoading &&
																embeddedAIOpportunityId === opportunity.id
															"
															class="pi pi-spin pi-spinner"
														></i>
													</ng-template>
												</p-button>
												<!--<span
													*ngIf="opportunity.data.type !== 'Embedded'"
													class="p-element p-button-text p-button-plain p-component p-button-icon-only flex justify-content-center align-items-center m-0 vertical-align-bottom w-2rem"
												>
													<app-zbrain-icon></app-zbrain-icon>
												</span>-->
												<span
													class="p-element p-button-text p-button-plain p-component p-button-icon-only flex justify-content-center align-items-center m-0 vertical-align-bottom"
													tippy
													[tippyContent]="
													opportunity.data.complexity === 1
														? 'Low'
														: opportunity.data.complexity === 2
														? 'Medium'
														: opportunity.data.complexity === 3
														? 'High'
														: 'Unknown'
												"
													tippyPlacement="top"
												>
													<app-complexity-icon
														class="p-button-icon"
														[complexity]="opportunity.data.complexity"
													></app-complexity-icon>
												</span>
												<span
													*ngIf="opportunity.data.mustHave"
													class="p-element p-button-text p-button-plain p-component p-button-icon-only flex justify-content-center align-items-center m-0 vertical-align-bottom"
													tippy
													[tippyContent]="
													opportunity.data.mustHave === 'MUST HAVE'
														? 'Essential'
														: 'Optional'
												"
													tippyPlacement="top"
												>
													<app-must-have-icon
														class="p-button-icon"
														[value]="opportunity.data.mustHave"
													></app-must-have-icon>
												</span>
												<p-button
													*ngIf="diagramEditOpportunity && can_edit"
													(onClick)="editOpportunity(opportunity)"
													[icon]="'pi pi-pencil'"
													styleClass="p-button-text p-button-plain"
													[title]="'Edit ' + opportunity.name"
													tippy
													tippyContent="Edit"
													tippyPlacement="bottom"
												></p-button>
												<p-button
													*ngIf="diagramEditOpportunity && can_edit"
													(onClick)="deleteOpportunity(opportunity)"
													[icon]="'pi pi-trash'"
													styleClass="p-button-text p-button-plain"
													[title]="'Delete ' + opportunity.name"
												></p-button>

												<p-button
													*ngIf="can_edit && shapeEditAIOpportunity"
													[icon]="opportunity.valid ? 'pi pi-check' : 'pi pi-plus-circle'"
													[label]="
														opportunity.valid
															? 'Added My AI Solutions'
															: 'Add to My AI Solutions'
													"
													(onClick)="toggleToHubble(opportunity)"
													styleClass="h-full"
												></p-button>
											</div>
										</div>
									</div>
								</ng-template>
							</div>
						</div>
					</p-tabPanel>
				</p-tabView>
			</div>
		</div>
		<div class="actions actions-box actions-8" [ngClass]="action8Class">
			<div class="flex justify-content-between align-items-center gap-2 px-3 py-2">
				<div class="flex justify-content-start align-items-center gap-2 px-3 py-2">
					<p class="text-black-alpha-90 text-xl font-bold my-auto">Embedded AI Platform</p>

					<p-button
						*ngIf="embeddedAIOpportunity && can_edit"
						styleClass="p-button-secondary p-button-text button-action btnAI"
						(onClick)="toggleEmbeddedAI(embeddedAIOpportunity, true)"
						[disabled]="embeddedAILoading"
						tippy
						tippyContent="Embedded AI"
						tippyPlacement="bottom"
					>
						<ng-template pTemplate="icon">
							<svg
								*ngIf="!embeddedAILoading"
								width="19"
								height="20"
								viewBox="0 0 19 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_3650_2953)">
									<path
										d="M11.4405 5.18509L13.1208 9.4945C13.2 9.69617 13.3582 9.85539 13.5587 9.93499L17.8427 11.6253C18.4969 11.8827 18.4969 12.8141 17.8427 13.0715L13.5587 14.7618C13.3582 14.8415 13.2 15.0007 13.1208 15.2023L11.4405 19.5117C11.1846 20.1698 10.2587 20.1698 10.0029 19.5117L8.32252 15.2023C8.24338 15.0007 8.08511 14.8415 7.88463 14.7618L3.60069 13.0715C2.9465 12.8141 2.9465 11.8827 3.60069 11.6253L7.88463 9.93499C8.08511 9.85539 8.24338 9.69617 8.32252 9.4945L10.0029 5.18509C10.2587 4.52701 11.1846 4.52701 11.4405 5.18509Z"
										class="icon"
									/>
									<path
										d="M3.94102 2.85791L4.73502 4.89851C4.77195 4.99404 4.84581 5.06834 4.94078 5.10549L6.96932 5.90422C7.27795 6.02628 7.27795 6.46677 6.96932 6.58884L4.94078 7.38757C4.84581 7.42472 4.77195 7.49902 4.73502 7.59454L3.94102 9.63514C3.81968 9.94561 3.38179 9.94561 3.26044 9.63514L2.46644 7.59454C2.42951 7.49902 2.35565 7.42472 2.26068 7.38757L0.232146 6.58884C-0.0764869 6.46677 -0.0764869 6.02628 0.232146 5.90422L2.26068 5.10549C2.35565 5.06834 2.42951 4.99404 2.46644 4.89851L3.26044 2.85791C3.38179 2.54744 3.81968 2.54744 3.94102 2.85791Z"
										class="icon"
									/>
									<path
										d="M8.91868 0.12471L9.34338 1.21268C9.36449 1.26309 9.40406 1.3029 9.45418 1.32413L10.5357 1.75135C10.7019 1.81769 10.7019 2.05121 10.5357 2.11755L9.45418 2.54477C9.40406 2.566 9.36449 2.6058 9.34338 2.65622L8.91868 3.74419C8.85274 3.91136 8.6206 3.91136 8.55466 3.74419L8.12995 2.65622C8.10885 2.6058 8.06928 2.566 8.01916 2.54477L6.93763 2.11755C6.77144 2.05121 6.77144 1.81769 6.93763 1.75135L8.01916 1.32413C8.06928 1.3029 8.10885 1.26309 8.12995 1.21268L8.55466 0.12471C8.6206 -0.0424652 8.85274 -0.0424652 8.91868 0.12471Z"
										class="icon"
									/>
								</g>
								<defs>
									<clipPath id="clip0_3650_2953">
										<rect width="18.3333" height="20" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<i *ngIf="embeddedAILoading" class="pi pi-spin pi-spinner"></i>
						</ng-template>
					</p-button>
				</div>

				<p-button
					icon="pi pi-angle-right"
					styleClass="p-button-rounded p-button-text"
					(onClick)="hideAction8()"
					tippy
					[tippyContent]="'Close Embedded AI'"
					tippyPlacement="bottom"
				></p-button>
			</div>

			<p-accordion [activeIndex]="0">
				<ng-template ngFor let-embedded [ngForOf]="embeddedData || []">
					<p-accordionTab [header]="embedded.platform">
						<div class="text-black-alpha-90 opportunity-item">
							<p class="text-primary my-0 py-0">{{ embedded.vendor }}</p>
							<p class="font-bold my-0 py-0">{{ embedded.module_or_tool }}</p>
							<p class="font-bold my-0 py-0">{{ embedded.functionality }}</p>
							<p class="mt-2 mb-0 py-0">{{ embedded.description_and_implementation }}</p>
						</div>
					</p-accordionTab>
				</ng-template>
			</p-accordion>
		</div>
		<div class="actions actions-box actions-5" [ngClass]="action5Class">
			<div *ngIf="diagramMetaDataEditable" class="flex flex-column flex-wrap gap-3 p-4">
				<h1 class="text-primary font-bold text-large">Diagram Info</h1>
				<div class="flex flex-column gap-2 w-full">
					<label for="diagram-name">Name</label>
					<input
						pInputText
						id="diagram-name"
						aria-describedby="name-help"
						[disabled]="!can_edit"
						[(ngModel)]="diagramMetaDataEditable.name"
						placeholder="Enter name"
					/>
				</div>
				<div class="flex flex-column gap-2 w-full">
					<label for="diagram-description">Description</label>
					<textarea
						rows="5"
						cols="30"
						pInputTextarea
						placeholder="Enter description"
						id="diagram-description"
						aria-describedby="description-help"
						[disabled]="!can_edit"
						[(ngModel)]="diagramMetaDataEditable.description"
					></textarea>
				</div>
			</div>
		</div>
		<div class="actions actions-box actions-6 p-0 overflow-auto" [ngClass]="action6Class">
			<div class="flex justify-content-between align-items-center gap-2 bg-primary px-3 py-2">
				<p class="text-white font-bold my-auto">AI Solution Library</p>
				<p-button
					icon="pi pi-times"
					styleClass="p-button-text text-white border-none"
					(onClick)="hideAction6()"
					tippy
					[tippyContent]="'Close AI Solution Library'"
					tippyPlacement="bottom"
				></p-button>
			</div>
			<div class="flex flex-column gap-2 p-3">
				<ng-template
					[ngIf]="!useCases.length && !simulationService.diagramAiSolutions.length"
					[ngIfElse]="useCaseTableBlock"
				>
					<div class="flex flex-wrap bg-gray-200 text-color-base p-3 border-round-lg">
						No AI Solutions are available for this process at the moment.
					</div>
				</ng-template>
				<ng-template #useCaseTableBlock>
					<div class="flex flex-wrap bg-gray-100 text-color-base">
						<div class="col-7 font-bold">AI Solution</div>
						<div class="col-4 font-bold">Impact</div>
						<div class="col-1"></div>
					</div>
					<ng-template [ngIf]="diagramType == 'SubProcess'" [ngIfElse]="useCaseTable">
						<ng-template ngFor let-solution [ngForOf]="simulationService.diagramAiSolutions">
							<div class="flex flex-wrap">
								<div class="col-7 text-color-base">{{ solution.name }}</div>
								<div class="col-4">{{ solution.impactAssessment.general }}</div>
								<div class="col-1">
									<p-button
										icon="pi pi-external-link"
										styleClass="p-button-text"
										(onClick)="openAiSolutionItemModal(solution)"
										tippy
										[tippyContent]="'Open AI Solution'"
										tippyPlacement="bottom"
									></p-button>
								</div>
							</div>
						</ng-template>
					</ng-template>
					<ng-template #useCaseTable>
						<ng-template ngFor let-useCase [ngForOf]="useCases">
							<div class="flex flex-wrap">
								<div class="col-7 text-color-base">{{ useCase.name }}</div>
								<div class="col-4">{{ useCase.data.impact_assessment }}</div>
								<div class="col-1">
									<p-button
										icon="pi pi-external-link"
										styleClass="p-button-text"
										(onClick)="openUseCaseItemModal(useCase)"
										tippy
										[tippyContent]="'Open AI Solution'"
										tippyPlacement="bottom"
									></p-button>
								</div>
							</div>
						</ng-template>
					</ng-template>
				</ng-template>
			</div>
		</div>
		<div #actionSubprocess></div>
		<div class="grid">
			<div *ngIf="!hasDiffDiagram" class="col-12">
				<div #canvas id="canvas"></div>
			</div>
			<div *ngIf="hasDiffDiagram" class="col-6 relative">
				<span class="canvas-before-span text-primary font-bold">Original</span>
				<div id="canvas-before"></div>
			</div>
			<div *ngIf="hasDiffDiagram" class="col-6 relative">
				<span class="canvas-after-span text-primary font-bold">Future State</span>
				<div id="canvas-after"></div>
			</div>
		</div>
	</ng-template>
</app-blockable-div>

<p-dialog
	header="Import from text"
	[(visible)]="visible_import_by_text"
	[style]="{ width: '50vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="dialog-diagram-modal"
>
	<div class="flex flex-column flex-wrap gap-3">
		<div class="flex flex-column gap-2">
			<label for="content">Content</label>
			<textarea
				rows="5"
				cols="30"
				pInputTextarea
				placeholder="Enter content"
				id="content"
				aria-describedby="description-help"
				[(ngModel)]="importable_text"
			></textarea>
		</div>
		<div class="text-right">
			<p-button [label]="can_edit ? 'Add' : ''" (onClick)="importByText()"></p-button>
		</div>
	</div>
</p-dialog>

<p-dialog
	header="Import from XML"
	[(visible)]="visible_import_by_xml"
	[style]="{ width: '50vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="dialog-diagram-modal"
>
	<div class="flex flex-column flex-wrap gap-3">
		<div class="flex flex-column gap-2">
			<p-fileUpload
				*ngIf="visible_import_by_xml"
				mode="basic"
				chooseLabel="File"
				accept="text/xml"
				[maxFileSize]="1000000"
				(onSelect)="onUploadXml($event)"
			></p-fileUpload>
		</div>
		<div class="text-right">
			<p-button label="Add" (onClick)="importByText()"></p-button>
		</div>
	</div>
</p-dialog>

<p-dialog
	header="Import from zip"
	[(visible)]="visible_import_by_zip"
	[style]="{ width: '50vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="dialog-diagram-modal"
>
	<div class="flex flex-column flex-wrap gap-3 px-3">
		<div class="flex flex-column gap-2">
			<p-fileUpload
				*ngIf="visible_import_by_zip"
				mode="basic"
				chooseLabel="File"
				accept=".zip"
				[maxFileSize]="1000000"
				(onSelect)="onUploadZip($event)"
			></p-fileUpload>
		</div>
		<div class="text-right">
			<p-button label="Add" (onClick)="importByZip()"></p-button>
		</div>
	</div>
</p-dialog>

<p-dialog
	header="Add to My AI Solutions"
	[(visible)]="visible_use_case_dialog"
	[style]="{ 'min-width': '40vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="dialog-diagram-modal"
>
	<div *ngIf="useCaseService.currentUseCase" class="flex flex-column flex-wrap gap-3 px-3">
		<div class="flex flex-column gap-2">
			<label for="usecase-name">Name</label>
			<input
				pInputText
				id="usecase-name"
				placeholder="Enter name"
				aria-describedby="name-help"
				[(ngModel)]="useCaseService.currentUseCase.name"
			/>
		</div>
		<div class="flex flex-column gap-2">
			<label for="usecase-description">Description</label>
			<textarea
				rows="5"
				cols="30"
				placeholder="Enter description"
				pInputTextarea
				id="usecase-description"
				aria-describedby="description-help"
				[(ngModel)]="useCaseService.currentUseCase.description"
			></textarea>
		</div>
		<div class="flex justify-content-end gap-2">
			<p-button
				[loading]="blockedAddUseCase"
				[disabled]="blockedAddUseCaseGo"
				label="Add"
				(onClick)="addUseCase()"
			></p-button>
			<p-button
				[loading]="blockedAddUseCaseGo"
				[disabled]="blockedAddUseCase"
				label="Add and edit"
				(onClick)="addUseCase(true)"
			></p-button>
		</div>
	</div>
</p-dialog>

<p-dialog
	*ngIf="isSubprocess"
	header="Import SubProcess from AI Solution"
	[(visible)]="subprocessModalVisible"
	[style]="{ width: '45vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="diagram-subprocess-modal"
>
	<p-tree
		class="w-full"
		styleClass="subprocess-tree"
		selectionMode="single"
		[filter]="true"
		filterMode="strict"
		filterPlaceholder="Filter"
		[value]="library"
		[(selection)]="librarySelect"
	>
	</p-tree>
	<div class="flex justify-content-end w-full mt-2">
		<p-button
			label="Import as SubProcess"
			[disabled]="!librarySelect"
			(onClick)="actionImportSubprocess()"
		></p-button>
	</div>
</p-dialog>

<p-dialog
	header="Taxonomy Child Phase"
	[(visible)]="showTaxonomyChildModal"
	[style]="{ width: '40vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="diagram-subprocess-modal"
>
	<p-tree
		class="w-full"
		styleClass="subprocess-tree"
		selectionMode="single"
		[filter]="false"
		[value]="taxonomyChildElements"
		[(selection)]="taxonomyChildElementSelection"
	>
	</p-tree>
	<div class="flex justify-content-end w-full mt-2">
		<p-button label="Update step" (onClick)="updateTaxonomyRel()"></p-button>
	</div>
</p-dialog>

<p-dialog
	header="AI Opportunity"
	[(visible)]="visible_edit_ai_opportunity"
	[style]="{ width: '40vw' }"
	[modal]="true"
	[draggable]="false"
	[resizable]="false"
	styleClass="dialog-diagram-modal"
	(onHide)="editedOpportunity()"
>
	<div *ngIf="currentEditOpportunity" class="flex flex-column flex-wrap gap-3">
		<div class="flex flex-column gap-2">
			<label for="opportunity-name">Name</label>
			<input
				pInputText
				id="opportunity-name"
				placeholder="Enter name"
				[(ngModel)]="currentEditOpportunity.name"
			/>
		</div>
		<div class="flex flex-column gap-2">
			<label for="opportunity-description">Content</label>
			<textarea
				rows="5"
				cols="30"
				placeholder="Enter Content"
				pInputTextarea
				id="opportunity-description"
				[(ngModel)]="currentEditOpportunity.description"
			></textarea>
		</div>
		<div class="flex flex-column gap-2">
			<label for="content">Knowledge Data</label>
			<div class="flex flex-column gap-2">
				<ng-template ngFor let-i="index" [ngForOf]="currentKnowledgeData" [ngForTrackBy]="trackByIndex">
					<div class="flex justify-content-between gap-3">
						<input
							pInputText
							placeholder="Enter Knowledge Data"
							[(ngModel)]="currentKnowledgeData[i]"
							class="w-full"
						/>
						<p-button
							icon="pi pi-times"
							(onClick)="removeKnowledgeData(i)"
							[disabled]="currentKnowledgeData.length === 1"
							tippy
							[tippyContent]="'Remove Knowledge Data'"
							tippyPlacement="bottom"
						></p-button>
					</div>
				</ng-template>

				<div class="flex justify-content-end">
					<p-button
						icon="pi pi-plus"
						(onClick)="addKnowledgeData()"
						tippy
						[tippyContent]="'Add Knowledge Data'"
						tippyPlacement="bottom"
					></p-button>
				</div>
			</div>
		</div>
	</div>
</p-dialog>

<p-dialog
	header="AI Solution Library"
	[(visible)]="useCaseItemModal"
	[modal]="true"
	[style]="{ width: '80vw' }"
	styleClass="diagram-sub-modal"
>
	<app-use-case-library-item
		*ngIf="currentUseCaseLibrary"
		[useCase]="currentUseCaseLibrary"
		[diagram]="ref"
	></app-use-case-library-item>
</p-dialog>

<p-dialog
	header="AI Solution Library"
	[(visible)]="simulationService.aiSolutionItemModal"
	[modal]="true"
	[style]="{ width: '80vw' }"
	styleClass="diagram-sub-modal"
	(onHide)="simulationService.currentAISolution = undefined"
>
	<app-ai-solution-item [aiSolution]="simulationService.currentAISolution"></app-ai-solution-item>
</p-dialog>

<p-blockUI [target]="diagramModal" [blocked]="blockedDiagram">
	<i class="pi pi-spin pi-spinner" style="font-size: 4rem; color: white"></i>
</p-blockUI>
