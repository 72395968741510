import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-svg-ai-agent',
	templateUrl: './svg-ai-agent.component.html',
})
export class SvgAiAgentComponent {
	@Input() fill: 'yellow' | 'blue' = 'yellow';

	get fillColor(){
		return this.fill == 'yellow' ? '#DCB96F' : '#1B54F8';
	}
}
