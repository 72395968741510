import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItem as MenuItemBase } from 'primeng/api';
import { AuthService } from '../rtp/service/auth.service';

type MenuItem = MenuItemBase & { class?: string };

@Component({
	selector: 'app-sidebar',
	templateUrl: './app.sidebar.component.html',
	styleUrls: ['./app.sidebar.component.scss'],
})
export class AppSidebarComponent implements OnInit {
	items: MenuItem[];

	constructor(public el: ElementRef, public authService: AuthService) {}

	ngOnInit() {
		this.items = [
			{
				label: 'Hackett Dynamic Simulation',
				icon: 'pi pi-fw pi-sparkles',
				class: 'menu-main',
				routerLink: ['/'],
			},
			{
				label: 'Taxonomy Explorer',
				icon: 'pi pi-fw pi-compass',
				class: 'menu-main',
				routerLink: ['/taxonomy/'],
			}
		];

		if (this.authService.currentOrganization) {
			this.items.push(
				...[
					{
						label: 'AI Solutions',
						icon: 'pi pi-fw pi-bolt',
						class: 'menu-main',
						routerLink: ['/ai-solutions/'],
					},
					{
						label: 'Hubble Design',
						icon: 'telescope-icon',
						class: 'menu-main',
						routerLink: ['/design-nexus/'],
					},
					{
						label: 'AI Maturity Framework',
						icon: 'pi pi-fw pi-book',
						class: 'menu-main',
						routerLink: ['/maturity-framework/'],
					}
				],
			);
		}
	}
}
