import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormChangePassword, FormSignIn, IChangedPassword, IPermissions, IToken, IUser } from '../api/auth.api';
import { environment } from '../../../environments/environment';
import { lastValueFrom, Subject } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DbService } from './db.service';
import { IOrganization, Organization } from '../api/common';
import { v4 as uuid } from 'uuid';
import { plainToInstance } from 'class-transformer';
import { EventService } from './event.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public user: IUser | undefined;
	public permissions: IPermissions | undefined;

	public updateSource = new Subject<string>();
	updateSource$ = this.updateSource.asObservable();

	constructor(public dbService: DbService, public http: HttpClient, private eventService: EventService) {
		this.getUser().then(() => {
			if (!environment.production) {
				console.log('Auth init');
			}
			this.eventService.setStartup();
		});

		this.dbService.dbAuthUpdateSource$.subscribe({
			next: (data) => {
				if (data.model_name === 'user') {
					if (data.object_id === this.user?.id) {
						this.getUser(true).then((user) => {
							if (user?.change_password) {
								window.location.reload();
							}
						});
					}
				} else {
					this.getCurrentOrganizationId().then(async (id) => {
						if (data.object_id === id) {
							await this.getCurrentOrganization(true);
						} else {
							await this.getOrganizations(true);
						}
						this.updateSource.next(uuid());
					});
				}
			},
		});
	}

	get name() {
		if (this.user) {
			return this.user.first_name + ' ' + this.user.last_name;
		}
		return 'Profile';
	}

	get profile() {
		const name = this.name;
		if (this.user) {
			if (name.trim()) {
				return name + ' (' + this.user.username + ')';
			} else {
				return this.user.username;
			}
		}
		return name;
	}

	getSignInForm(): FormSignIn {
		return new FormGroup({
			username: new FormControl<string>('', [Validators.required]),
			password: new FormControl<string>('', [Validators.required]),
		});
	}

	async signIn(form: FormSignIn): Promise<boolean> {
		let valid = form.touched && form.valid;
		if (valid) {
			if (form.value.username && form.value.password) {
				valid = await this.loadUser(form.value.username, form.value.password);
			} else {
				valid = false;
			}
		}
		return valid;
	}

	getChangePasswordForm(): FormChangePassword {
		return new FormGroup({
			password1: new FormControl<string>('', [Validators.required]),
			password2: new FormControl<string>('', [Validators.required]),
		});
	}

	async changePassword(form: FormChangePassword): Promise<boolean> {
		let valid = form.touched && form.valid;
		if (valid) {
			if (form.value.password1 && form.value.password2 && form.value.password1 === form.value.password2) {
				const token = localStorage.getItem('auth-token');
				if (!token) {
					return false;
				}
				const res = await lastValueFrom(
					this.http.post<IChangedPassword>(
						`${environment.url}/api/v2/user/change-password`,
						{
							password: form.value.password1,
						},
						{
							headers: { Authorization: 'Bearer ' + token },
						},
					),
				).catch(() => ({ valid: false }));
				if (res.valid && this.user) {
					this.user.change_password = false;
				}
				return res.valid;
			} else {
				return false;
			}
		}
		return false;
	}

	async getUser(force = false): Promise<IUser | undefined> {
		if (force) {
			this.user = undefined;
		}
		if (!this.user) {
			const token = localStorage.getItem('auth-token');
			if (token) {
				const res = await lastValueFrom(
					this.http.get<IUser | undefined>(`${environment.url}/api/v2/user/`, {
						headers: { Authorization: 'Bearer ' + token },
					}),
				).catch(() => undefined);
				if (res) {
					this.user = res;
				}
			}
		}
		await this.getPermissions();
		if (this.user) {
			const lastUser = localStorage.getItem('last-user');
			if (!lastUser || (lastUser && lastUser !== this.user.id)) {
				localStorage.removeItem('current_organization');
				localStorage.removeItem('organizations');
			}
			localStorage.setItem('last-user', this.user.id || '');
		}
		await this.getCurrentOrganization();
		this.updateSource.next(uuid());
		this.eventService.setAuth(!!this.user);
		return this.user;
	}

	async getPermissions() {
		if (!this.permissions) {
			if (this.user) {
				if (this.user.role === 'superadmin' || this.user.role === 'admin') {
					this.permissions = {
						can_edit_taxonomy: true,
						can_add_solution: true,
						can_edit_solution: true,
						can_delete_solution: true,
					};
				} else {
					this.permissions = {
						can_edit_taxonomy: false,
						can_add_solution: false,
						can_edit_solution: false,
						can_delete_solution: false,
					};
				}
			}
		}
		return this.permissions;
	}

	async loadUser(username?: string, password?: string): Promise<boolean> {
		const res = await lastValueFrom(
			this.http.post<IToken>(`${environment.url}/api/v2/auth/`, {
				username,
				password,
			}),
		).catch(() => ({ token: '' }));
		if (res.token) {
			localStorage.setItem('auth-token', res.token);
			await this.getUser();
			await this.getPermissions();
			if (!environment.production) {
				console.log('Auth init');
			}
		}
		return !!res.token;
	}

	async signOut() {
		this.dbService.clear();
		localStorage.removeItem('auth-token');
		this.user = undefined;
		this.permissions = undefined;
		this.loadOrganizations = false;
		this.organizations = [];
		this.organizationsDtUnix = [];
		this.currentOrganization = undefined;
		this.organizationSource.next(uuid());
	}

	loadOrganizations = false;
	organizations: Organization[];
	organizationsDtUnix: { organizationId: string; dtUnix: number }[];
	currentOrganization: Organization | undefined;

	async getOrganizations(force?: boolean): Promise<Organization[]> {
		if (force) {
			this.organizations = [];
			this.loadOrganizations = false;
		}
		if ((!this.organizations || !this.organizations.length) && !!localStorage.getItem('auth-token')) {
			const organizations: IOrganization[] = [];
			if (this.loadOrganizations) {
				const organizations_json = localStorage.getItem('organizations') || '[]';
				organizations.push(...JSON.parse(organizations_json));
			} else {
				organizations.push(
					...(await lastValueFrom(this.http.get<IOrganization[]>('@api/common/organization/'))),
				);
				localStorage.setItem('organizations', JSON.stringify(organizations));
				this.loadOrganizations = true;
			}
			this.organizations = organizations.map((d) => plainToInstance(Organization, d));
		}
		return this.organizations;
	}

	async getOrganization(id: string, force?: boolean): Promise<Organization | undefined> {
		await this.getOrganizations(force);
		return this.organizations.find((o) => o.id === id);
	}

	async getCurrentOrganization(force?: boolean): Promise<Organization | undefined> {
		if (force) {
			this.currentOrganization = undefined;
		}
		if (!this.currentOrganization && this.user) {
			const id = localStorage.getItem('current_organization') || '';
			await this.getOrganizations(force);
			if (id) {
				const organization = await this.getOrganization(id);
				if (organization) {
					await this.setCurrentOrganization(organization);
				} else {
					if (this.organizations && this.organizations.length) {
						await this.setCurrentOrganization(this.organizations[0]);
					}
				}
			} else {
				if (this.organizations && this.organizations.length) {
					await this.setCurrentOrganization(this.organizations[0]);
				}
			}
		}
		return this.currentOrganization;
	}

	async getCurrentOrganizationId(force?: boolean): Promise<string> {
		return (await this.getCurrentOrganization(force))?.id || '';
	}

	public organizationSource = new Subject<string>();
	organizationSource$ = this.organizationSource.asObservable();

	async setCurrentOrganization(organization: string | Organization, force?: boolean) {
		const id = organization instanceof Organization ? organization.id : organization;
		if (id) {
			localStorage.setItem('current_organization', id);
		}
		if (organization instanceof Organization) {
			this.currentOrganization = organization;
		} else if (id) {
			const organization = await this.getOrganization(id, force);
			if (organization) {
				this.currentOrganization = organization;
			}
		}
		this.organizationSource.next(uuid());
		return this.currentOrganization;
	}

	saveCurrentOrganization() {
		this.http
			.patch('@api/common/organization/' + this.currentOrganization?.id + '/', {
				name: this.currentOrganization?.name,
				summary: this.currentOrganization?.summary,
			})
			.subscribe({
				next: (obj) => {
					if (!environment.production && obj) {
						console.log(obj);
					}
				},
				error: (err: HttpErrorResponse) => {
					console.log(err);
				},
			});
	}

	resetPassword(username_email: string) {
		return this.http.post<{ valid: boolean }>(`${environment.url}/api/v2/user/reset-password`, {
			username_email,
		});
	}
}
