<div class="grid card-grid">
	<div class="inform-wrapper">
	  <span class="inform-label font-bold">INFORM</span>
	</div>
	<div class="col-12 hackett-ai-agents">
	  <div class="grid card-grid pt-5 pl-5 pr-5 pb-3">
		<div class="col-2 logo-column">
		  <div class="logo-wrapper">
			<app-hackett-logo class="logo"></app-hackett-logo>
			<div class="text-wrapper">
			  <span class="taxonomy-company">Hackett Digital</span>
			  <span class="taxonomy-company">World Class<sup>®</sup></span>
			  <span class="taxonomy-title">INDUSTRY</span>
			  <span class="taxonomy-title">TAXONOMIES</span>
			</div>
		  </div>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary"># OF E2E</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #e2eSourceRelCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary"># OF PROCESS</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #processCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary"># OF SUBPROCESS</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #subProcessCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary"># OF WORKSTEPS</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #stepsCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
	  </div>
	</div>
  
	<div class="inform-wrapper">
	  <span class="inform-label-violet font-bold">IDEATE</span>
	</div>
	<div class="col-12 taxonomy-section hackett-ai-agents">
	  <div class="grid card-grid pt-5 pl-5 pr-5 pb-3">
		<div class="col-2 logo-column">
		  <div class="logo-wrapper">
			<app-hackett-logo class="logo"></app-hackett-logo>
			<div class="text-wrapper">
			  <span [hidden]="!simulationService.loaded" class="taxonomy-company">Hackett</span>
			  <span class="taxonomy-title">AI SOLUTIONS</span>
			</div>
		  </div>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card highlighted-card pb-3">
			<div class="card-header-highlight"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-black-900">GEN AI SOLUTIONS</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #useCasesCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card highlighted-card pb-3">
			<div class="card-header-highlight"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title">BREAKTHROUGH</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #breakthroughCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card highlighted-card pb-3">
			<div class="card-header-highlight"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title">TRANSFORMATIVE</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #transformativeCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card highlighted-card pb-3">
			<div class="card-header-highlight"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title">INCREMENTAL</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #incrementalCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
	  </div>
	</div>
  
	<div class="inform-wrapper">
	  <span class="inform-label font-bold">BUILD</span>
	</div>
	<div class="col-12 hackett-ai-agents">
	  <div class="grid card-grid pt-5 pl-5 pr-5 pb-3">
		<div class="col-2 logo-column">
		  <div class="logo-wrapper">
			<app-hackett-logo class="logo"></app-hackett-logo>
			<div class="text-wrapper">
			  <span class="taxonomy-company">Hackett</span>
			  <span class="taxonomy-title">AI AGENTS</span>
			</div>
		  </div>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary">GEN AI OPPORTUNITIES</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #aiOpportunitiesCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary">AI AGENTS</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #aiOpportunityAgentCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary">ESSENTIAL AGENTS</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #aiOpportunitiesEssentialCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
  
		<div class="col-2">
		  <mat-card class="small-card pb-3 highlighted-card-blue">
			<div class="card-header-blue"></div>
			<div class="card-content-wrapper">
			  <mat-card-header class="text-center">
				<mat-card-title class="mat-card-title text-primary">OPTIONAL AGENTS</mat-card-title>
			  </mat-card-header>
			  <mat-card-content class="mat-card-content pb-3">
				<span [hidden]="!simulationService.loaded" #aiOpportunitiesOptionalCountUp></span>
				<span *ngIf="!simulationService.loaded" class="pt-2">
				  <p class="pt-3">
					<p-skeleton width="75px" height="30px"></p-skeleton>
				  </p>
				</span>
			  </mat-card-content>
			</div>
		  </mat-card>
		</div>
	  </div>
	</div>
  </div>
  