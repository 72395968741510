/**
 * Represents an abstract entity.
 *
 * @interface
 */
export interface IAbstractEntity {
	id?: string;

	__typename: string;
}

/**
 * Interface representing the base entity.
 * @interface
 * @extends IAbstractEntity
 */
export interface IBaseEntity extends IAbstractEntity {
	created_at?: number;
	updated_at?: number;
}

/**
 * Represents a tag entity.
 * @interface
 * @extends IBaseEntity
 */
export interface ITagEntity extends IBaseEntity {
	name: string;
	short?: string;
}

/**
 * Interface representing a diagram data item.
 * @interface
 */
export interface IDiagramDataItem {
	name: string;
	type: string;
	description: string;
	technologiesIds: string[];
	aiTechnologies: { [key: string]: string };
	taxonomyRel?: string;
}

/**
 * Represents the base interface for a diagram.
 * @interface
 * @extends IBaseEntity
 */
export interface IDiagramBase extends IBaseEntity {
	xmlData: string;
	xmlImage?: string;

	data?: { [key: string]: IDiagramDataItem };
}

/**
 * Represents a parent entity for a diagram.
 * @interface
 * @template T - The type of diagram.
 */
export interface IDiagramParent<T extends IDiagramBase> extends IBaseEntity {
	name: string;
	description: string;

	diagram?: T;

	// Computed
	path: string;
}

/**
 * Represents a generic interface for common data.
 *
 * @template T - The type of the data.
 */
export interface ICommonData {
	key: string;
	data: string;
}

/**
 * Represents a category function.
 * @interface
 * @extends ITagEntity
 */
export interface ICategoryFunction extends ITagEntity {
	sort: number;
	type: string;
	office: string;

	functions: IFunction[];

	__typename: 'CategoryFunctionModelObjectType';

	// Computed
	path: string;
}

/**
 * Interface representing a function.
 * @interface
 * @extends ITagEntity
 */
export interface IFunction extends IDiagramParent<IDiagramE2E> {
	short?: string;
	sort: number;
	type: string;
	category: ICategoryFunction;
	categoryId: string;
	overall: number;

	levels1: ILevel1[];

	__typename: 'FunctionModelObjectType';

	// Computed
	path: string;
	valuesArray: boolean[];
	overallLabel: string;
	overallClass: string;
}

export interface IDiagramE2E extends IDiagramBase {
	function: IFunction;
	functionId: string;
}

/**
 * Represents a level 1 entity.
 *
 * @interface
 * @extends IBaseEntity
 */
export interface ILevel1 extends IDiagramParent<IDiagramProcess> {
	function: IFunction;
	functionId: string;

	levels2: ILevel2[];

	type: string;

	__typename: 'Level1ModelObjectType';

	// Computed
	path: string;
	valuesArray: boolean[];
}

export interface IDiagramProcess extends IDiagramBase {
	level1: ILevel1;
	level1Id: string;
}

export interface IValueLevel2 {
	technologies: { [key: string]: boolean };
	aiExamples: number;
	steps: number;
}

/**
 * Represents a Level 2 interface.
 * This interface extends IDiagramParent<IDiagramTemplate>.
 */
export interface ILevel2 extends IDiagramParent<IDiagramTemplate> {
	level1: ILevel1;
	level1Id: string;

	values: IValueLevel2;
	type: string;

	__typename: 'Level2ModelObjectType';

	// Computed
	path: string;
	valuesArray: boolean[];
}

/**
 * Represents a diagram template with level 2 information.
 * @interface
 * @extends {IDiagramBase}
 */
export interface IDiagramTemplate extends IDiagramBase {
	level2: ILevel2;
	level2Id: string;
}

/**
 * @interface
 * @extends ITagEntity
 * Represents a technology.
 */
export interface ITechnology extends ITagEntity {
	neto: boolean;
	svg: string;

	__typename: 'TechnologyModelObjectType';
}

export function getEnumValue(value: string): string {
	if (value.split('_').length === 2) {
		value = value.split('_')[1];
	}
	return value;
}
