import {
	TagEntity,
	BaseEntity,
	ITagEntity,
	IBaseEntity,
	IDiagramDataItem,
	IDiagramBase,
	DiagramBase,
	DiagramParent,
	IDiagramParent,
} from './base.api';
import { instanceToInstance } from 'class-transformer';

/**
 * Represents a category function.
 * @interface
 * @extends ITagEntity
 */
export interface ICategoryFunction extends ITagEntity {
	sort: number;
	type: string;
	office: string;

	functions: IFunction[];
}

/**
 * Represents a category function.
 *
 * @class
 * @extends TagEntity
 * @implements ICategoryFunction
 */
export class CategoryFunction extends TagEntity implements ICategoryFunction {
	functions: Function[];

	constructor(
		public sort: number,
		public type: string,
		public office: string,
		name: string,
		short?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, short, created_at, updated_at, id);
		Object.defineProperties(this, {
			functions: { value: [], enumerable: false, writable: true },
		});
	}

	/**
	 * Sorts the values of the object based on the provided sort field and sort order.
	 *
	 * @param {string} sortField - The field by which to sort the values.
	 * @param {number} sortOrder - The order in which to sort the values. 1 for ascending, -1 for descending.
	 *
	 * @return {CategoryFunction} - The modified object with sorted values.
	 */
	getSortByValues(sortField: string, sortOrder: number): CategoryFunction {
		const cf = instanceToInstance<CategoryFunction>(this);
		cf.functions = this.functions.map((fn) => fn.getSortByValues(sortField, sortOrder));
		return cf;
	}

	/**
	 * Retrieves the children of this object.
	 *
	 * @returns {array} An array containing the children of this object.
	 */
	getChildren(): Array<Function> {
		return this.functions || [];
	}
}

/**
 * Interface representing a function.
 * @interface
 * @extends ITagEntity
 */
export interface IFunction extends IDiagramParent<IDiagramE2E> {
	short?: string;
	sort: number;
	type: string;
	category: ICategoryFunction;
	categoryId: string;
	overall: number;

	levels1: ILevel1[];
}

/**
 * Represents a Function.
 * @extends TagEntity
 * @implements IFunction
 */
export class Function extends DiagramParent<DiagramE2E> implements IFunction {
	category: CategoryFunction;

	levels1: Level1[];

	constructor(
		public sort: number,
		public type: string,
		public categoryId: string,
		public overall: number,
		name: string,
		description: string,
		public short?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, description, created_at, updated_at, id);
		Object.defineProperties(this, {
			category: { value: undefined, enumerable: false, writable: true },
			levels1: { value: [], enumerable: false, writable: true },
			valuesArray: { value: [], enumerable: false, writable: true },
			overallLabel: { value: undefined, enumerable: false, writable: true },
			overallClass: { value: undefined, enumerable: false, writable: true },
		});
	}

	valuesArray: boolean[] = [];
	overallLabel: string = '';
	overallClass: string = '';

	/**
	 * Returns the full path of the item.
	 *
	 * @returns {string} The full path of the item.
	 */
	get path(): string {
		if (this.category) {
			return this.category.name + ' / ' + this.name;
		} else {
			return '';
		}
	}

	/**
	 * Sorts the values in the current object based on the specified field and order.
	 *
	 * @param {string} sortField - The field based on which the values should be sorted.
	 * @param {number} sortOrder - The order in which the values should be sorted. Positive values for ascending order, negative values for descending order.
	 *
	 * @return {Function} - A new object with the sorted values.
	 */
	getSortByValues(sortField: string, sortOrder: number): Function {
		const fn = instanceToInstance<Function>(this);
		fn.levels1 = this.levels1.map((l1) => l1.getSortByValues(sortField, sortOrder));
		if (this.category) {
			fn.category = this.category;
		}
		return fn;
	}

	/**
	 * Retrieves the children of the current node.
	 *
	 * @returns {Array} The children of the current node.
	 */
	getChildren(): Array<Level1> {
		return this.levels1 || [];
	}

	getOverallLabel(): string {
		if (this.overall >= 1 && this.overall < 4) {
			return 'Incremental';
		} else if (this.overall >= 4 && this.overall < 7) {
			return 'Transformative';
		} else if (this.overall >= 7 && this.overall <= 10) {
			return 'Breakthrough';
		} else {
			return '--';
		}
	}

	getOverallClass(): string {
		if (this.overall >= 1 && this.overall < 4) {
			return 'background-low';
		} else if (this.overall >= 4 && this.overall < 7) {
			return 'background-medium';
		} else if (this.overall >= 7 && this.overall <= 10) {
			return 'background-high';
		} else {
			return 'background-none';
		}
	}

	getOverallFilter(options: number[]) {
		let value = 0;
		if (this.overall >= 1 && this.overall < 4) {
			value = 1;
		} else if (this.overall >= 4 && this.overall < 7) {
			value = 2;
		} else if (this.overall >= 7 && this.overall <= 10) {
			value = 3;
		}
		return options.includes(value);
	}
}

export interface IDiagramE2E extends IDiagramBase {
	function: IFunction;
	functionId: string;
}

/**
 * Represents a diagram template.
 * @extends {DiagramBase}
 * @implements {IDiagramTemplate}
 */
export class DiagramE2E extends DiagramBase implements IDiagramE2E {
	function: Function;

	constructor(
		public functionId: string,
		xml: string,
		data?: { [key: string]: IDiagramDataItem },
		capture_url?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(xml, capture_url, data, created_at, updated_at, id);
		Object.defineProperties(this, {
			function: { value: undefined, enumerable: false, writable: true },
		});
	}
}

/**
 * Represents a level 1 entity.
 *
 * @interface
 * @extends IBaseEntity
 */
export interface ILevel1 extends IDiagramParent<IDiagramProcess> {
	function: IFunction;
	functionId: string;

	levels2: ILevel2[];

	type: string;
}

/**
 * Represents a Level1 object.
 * @extends BaseEntity
 * @implements ILevel1
 */
export class Level1 extends DiagramParent<DiagramProcess> implements ILevel1 {
	function: Function;

	levels2: Level2[];

	constructor(
		public functionId: string,
		public type: string,
		name: string,
		description: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, description, created_at, updated_at, id);
		Object.defineProperties(this, {
			function: { value: undefined, enumerable: false, writable: true },
			levels2: { value: [], enumerable: false, writable: true },
			valuesArray: { value: [], enumerable: false, writable: true },
		});
	}

	valuesArray: boolean[] = [];

	/**
	 * Retrieves the path of the current function or name.
	 * If the function exists, the path consists of the function's path followed by the name.
	 * If the function does not exist, only the name is returned.
	 *
	 * @returns {string} The path of the current function or name.
	 */
	get path(): string {
		if (this.function) {
			return this.function.path + ' / ' + this.name;
		} else {
			return this.name;
		}
	}

	/**
	 * Retrieves the children of the current node.
	 *
	 * @returns {Array} The children of the current node.
	 */
	getChildren(): Array<Level2> {
		return this.levels2 || [];
	}

	/**
	 * Returns a deep copy of the current object with levels sorted by values.
	 *
	 * @param {string} sortField - The field by which to sort the values.
	 * @param {number} sortOrder - The sort order. -1 for descending order, 1 for ascending order.
	 *
	 * @return {Level1} - A deep copy of the current object with levels sorted by values.
	 */
	getSortByValues(sortField: string, sortOrder: number): Level1 {
		return this;
	}
}

export interface IDiagramProcess extends IDiagramBase {
	level1: ILevel1;
	level1Id: string;
}

/**
 * Represents a diagram template.
 * @extends {DiagramBase}
 * @implements {IDiagramTemplate}
 */
export class DiagramProcess extends DiagramBase implements IDiagramProcess {
	level1: Level1;

	constructor(
		public level1Id: string,
		xml: string,
		data?: { [key: string]: IDiagramDataItem },
		capture_url?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(xml, capture_url, data, created_at, updated_at, id);
		Object.defineProperties(this, {
			level1: { value: undefined, enumerable: false, writable: true },
		});
	}
}

export interface IValueLevel2 {
	technologies: { [key: string]: boolean };
	aiExamples: number;
	steps: number;
}

/**
 * Represents a Level 2 interface.
 * This interface extends IDiagramParent<IDiagramTemplate>.
 */
export interface ILevel2 extends IDiagramParent<IDiagramTemplate> {
	level1: ILevel1;
	level1Id: string;

	values: IValueLevel2;
	type: string;
}

/**
 * Represents a Level2 object that extends DiagramParent<DiagramTemplate> and implements ILevel2 interface.
 */
export class Level2 extends DiagramParent<DiagramTemplate> implements ILevel2 {
	level1: Level1;

	constructor(
		public level1Id: string,
		public values: IValueLevel2,
		public type: string,
		name: string,
		description: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, description, created_at, updated_at, id);
		Object.defineProperties(this, {
			level1: { value: undefined, enumerable: false, writable: true },
			valuesArray: { value: [], enumerable: false, writable: true },
		});
	}

	valuesArray: boolean[] = [];

	/**
	 * Retrieves the complete path of the object.
	 * If the object has a parent (level1), the path includes the parent's path followed by a forward slash (/) and the object's name.
	 * If the object does not have a parent, the path consists solely of the object's name.
	 *
	 * @returns {string} The complete path of the object.
	 */
	get path(): string {
		if (this.level1) {
			return this.level1.path + ' / ' + this.name;
		} else {
			return this.name;
		}
	}
}

/**
 * Represents a diagram template with level 2 information.
 * @interface
 * @extends {IDiagramBase}
 */
export interface IDiagramTemplate extends IDiagramBase {
	level2: ILevel2;
	level2Id: string;
}

/**
 * Represents a diagram template.
 * @extends {DiagramBase}
 * @implements {IDiagramTemplate}
 */
export class DiagramTemplate extends DiagramBase implements IDiagramTemplate {
	level2: Level2;

	constructor(
		public level2Id: string,
		xml: string,
		data?: { [key: string]: IDiagramDataItem },
		capture_url?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(xml, capture_url, data, created_at, updated_at, id);
		Object.defineProperties(this, {
			level2: { value: undefined, enumerable: false, writable: true },
		});
	}
}

/**
 * @interface
 * @extends ITagEntity
 * Represents a technology.
 */
export interface ITechnology extends ITagEntity {
	neto: boolean;
	svg: string;
}

/**
 * Class representing a Technology.
 * @extends TagEntity
 * @implements ITechnology
 */
export class Technology extends TagEntity implements ITechnology {
	constructor(
		public neto: boolean,
		public svg: string,
		name: string,
		short?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, short, created_at, updated_at, id);
	}
}

/**
 * Represents a digital worker.
 *
 * @interface
 * @extends {ITagEntity}
 */
export interface IDigitalWorker extends ITagEntity {
	description: string;
	technology: ITechnology;
	technologyId: string;
}

/**
 * Represents a digital worker.
 * @extends TagEntity
 * @implements IDigitalWorker
 */
export class DigitalWorker extends TagEntity implements IDigitalWorker {
	technology: Technology;

	constructor(
		name: string,
		public description: string,
		public technologyId: string,
		short?: string,
		created_at?: number,
		updated_at?: number,
		id?: string,
	) {
		super(name, short, created_at, updated_at, id);
		Object.defineProperties(this, {
			technology: { value: undefined, enumerable: false, writable: true },
		});
	}
}
