import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, TreeNode } from 'primeng/api';
import { LayoutService } from '../../../layout/service/app.layout.service';
import { SimulationService } from './simulation.service';
import { HttpClient } from '@angular/common/http';
import { UseCasesService } from './use-cases.service';
import { AuthService } from '../../service/auth.service';
import { M1Prep, Simulation } from '../../api/simulation.api';
import { Location } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { EventService } from '../../service/event.service';

@Component({
	templateUrl: './simulation.component.html',
	styleUrls: ['./simulation.component.scss'],
	providers: [ConfirmationService, MessageService],
})
export class SimulationComponent implements OnInit {
	constructor(
		private http: HttpClient,
		private layoutService: LayoutService,
		public confirmationService: ConfirmationService,
		public messageService: MessageService,
		public authService: AuthService,
		public simulationService: SimulationService,
		public useCasesService: UseCasesService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private eventService: EventService,
	) {
		this.simulationService.messageService = this.messageService;
	}

	@Input() selectionView: number = 0;

	initLoaded: boolean = false;

	isAccordionOpen: boolean = false; // Control the accordion state
	selectedFilterCount: number = 0;
	showFilter: boolean = false; // State to control the filter visibility

	goToPromptStudio(): void {
		this.selectionView = 3;
		this.changeSelectionView();
	}

	async ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			if (params['NavTab']) {
				this.selectionView = +params['NavTab'];
				this.clearNavTabQueryParam();
			}
		});

		//this.simulationService.simulationsUsesCases
		this.layoutService.updateTitle('Hackett Dynamic Simulation');

		this.simulationService.confirmationService = this.confirmationService;

		if (!this.simulationService.loaded) {
			await this.simulationService.onLoad(() => {
				this.selectedIndustry = this.simulationService.industriesTree.filter(
					(t) => this.simulationService.industryIds.includes(t.key as string) || t.key === 'baseline',
				);

				this.eventService.nextValueEvent<{
					industry: string[];
					taxonomy: string[];
				}>('industryTaxonomySelected', {
					industry: this.selectedIndustry.map((t) => t.key as string),
					taxonomy: [],
				});
			});
			this.initLoaded = true;
		}

		this.useCasesService.confirmationService = this.confirmationService;
		this.useCasesService.messageService = this.messageService;

		if (!this.useCasesService.init) {
			this.useCasesService.loading = true;
			await this.useCasesService.prepareData();
		}
		if (this.selectedIndustry.length !== 0) {
			this.simulationService.selectedIndustryState = this.selectedIndustry;
		}
		this.applyInitialFilter();
	}

	applyInitialFilter(): void {
		if (this.simulationService.selectedIndustryState.length > 0) {
			const selectedIndustryNames = this.simulationService.selectedIndustryState.map((industry) => industry.label);
			this.simulationService.filteredAISolutions = this.simulationService.filteredAISolutions.filter((solution) => {
				const industryName = solution.function?.taxonomy?.industry?.name ?? 'Baseline';
				return selectedIndustryNames.includes(industryName);
			});
		}
		if (this.simulationService.functionsTreeSelection.length > 0) {
			const selectedFunctionKeys = this.simulationService.functionsTreeSelection.map((fn) => fn.key);
			this.simulationService.filteredAISolutions = this.simulationService.filteredAISolutions.filter((solution) =>
				selectedFunctionKeys.includes(solution.function.id),
			);
		}
	}

	clearNavTabQueryParam() {
		const urlTree = this.router.createUrlTree([], {
			relativeTo: this.route,
			queryParams: { NavTab: null },
			queryParamsHandling: 'merge',
		});
		const url = this.router.serializeUrl(urlTree);
		this.location.go(url);
	}

	filterIndex: number = 0;
	useCaseFilter: number = -1;
	dashboardFilter: number = -1;

	getClass(value: string): string {
		switch (value) {
			case 'High':
				return 'background-high';
			case 'Medium':
				return 'background-medium';
			case 'Low':
				return 'background-low';
			case 'None':
				return 'background-none';
			default:
				return '';
		}
	}

	changeFilter(i: number) {
		if (i === this.filterIndex) {
			this.filterIndex = 0;
		} else {
			this.filterIndex = i;
		}
	}

	aiEnablersTreeSelections: TreeNode[] = [];
	aiEnablersTreeOptions: TreeNode[] = [];

	scopeTree: TreeNode[] = [
		{
			label: 'E2E',
			key: '1',
		},
		{
			label: 'Process',
			key: '2',
		},
		{
			label: 'SubProcess',
			key: '3',
		},
	];
	scopeTreeSelections: TreeNode[] = [...this.scopeTree];
	scopeTreeOptions: TreeNode[] = [...this.scopeTree];

	impactTree: TreeNode[] = [
		{
			label: 'Breakthrough',
			key: '1',
		},
		{
			label: 'Transformative',
			key: '2',
		},
	];
	impactTreeSelections: TreeNode[] = [...this.impactTree];
	impactTreeOptions: TreeNode[] = [...this.impactTree];

	technologyTree: TreeNode[] = [
		{
			label: 'OneStream',
			key: '1',
		},
		{
			label: 'SAP',
			key: '2',
		},
	];
	technologyTreeSelections: TreeNode[] = [...this.technologyTree];
	technologyTreeOptions: TreeNode[] = [...this.technologyTree];

	benefitsTree: TreeNode[] = [
		{
			label: 'Savings',
			key: '1',
		},
		{
			label: 'Automation',
			key: '2',
		},
	];
	benefitsTreeSelections: TreeNode[] = [...this.benefitsTree];
	benefitsTreeOptions: TreeNode[] = [...this.benefitsTree];

	addProcessDiagram() {
		this.simulationService.addProcessTab = 0;
		this.simulationService.addProcessForm = { name: '', description: '' };
		this.simulationService.addProcessTaxonomyTreeSelected = [];
		this.simulationService.addProcessTaxonomyScope =
			this.simulationService.addProcessTaxonomyScopeOptions[
				this.simulationService.addProcessTaxonomyScopeOptions.length - 1
			];
		this.simulationService.addProcessDialog = true;
	}

	selectionViewOptions: { value: number }[] = [{ value: 0 }, { value: 1 }, { value: 2 }, { value: 3 }];

	taxonomyPromptStudioBackup: string[] = [];
	industriesPromptStudioBackup: string[] = [];

	changeSelectionView() {
		this.filterIndex = 0;
		this.useCaseFilter = -1;
		this.dashboardFilter = -1;

		if (this.selectionView === 3) {
			if (this.simulationService.taxonomyPromptStudio.length === 0) {
				this.taxonomyPromptStudioBackup = [];
			}
			this.simulationService.taxonomyPromptStudio = [];
		} else {
			const areEqualIndustry =
				this.simulationService.industriesPromptStudio.length === this.industriesPromptStudioBackup.length &&
				new Set(this.simulationService.industriesPromptStudio).size ===
					new Set(this.industriesPromptStudioBackup).size &&
				this.simulationService.industriesPromptStudio.every((item) =>
					this.industriesPromptStudioBackup.includes(item),
				);

			if (!areEqualIndustry) {
				this.selectedIndustry = this.simulationService.industriesTree.filter((t) =>
					this.simulationService.industriesPromptStudio.includes(t.key as string),
				);

				this.industriesPromptStudioBackup = this.selectedIndustry.map((t) => t.key as string);
			}

			const areEqualTaxonomy =
				this.simulationService.taxonomyPromptStudio.length === this.taxonomyPromptStudioBackup.length &&
				new Set(this.simulationService.taxonomyPromptStudio).size ===
					new Set(this.taxonomyPromptStudioBackup).size &&
				this.simulationService.taxonomyPromptStudio.every((item) =>
					this.taxonomyPromptStudioBackup.includes(item),
				);

			if (!areEqualTaxonomy) {
				this.simulationService.functionsTreeSelection = this.useCasesService.functionsTree.filter((t) =>
					this.simulationService.taxonomyPromptStudio.includes(t.key as string),
				);

				this.taxonomyPromptStudioBackup = this.simulationService.functionsTreeSelection.map((t) => t.key as string);
			}

			if (!areEqualIndustry || !areEqualTaxonomy) {
				this.selectTaxonomy(this.industriesPromptStudioBackup, this.taxonomyPromptStudioBackup, true);
			}
		}
	}

	showOpenSimulation: boolean = false;

	openSimulation() {
		this.simulationService._loadSimulations().then(() => {
			this.showOpenSimulation = true;
		});
	}

	selectSimulation(simulation: Simulation) {
		this.showOpenSimulation = false;

		this.simulationService.selectSimulation(simulation);
	}

	showOpenM1Prep: boolean = false;

	openM1Prep() {
		this.simulationService._loadM1Preps().then(() => {
			this.showOpenM1Prep = true;
		});
	}

	selectM1Prep(m1Prep: M1Prep) {
		this.showOpenM1Prep = false;

		this.simulationService.selectM1Prep(m1Prep);
	}

	// Filter Dashboard

	selectedTaxonomy: TreeNode[] = [];
	bSelectedTaxonomy: TreeNode[] = [];
	bSelectedImpact: string[] = [];
	bSelectedValueDrivers: string[] = [];

	selectedIndustry: TreeNode[] = [];
	bSelectedIndustry: TreeNode[] = [];

	async updateSelectedFiltersIndustry(panel = true) {
		this.selectedIndustry = this.simulationService.selectedIndustryState;
		// if (!this.initLoaded) {
		// 	return;
		// }

		const isOpen = !!document.querySelector('.p-multiselect-panel.industry');

		if (
			(panel || (!panel && !isOpen)) &&
			!this.areListsEqual(
				this.selectedIndustry.map((t) => t.key as string),
				this.bSelectedIndustry.map((t) => t.key as string),
			)
		) {
			this.bSelectedIndustry = [...this.selectedIndustry];

			this.selectedTaxonomy = [];
			this.bSelectedTaxonomy = [];

			await this.selectTaxonomy(this.selectedIndustry, this.selectedTaxonomy, true);
			this.simulationService.selectedIndustryState = this.selectedIndustry;
			this.simulationService.functionsTreeSelection = this.selectedTaxonomy;
			this.simulationService.changeSelection();
		}
	}

	changeSelection() {
		this.updateSelectedFiltersIndustry(false);
		this.updateSelectedFilters(false);
	}

	async updateSelectedFilters(panel = true) {
		const isIndustryOpen = !!document.querySelector('.p-multiselect-panel.industry');
		const isFunctionOpen = !!document.querySelector('.p-multiselect-panel.taxonomy');
		const isImpactOpen = !!document.querySelector('.p-multiselect-panel.impact');
		const isValueDriversOpen = !!document.querySelector('.p-multiselect-panel.benefits');
	
		const isOpen = isIndustryOpen || isFunctionOpen || isImpactOpen || isValueDriversOpen;
	
		const selectedFunctions = this.simulationService.functionsTreeSelection.map((t) => t.key as string);
		const selectedIndustries = this.simulationService.selectedIndustryState.map((t) => t.key as string);
		const selectedImpact = this.simulationService.impactTreeSelection.map((t) => t.key as string);
		const selectedValueDrivers = this.simulationService.selectedValueDrivers.map((t) => t.key as string);

		const hasChanged =
			!this.areListsEqual(selectedFunctions, this.bSelectedTaxonomy.map((t) => t.key as string)) ||
			!this.areListsEqual(selectedIndustries, this.simulationService.selectedIndustryState.map((t) => t.key as string)) ||
			!this.areListsEqual(selectedImpact, this.bSelectedImpact) ||
			!this.areListsEqual(selectedValueDrivers, this.bSelectedValueDrivers);
	
		if (hasChanged && !isOpen) {	
			this.bSelectedTaxonomy = [...this.simulationService.functionsTreeSelection];
			this.bSelectedImpact = [...selectedImpact];
			this.bSelectedValueDrivers = [...selectedValueDrivers];
			this.simulationService.selectedIndustryState = [...this.simulationService.selectedIndustryState];
			this.simulationService.selectedValueDrivers = [...this.simulationService.selectedValueDrivers];
			this.simulationService.impactTreeSelection = [...this.simulationService.impactTreeSelection];
	
			await this.selectTaxonomy(this.simulationService.selectedIndustryState, this.simulationService.functionsTreeSelection);
			this.simulationService.changeSelection();
		} else {
		}
	}

	async selectTaxonomy(
		selectedIndustry: (string | TreeNode)[],
		selectedTaxonomy: (string | TreeNode)[],
		updateFunctionsTree?: boolean,
	) {
		const industryIds = selectedIndustry.length
			? selectedIndustry.map((t) => (typeof t === 'string' ? t : (t.key as string)))
			: this.simulationService.industriesTree.map((t) => t.key as string);
		const categoryIds = selectedTaxonomy.map((t) => (typeof t === 'string' ? t : (t.key as string)));
	
		const valueDriversIds = this.simulationService.selectedValueDrivers.length > 0
			? this.simulationService.selectedValueDrivers.map((d) => d.key as string)
			: [];
	
		const impactIds = this.simulationService.impactTreeSelection.length > 0
			? this.simulationService.impactTreeSelection.map((d) => d.label as string)
			: [];

		if (updateFunctionsTree) {
			this.useCasesService.updateFunctionsTree(selectedIndustry);
		}
	
		await this.simulationService.loadSimulations(undefined, industryIds, categoryIds, valueDriversIds, impactIds);
		this.simulationService.selectedIndustryState = this.selectedIndustry;
		this.simulationService.selectedTaxonomyState = this.selectedTaxonomy;

		this.eventService.nextValueEvent<{
			industry: string[];
			taxonomy: string[];
			valueDrivers?: string[];
			impact?: string[];
		}>('industryTaxonomySelected', {
			industry: industryIds,
			taxonomy: categoryIds,
			valueDrivers: valueDriversIds,
			impact: impactIds
		});
	}

	toggleFilter(button: any) {
		this.dashboardFilter = this.dashboardFilter ? 0 : -1;
		setTimeout(() => {
			button.updateTooltip(); 
		});
	}

	getAISolutionsCount(): string {
		return (
			this.simulationService.filteredAISolutions.length
				? this.simulationService.filteredAISolutions.length
				: this.simulationService.counters.aiUseCases
		).toString();
	}
	
	getAISolutionsTooltip(): string {
		return this.simulationService.filteredAISolutions.length
			? `${this.simulationService.filteredAISolutions.length} AI Solutions Found`
			: `${this.simulationService.counters.aiUseCases} Total AI Solutions`;
	}

	getUseCaseFilterTooltip(): string {
		return this.useCaseFilter === 0 ? 'Collapse Filter' : 'Expand Filter';
	}
	
	toggleUseCaseFilter(): void {
		this.useCaseFilter = this.useCaseFilter ? 0 : -1;
	}

	areListsEqual(list1: string[], list2: string[]): boolean {
		if (list1.length !== list2.length) {
			return false;
		}
		const set1 = new Set(list1);
		const set2 = new Set(list2);
	
		return [...set1].every((item) => set2.has(item));
	}
}
