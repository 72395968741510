<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<ng-template [ngIf]="useCase">
	<div class="use-case-library-item text-color-base px-3">
		<div class="flex flex-wrap">
			<div class="col-12 pt-0 pl-0 pr-2">
				<h3 class="text-lg m-0 text-primary mb-3">{{ useCase.name }}</h3>
				<p class="text-color-base">{{ useCase.description }}</p>
			</div>

			<!-- <div class="col-4 pt-0 pr-0 pl-2">
				<p class="text-lg m-0 mb-3 title-enabler">AI Enablers</p>
				<div class="flex flex-wrap justify-content-between align-items-center gap-3">
					<ng-template ngFor let-technology [ngForOf]="aiEnablers">
						<div
							class="flex flex-column text-center align-items-center gap-1"
							tippy
							[tippyContent]="technology.name"
							tippyPlacement="bottom"
						>
							<i
								*ngIf="technology.svg"
								class="icon-tooltip technology"
								[ngClass]="(useCase.data.aiEnablers.includes(technology.id ?? '') || useCase.data.aiEnablers.includes(technology.name)) ? 'active' : ''"
								[innerHTML]="technology.svg"
							></i>
							<span class="text-sm">{{ technology.short }}</span>
						</div>
					</ng-template>
				</div>
			</div> -->

			<div class="flex flex-wrap">
				<div class="col-2 p-0 pr-2">
					<div class="box-simulation-item p-0 h-full">
						<div class="flex flex-column gap-0 h-full">
							<div class="flex justify-content-left align-items-center min-h-3rem font-bold bg-blue-100 px-3 py-2">
								<p class="title-impact py-1"><i class="pi pi-bullseye mr-2"></i> Impact</p>
							</div>
							<div class="flex justify-content-left align-items-center flex-grow-1 px-3">
								<div class="flex flex-wrap gap-1">
									<span
										style="font-size: 85%"
										*ngIf="useCase.data.impact_assessment === 'Incremental'"
										class="impact incremental"
									>#{{ useCase.data.impact_assessment }}</span>
									<span
										style="font-size: 85%"
										*ngIf="useCase.data.impact_assessment === 'Transformative'"
										class="impact transformative"
									>#{{ useCase.data.impact_assessment }}</span>
									<span
										style="font-size: 85%"
										*ngIf="useCase.data.impact_assessment === 'Breakthrough'"
										class="impact breakthrough"
									>#{{ useCase.data.impact_assessment }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-5 p-0 px-2">
					<div class="box-simulation-item p-0 h-full">
						<div class="flex flex-column gap-0 h-full">
							<div class="flex justify-content-left align-items-center min-h-3rem font-bold header-business px-3 py-2">
								<p class="text-primary"><i class="pi pi-database mr-2 py-1"></i> Data Requirements</p>
							</div>
							<div class="flex justify-content-left align-items-center flex-grow-1 px-3">
								<p class="text-color-base py-2">{{ useCase.data.data_requirements || '---' }}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="col-5 p-0 pl-2">
					<div class="box-simulation-item p-0 h-full">
						<div class="flex flex-column gap-0 h-full">
							<div class="flex justify-content-left align-items-center min-h-3rem font-bold header-business px-3 py-2">
								<p class="text-primary">
									<i class="pi pi-exclamation-circle mr-2 py-1"></i> Implementation Considerations
								</p>
							</div>
							<div class="flex justify-content-left align-items-center flex-grow-1 px-3">
								<p class="text-color-base py-2">{{ useCase.data.potential_challenges || '---' }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 box-simulation-item p-0 mt-3">
				<div class="flex flex-wrap">
					<ng-template ngFor let-value [ngForOf]="valuesDriversScores">
						<div class="col p-0">
							<div class="flex flex-column gap-0">
								<div
									class="header-business flex justify-content-center align-items-center h-3rem font-bold"
								>
									<p>{{ value.title }}</p>
								</div>
								<div class="flex justify-content-center align-items-center h-5rem">
									<p class="w-5rem h-2rem" [ngClass]="value.className">{{ value.label }}</p>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>

			<div class="col-12 px-0 py-3">
				<div class="box-simulation-item p-0">
					<div class="flex flex-column gap-0">
						<div class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3">
							<p class="text-primary">
								<i class="pi pi-microchip mr-2"></i> Embedded AI
								<p-button
									*ngIf="embeddedAIData.length"
									icon="pi pi-sparkles"
									styleClass="p-button-text p-button-plain ml-3"
									[loading]="embeddedAILoading"
									(onClick)="regenerateEmbeddedAI()"
								></p-button>
							</p>
						</div>
						<ng-template [ngIf]="!embeddedAIData.length">
							<div class="flex justify-content-center align-items-center h-full p-4">
								<div class="flex flex-column gap-2 align-items-center">
									<span *ngIf="!embeddedAIRegenerate && !embeddedAILoading">No platform embedded</span>
									<span *ngIf="!embeddedAIRegenerate" class="font-bold">Click below to start</span>
									<p-button
										icon="pi pi-microchip-ai"
										[label]="
											embeddedAIRegenerate ? 'Regenerating Embedded AI' : (embeddedAILoading ? 'Generating Embedded AI' : 'Generate Embedded AI')
										"
										[loading]="embeddedAILoading"
										(onClick)="generateEmbeddedAI()"
									></p-button>
								</div>
							</div>
						</ng-template>
						<ng-template [ngIf]="embeddedAIData.length">
							<p-accordion [activeIndex]="0">
								<ng-template ngFor let-embedded [ngForOf]="embeddedAIData || []">
									<p-accordionTab [header]="embedded.platform">
										<div class="text-black-alpha-90 opportunity-item">
											<ng-template ngFor let-vendor [ngForOf]="embedded.vendors || []">
												<span class="font-bold my-0 py-0">{{ vendor.vendor }}</span>
												<ol>
													<ng-template
														ngFor
														let-functionality
														[ngForOf]="vendor.functionalities || []"
													>
														<li>
															<span class="font-bold">{{ functionality.name }}:</span>
															<span>{{
																functionality.description_and_implementation
															}}</span>
														</li>
													</ng-template>
												</ol>
											</ng-template>
										</div>
									</p-accordionTab>
								</ng-template>
							</p-accordion>
						</ng-template>
					</div>
				</div>
			</div>

			<div class="col-12 text-right">
				<p-button
					label="Add to my AI Solutions"
					icon="pi pi-file"
					class="ml-2"
					[loading]="addToMyUseCaseLoading"
					(onClick)="addToMyUseCase()"
				></p-button>
			</div>
		</div>
	</div>
</ng-template>
