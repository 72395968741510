<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<ng-template [ngIf]="currentUseCase">
	<div class="use-case-simulation-item text-color-base px-3">
		<div class="flex flex-wrap">
			<div class="col-12 pt-0 pl-0 pr-2">
				<h3 class="text-lg m-0 text-primary mb-3">{{ currentUseCase.name }}</h3>
				<p class="text-color-base">{{ currentUseCase.description }}</p>
			</div>

			<!-- <div class="col-4 pt-0 pr-0 pl-2">
				<p class="text-lg m-0 mb-3 title-enabler">AI Enablers</p>
				<div class="flex flex-wrap justify-content-between align-items-center gap-3">
					<ng-template ngFor let-technology [ngForOf]="simulationService.aiEnablers">
						<div
							class="flex flex-column text-center align-items-center gap-1"
							tippy
							[tippyContent]="technology.name"
							tippyPlacement="bottom"
						>
							<i
								*ngIf="technology.svg"
								class="icon-tooltip technology"
								[ngClass]="aiEnablers.includes(technology.id || '') ? 'active' : ''"
								[innerHTML]="technology.svg"
							></i>
							<span class="text-sm">{{ technology.short }}</span>
						</div>
					</ng-template>
				</div>
			</div> -->

			<div class="col-2 p-0 pr-2">
				<div class="box-simulation-item p-0">
					<div class="flex flex-column gap-0">
						<div class="flex justify-content-left align-items-center h-3rem font-bold bg-blue-100 px-3">
							<p class="title-impact"><i class="pi pi-bullseye mr-2"></i> Impact</p>
						</div>
						<div class="flex justify-content-left align-items-center h-6rem px-3">
							<div class="flex flex-wrap gap-1">
								<ng-template ngFor let-impact [ngForOf]="currentUseCase.context.impact || []">
									<span
										style="font-size: 85%"
										*ngIf="impact === 'Incremental'"
										class="impact incremental"
										>#{{ impact }}</span
									>
									<span
										style="font-size: 85%"
										*ngIf="impact === 'Transformative'"
										class="impact transformative"
										>#{{ impact }}</span
									>
									<span
										style="font-size: 85%"
										*ngIf="impact === 'Breakthrough'"
										class="impact breakthrough"
										>#{{ impact }}</span
									>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-5 p-0 px-2">
				<div class="box-simulation-item p-0 d-flex flex-column justify-content-between">
					<div class="flex justify-content-left align-items-center font-bold header-business px-3">
						<p class="text-primary"><i class="pi pi-database mr-2"></i> Data Requirements</p>
					</div>
					<div class="flex-grow-1 px-3 py-2">
						<p class="text-color-base">{{ currentUseCase.data.data_requirements || '---' }}</p>
					</div>
				</div>
			</div>

			<div class="col-5 p-0 pl-2">
				<div class="box-simulation-item p-0 d-flex flex-column justify-content-between">
					<div class="flex justify-content-left align-items-center font-bold header-business px-3">
						<p class="text-primary">
							<i class="pi pi-exclamation-circle mr-2"></i> Implementation Considerations
						</p>
					</div>
					<div class="flex-grow-1 px-3 py-2">
						<p class="text-color-base">{{ currentUseCase.data.potential_challenges || '---' }}</p>
					</div>
				</div>
			</div>

			<div class="col-12 px-0 py-3">
				<div class="box-simulation-item p-0">
					<div class="flex flex-column gap-0">
						<div class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3">
							<p class="text-primary"><i class="pi pi-chart-line mr-2"></i> Industry Commentary</p>
						</div>
						<div class="flex justify-content-left align-items-center h-full px-3">
							<p class="py-2 text-color-base">{{ currentUseCase.data.llm_explanation || '---' }}</p>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 box-simulation-item p-0">
				<div class="flex flex-wrap">
					<ng-template ngFor let-value [ngForOf]="valuesDriversScores">
						<div class="col p-0">
							<div class="flex flex-column gap-0">
								<div
									class="header-business flex justify-content-center align-items-center h-3rem font-bold"
								>
									<p>{{ value.title }}</p>
								</div>
								<div class="flex justify-content-center align-items-center h-5rem">
									<p class="w-5rem h-2rem" [ngClass]="value.className">{{ value.label }}</p>
								</div>
							</div>
						</div>
					</ng-template>
				</div>
			</div>

			<!--			<div class="col-12 px-0 py-3">-->
			<!--				<div class="box-simulation-item p-0">-->
			<!--					<div class="flex flex-column gap-0">-->
			<!--						<div class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3">-->
			<!--							<p class="text-primary">-->
			<!--								<i class="pi pi-microchip mr-2"></i> Embedded AI-->
			<!--								<p-button-->
			<!--									*ngIf="embeddedAIData.length"-->
			<!--									icon="pi pi-sparkles"-->
			<!--									styleClass="p-button-text p-button-plain ml-3"-->
			<!--									[loading]="embeddedAILoading"-->
			<!--									(onClick)="regenerateEmbeddedAI()"-->
			<!--								></p-button>-->
			<!--							</p>-->
			<!--						</div>-->
			<!--&lt;!&ndash;						<ng-template [ngIf]="!embeddedAIData.length">&ndash;&gt;-->
			<!--&lt;!&ndash;							<div class="flex justify-content-center align-items-center h-full p-4">&ndash;&gt;-->
			<!--&lt;!&ndash;								<div class="flex flex-column gap-2 align-items-center">&ndash;&gt;-->
			<!--&lt;!&ndash;									<span *ngIf="!embeddedAIRegenerate && !embeddedAILoading">No platform embedded</span>&ndash;&gt;-->
			<!--&lt;!&ndash;									<span *ngIf="!embeddedAIRegenerate" class="font-bold">Click below to start</span>&ndash;&gt;-->
			<!--&lt;!&ndash;									<p-button&ndash;&gt;-->
			<!--&lt;!&ndash;										icon="pi pi-microchip-ai"&ndash;&gt;-->
			<!--&lt;!&ndash;										[label]="&ndash;&gt;-->
			<!--&lt;!&ndash;											embeddedAIRegenerate ? 'Regenerating Embedded AI' : (embeddedAILoading ? 'Generating Embedded AI' : 'Generate Embedded AI')&ndash;&gt;-->
			<!--&lt;!&ndash;										"&ndash;&gt;-->
			<!--&lt;!&ndash;										[loading]="embeddedAILoading"&ndash;&gt;-->
			<!--&lt;!&ndash;										(onClick)="generateEmbeddedAI()"&ndash;&gt;-->
			<!--&lt;!&ndash;									></p-button>&ndash;&gt;-->
			<!--&lt;!&ndash;								</div>&ndash;&gt;-->
			<!--&lt;!&ndash;							</div>&ndash;&gt;-->
			<!--&lt;!&ndash;						</ng-template>&ndash;&gt;-->
			<!--&lt;!&ndash;						<ng-template [ngIf]="embeddedAIData.length">&ndash;&gt;-->
			<!--&lt;!&ndash;							<p-accordion [activeIndex]="0">&ndash;&gt;-->
			<!--&lt;!&ndash;								<ng-template ngFor let-embedded [ngForOf]="embeddedAIData || []">&ndash;&gt;-->
			<!--&lt;!&ndash;									<p-accordionTab [header]="embedded.platform">&ndash;&gt;-->
			<!--&lt;!&ndash;										<div class="text-black-alpha-90 opportunity-item">&ndash;&gt;-->
			<!--&lt;!&ndash;											<ng-template ngFor let-vendor [ngForOf]="embedded.vendors || []">&ndash;&gt;-->
			<!--&lt;!&ndash;												<span class="font-bold my-0 py-0">{{ vendor.vendor }}</span>&ndash;&gt;-->
			<!--&lt;!&ndash;												<ol>&ndash;&gt;-->
			<!--&lt;!&ndash;													<ng-template&ndash;&gt;-->
			<!--&lt;!&ndash;														ngFor&ndash;&gt;-->
			<!--&lt;!&ndash;														let-functionality&ndash;&gt;-->
			<!--&lt;!&ndash;														[ngForOf]="vendor.functionalities || []"&ndash;&gt;-->
			<!--&lt;!&ndash;													>&ndash;&gt;-->
			<!--&lt;!&ndash;														<li>&ndash;&gt;-->
			<!--&lt;!&ndash;															<span class="font-bold">{{ functionality.name }}:</span>&ndash;&gt;-->
			<!--&lt;!&ndash;															<span>{{&ndash;&gt;-->
			<!--&lt;!&ndash;																functionality.description_and_implementation&ndash;&gt;-->
			<!--&lt;!&ndash;															}}</span>&ndash;&gt;-->
			<!--&lt;!&ndash;														</li>&ndash;&gt;-->
			<!--&lt;!&ndash;													</ng-template>&ndash;&gt;-->
			<!--&lt;!&ndash;												</ol>&ndash;&gt;-->
			<!--&lt;!&ndash;											</ng-template>&ndash;&gt;-->
			<!--&lt;!&ndash;										</div>&ndash;&gt;-->
			<!--&lt;!&ndash;									</p-accordionTab>&ndash;&gt;-->
			<!--&lt;!&ndash;								</ng-template>&ndash;&gt;-->
			<!--&lt;!&ndash;							</p-accordion>&ndash;&gt;-->
			<!--&lt;!&ndash;						</ng-template>&ndash;&gt;-->
			<!--					</div>-->
			<!--				</div>-->
			<!--			</div>-->

			<div *ngIf="currentUseCase.data.allAgents" class="col-12 px-0 py-3">
				<div class="box-simulation-item p-0">
					<div class="flex flex-column gap-4 pb-4">
						<div class="flex justify-content-left align-items-center h-3rem font-bold header-business px-3">
							<div class="text-primary flex gap-2" style="font-size: 16px; line-height: 25px">
								<app-svg-ai-agent fill="blue"></app-svg-ai-agent>
								AI Agents
							</div>
						</div>
						<p class="px-3">{{ currentUseCase.data.allAgents.description }}</p>
						<span class="px-3 font-bold">Agents Involved:</span>
						<div
							class="text-black-alpha-90 opportunity-item flex flex-row flex-wrap gap-2 px-3 justify-content-evenly"
						>
							<ng-template ngFor let-agent [ngForOf]="currentUseCase.data.allAgents.agents || []">
								<div class="ai-agent flex flex-column {{ agent.className }}">
									<div>
										<app-svg-ai-agent [fill]="agent.className"></app-svg-ai-agent>
									</div>
									<div class="label">
										{{ agent.label }}
									</div>
									<div class="chip {{ agent.className }}">
										<app-svg-must-have-icon [value]="agent.mustHave"></app-svg-must-have-icon>
										{{ agent.mustHaveLabel }}
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>

			<div class="col-12 text-right">
				<p-button
					label="Export Data"
					icon="pi pi-download"
					styleClass="hidden"
					(click)="generatePresentation(currentUseCase)"
				></p-button>
				<p-button
					*ngIf="!currentUseCase.deployedId && useCasesSaved"
					label="Add to my AI Solutions"
					icon="pi pi-plus"
					class="p-button-secondary ml-2"
					[loading]="addToMyUseCaseLoading"
					(onClick)="addToMyUseCase()"
				></p-button>
				<p-button
					*ngIf="currentUseCase.deployedId"
					label="Go to my AI Solutions"
					icon="pi pi-file"
					class="ml-2"
					(onClick)="goToMyUseCase()"
				></p-button>
			</div>
		</div>
	</div>
</ng-template>
